import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { DepartmentService } from 'src/app/service/department.service';
import { ChatbotService } from 'src/app/service/chatbot.service';

@Component({
  selector: 'app-question-type-create',
  templateUrl: './question-type-create.component.html',
  styleUrls: ['./question-type-create.component.scss'],
})

export class QuestionTypeCreateComponent implements OnInit {

  name: any;
  /*intalise form */
  createQuestionTypeForm = new FormGroup({
    name: new FormControl('',),
  })

  department_id: any;
  errormsg: any;
  page: number = 1;
  count: any;




  constructor(
    public dialogRef: MatDialogRef<QuestionTypeCreateComponent>,
    private maintenanceService: MaintenanceService,
    private departmentService: DepartmentService,
    private ChatbotService: ChatbotService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    // console.log(this.data);
    // if (this.data.id) {
    //   this.department_id = this.data.id;
    //   this.getDepartmentDetails();
    // }
  }


  getDepartmentDetails() {

    this.departmentService.getDepartmentData(this.department_id).subscribe((res) => {
      // console.log(res);
      this.createQuestionTypeForm.patchValue({
        name: res.name
        // ticket_type: this.data.filterData.ticket_type,name:res.name      
      });


    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Department Details');
      }
    )

  }
  /* Func onsubmitting form - to create a department*/
  onSubmit() {
    // if (this.department_id) {
    //   /* subscribe create department api*/
    //   this.departmentService.updateDepartment(this.department_id, this.createQuestionTypeForm.value).subscribe((res) => {
    //     /* if successfully submitted display msg to user in toast then close modal*/
    //     // console.log("department_id",res);
    //     this.closeDialog();    //close modal
    //     this.toastPopupService.openToast('Department update Successfully');   //display success mesg
    //     // }


    //   },
    //     (error) => {
    //       // console.log(error);
    //       this.errormsg = error.error;
    //       this.toastPopupService.openToast('Unable to update Department');
    //     }
    //   )
    // }
    // else
    {

      /* subscribe create department api*/
      this.ChatbotService.questionTypeTicketCreate(this.createQuestionTypeForm.value).subscribe((res) => {
        /* if successfully submitted display msg to user in toast then close modal*/
        // console.log("department_id",res);


        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Department Created Successfully');   //display success mesg
        // }


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Department');
        }
      )
    }
  }





  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }







}

