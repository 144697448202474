import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { State } from '../interface/State';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(public http: HttpClient) {
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }


  getStates(page = 1): Observable<State> {
    return this.http.get<State>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/states?page=${page}`,
    );
  }

  addState(postData): Observable<State> {
    return this.http.post<State>(
      `${this.endpoint}api/states/create/`,
      postData
    )
  }

  updateState(id, postData) {
    return this.http.put<State>(
      `${this.endpoint}api/states/${id}/`,
      postData
    )
  }

  getStateData(id) {
    return this.http.get<State>(
      `${this.endpoint}api/states/${id}/`,

    )
  }
  getStateSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<State>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/states?page=${page}&search=${result.search}`
    );
  }
}
