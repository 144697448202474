import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { UserService } from 'src/app/service/users.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { LoginService } from 'src/app/service/login.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { CustomRoleService } from 'src/app/service/customrole.service';
//import { DialogData } from '../../../../interface/DialogData';

@Component({
  selector: 'app-ticket-assign',
  templateUrl: './ticket-assign.component.html',
  styleUrls: ['./ticket-assign.component.scss'],
})
export class TicketAssignComponent implements OnInit {
  minDate1ToFinish = new Subject<string>();
  minDate1: any;

  minDate2ToFinish = new Subject<string>();
  minDate2: any;

  datevaliderror: string;
  roleTypes: unknown[];
  roleTypesCount: any;
  loggedInUserId: any;
  assignType: any;
  technicianInvalid: number;
  minTime2 = '00:00';
  minTime1 = '00:00';
  disableCurrentTechnician: string;
  disableReassignSubmitButton: boolean=true;

  dateChange(e) {
    this.minDate1ToFinish.next(e.value.toString());
    //this.minTime1='11:00'
    this.validateDateWithCurrentDate();
  }

  dateChange1(e) { 
    this.minDate2ToFinish.next(e.value.toString());
    this.validateDatesCompare();
  }

  validateDateWithCurrentDate(){
    const date = new Date();
    let ctime=date.getHours()+':'+date.getMinutes();
    let sdate = this.convert(this.minDate1);
    let todaydate = this.convert(date);
    let start_date = `${sdate}T00:00Z`;
    let today_date = `${todaydate}T00:00Z`;
    let z = this.datesequalvalid(start_date, today_date);
    if (z == 1) {
      this.minTime1 = ctime;
    } else {
      this.minTime2 = '00:00';
    }
   
   // this.minTime1='11:00'; 
  }

  validateDatesCompare() {
    let sdate = this.convert(this.minDate1);
    let edate = this.convert(this.minDate2);
    let start_date = `${sdate}T00:00Z`;
    let end_date = `${edate}T00:00Z`;
    let z = this.datesequalvalid(start_date, end_date);
    if (z == 1) {
      this.minTime2 = this.assignTicketForm.value.start_time;
    } else {
      this.minTime2 = '00:00';
    }
  }

  onStartTimeChange(e) {
    // console.log(e, 'starttime');
  }

  /*inialize varaibale for group dropdown */
  selectedGroup: number;
  groups = [];

  /*inialize varaibale for technician dropdown */
  selectedTechnician: any;
  technicians = [];

  /*inialize form */
  assignTicketForm = new FormGroup({
    ticket: new FormControl(this.data.ticket, [Validators.required]),
    technician: new FormControl(''),
    start_date: new FormControl('', [Validators.required]),
    start_time: new FormControl('', [Validators.required]),
    end_date: new FormControl('', [Validators.required]),
    end_time: new FormControl('', [Validators.required]),
    // service_notes: new FormControl('', [Validators.required,]),
    service_instructions: new FormControl(''),
    monitoring_team: new FormControl(''),
  });

  count: any;
  page: any = 1;
  tech_department: any;
  userRole: any;
  minDate: Date;
  cDate: string;
  cTime: string;
  test: any;
  dateToday: number;
  ticket_id: any;
  loggedInRole: string;
  minimunDate: { year: number; month: number; day: number };
  minimumTime: string;
  selectedDateToday: boolean;

  /* */
  selected_monitoring_team: any;
  monitoring_team_page = 1;
  monitoring_teams = [];
  monitoring_team_count: any;
  has_selected_monitoring_team: number;
  main_department: any;

  /*constructor func */
  constructor(
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private loginService: LoginService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private customRoleService: CustomRoleService,
    public dialogRef: MatDialogRef<TicketAssignComponent>, //refrence to modal
    @Inject(MAT_DIALOG_DATA) public data //injecting data to modal
  ) {
    this.minDate1ToFinish.subscribe((r) => {
      this.minDate1 = new Date(r);
    });

    this.minDate2ToFinish.subscribe((r) => {
      this.minDate2 = new Date(r);
    });
  }

  /* ngOnInit() func called at the time of component created */
  ngOnInit() {
    this.getRolesList();
    this.getTechniciansList(); //call the func getTechniciansList()
    /*if task deatails alredy exists get task deatails */
    if (this.data) {
      // console.log(this.data);
      // console.log(this.data.department);
      this.ticket_id = this.data.id;
      this.tech_department = this.data.department;
      this.assignType = this.data.assigType;
      this.getTaskDetails();
    }

    this.minDate = new Date();
    this.minDate1 = new Date();

    this.loggedInRole = this.loginService.getRole();
    this.loggedInUserId = this.loginService.getUserId();

    this.dateToday = Date.now();

    this.getCurrentDate();
  }

  getCurrentDate() {
    let dateObj = new Date();
    let month = String(dateObj.getMonth() + 1).padStart(2, '0');
    let day = String(dateObj.getDate()).padStart(2, '0');
    let year = dateObj.getFullYear();
    let output = year + '-' + month + '-' + day;
    this.cDate = output;
  }

  convert(str) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  /* func called on form submit */
  onSubmit() {
    /* set the payload as per backedn api need*/
    let sdate = this.convert(this.assignTicketForm.value.start_date);
    let edate = this.convert(this.assignTicketForm.value.end_date);

    let start_date = `${sdate}T${this.assignTicketForm.value.start_time}Z`;
    let end_date = `${edate}T${this.assignTicketForm.value.end_time}Z`;
    // let technician;

    if (this.assignType == 'grabtheticket') {
      this.assignTicketForm.value.technician = this.loggedInUserId;
    }

    const payload = {
      ticket: this.assignTicketForm.value.ticket,
      service_instructions: this.assignTicketForm.value.service_instructions,
      start_date: start_date,
      end_date: end_date,
      technician: this.assignTicketForm.value.technician,
    };

    if (
      this.assignTicketForm.valid &&
      this.datesvalid(start_date, end_date) &&
      this.formFieldValid()
    ) {
      this.updateTask(payload);
    }
  }

  formFieldValid() {
    // console.log(this.assignTicketForm.value.technician);
    if (
      this.assignTicketForm.value.technician == '' ||
      this.assignTicketForm.value.technician == null
    ) {
      this.technicianInvalid = 1;
      return 0;
    } else {
      this.technicianInvalid = 0;
      return 1;
    }
  }

  datesequalvalid(start_date, end_date) {
    var a = moment(start_date);
    var b = moment(end_date);

    let f = b.diff(a); // 1000
    //let f1= a.diff(b) // -1000
    // console.log(f);
    //console.log(f1);
    // console.log(end_date>start_date);
    if (f == 0) {
      this.datevaliderror = '';
      return 1;
    } else {
    //  this.datevaliderror = "Invalid Start Time and End Time"
      return 0;
    }
  }

  datesvalid(start_date, end_date) {
    var a = moment(start_date);
    var b = moment(end_date);

    let f = b.diff(a); // 1000
    //let f1= a.diff(b) // -1000
    // console.log(f);
    //console.log(f1);
    // console.log(end_date>start_date);
    if (f > 0) {
      this.datevaliderror = '';
      return 1;
    } else {
      this.datevaliderror = "Invalid Start Time and End Time"
      return 0;
    }
  }

  /* Func to get list of monitoring team*/
  getMonitoringTeamList() {
    {
      this.departmentService
        .getMaintenanceDepartments(this.monitoring_team_page)
        .subscribe(
          (res: any) => {
            this.monitoring_team_count = res.count;

            this.monitoring_teams = [
              ...new Map(
                this.monitoring_teams
                  .concat(res.results)
                  .map((item) => [item['id'], item])
              ).values(),
            ];
            this.loadMoreMonitoringTeam();
          },
          (error) => {
            // console.log(error);
          }
        );
    }
  }

  loadMoreMonitoringTeam() {
    //console.log(this.maintenance_departments.length);
    if (this.monitoring_team_count != this.monitoring_teams.length) {
      this.monitoring_team_page++;
      this.getMonitoringTeamList();
    } else {
      this.monitoring_teams = this.monitoring_teams.filter((x: any) => {
        return x.department == this.main_department;
      });
    }
  }

  /*func to create task */
  createTask(payload) {
    // if (this.assignTicketForm.valid)
    {
      this.maintenanceService
        .assignTicket(this.ticket_id, payload)

        .subscribe(
          (data) => {
            // console.log(data);
            // this.loginData = data;
            this.closeDialogsimple();
            this.toastPopupService.openToast('Ticket Assigned Successfully');
          },
          (error) => {
            // console.log(error);
          }
        );
    }
  }

  /*func to update task */
  updateTask(payload) {
    {
      this.maintenanceService
        .updateAssignTicket(this.data.id, payload)
        .subscribe(
          (data) => {
            // console.log(data);
            // this.loginData = data;
            this.closeDialogsimple();
            this.toastPopupService.openToast('Ticket Assigned Successfully');
          },
          (error) => {
            // console.log(error);
          }
        );
    }
  }

  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  onScroll() {
    // console.log("hello")
    if (this.count != this.technicians.length) {
      this.page++;

      this.getTechniciansList();
    } else {
      this.technicians = this.technicians.filter((x: any) => {
        return x.role != 'client';
      });
      this.technicians = this.technicians.filter((x: any) => {
        return x.department == this.tech_department;
      });


      // const p2 = this.technicians.find((p) => p.id === this.assignTicketForm.value.technician); 
      // p2.disabled = true;

      // if (this.loggedInRole == 'lead') {
      //    this.technicians = this.technicians.filter((x: any) => { return x.role != 'manager' });
      //  }

      //  if (this.loggedInRole == 'technician') {
      //    this.technicians = this.technicians.filter((x: any) => { return x.role == 'technician' });
      //  }

      // console.log(this.technicians);
      // this.tech_department=this.data.department;
    }
  }


 disableTheCurrentTechnician(id){ 
  //console.log(id,"iddd");

  if(id==this.disableCurrentTechnician){
  return true;
  }
  else {
  return false;
  }

  }

  getRoleName(name) {
    let role = this.roleTypes.filter((x: any) => {
      return x.id == name;
    });
    // console.log(role);
    //return role[0].name;
  }

  getRolesList() {
    this.customRoleService.getCustomRoles().subscribe(
      (res: any) => {
        //   this.roleTypes = res.results;
        this.roleTypes = [
          ...new Map(
            this.roleTypes.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.roleTypesCount = res.count;
        this.onScrollRoleTypes();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable');
      }
    );
  }

  onScrollRoleTypes() {
    // console.log("hello")
    if (this.roleTypesCount != this.roleTypes.length) {
      this.page++;
      this.getRolesList();
    }
  }

  /* Func to get list of technicians */
  getTechniciansList() {
    this.userService.getusers(this.page).subscribe(
      (res: any) => {
        // this.technicians = res.results;
        this.technicians = [
          ...new Map(
            this.technicians
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = res.count;

        this.onScroll();
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  /* Func to get list of technicians */
  getGroupsList() {
    this.userService.getGroups().subscribe(
      (res: any) => {
        this.groups = res.results;
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  /*func to get task details and patch values to form */
  getTaskDetails() {
    {
      this.maintenanceService.getAssignTicket(this.ticket_id).subscribe(
        (res) => {
          /*  this.selectedTechnician = [];     //initalize selectedTechnician
         
             const technicianslist = this.data.taskdetails[0].technician;  
         
             technicianslist.forEach((item, index) => {
               // console.log(index, item.id);
               this.selectedTechnician.push(item.id);      //update selectedTechnician dropdown value
             })*/

          let stime = res.start_date.split(' ')[1];
          let stime1 = stime.split(':')[0] + ':' + stime.split(':')[1];

          let etime = res.end_date.split(' ')[1];
          let etime1 = etime.split(':')[0] + ':' + etime.split(':')[1];

          this.disableCurrentTechnician=res.technician;
          this.assignTicketForm.patchValue({
            technician: res.technician,
            start_date: res.start_date.split(' ')[0],
            start_time: stime1,
            end_date: res.end_date.split(' ')[0],
            end_time: etime1,
            // //service_notes: new FormControl('', [Validators.required,]),
            service_instructions: res.service_instructions,
            //    monitoring_team: res.monitoring_team,
          });
          // console.log(res);
        },
        (error) => {
          // console.log(error);
        }
      );
    }
  }


  onChangeTechnicianAtReassign(e){
    // console.log(e);
    this.disableReassignSubmitButton=false;
  }
}