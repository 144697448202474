import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { LoginService } from '../service/login.service';

@Injectable({
  providedIn: 'root',
})

export class AuthGuard  {
  tokenValid: boolean = false;
  constructor(private loginService: LoginService,
    private authService: AuthService,
    private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const result = localStorage.getItem('token');

    if (this.authService.inMaintenance()) {
      //  alert('This Site Is Still Under Maintenance');
      this.router.navigate(['maintenance']);
      this.tokenValid = false;
    }
    else {
      if (result) {
        this.tokenValid = true;
      } else {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        this.tokenValid = false;
      }
      return this.tokenValid;
    }
  }
}
