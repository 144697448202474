import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ReportService } from 'src/app/service/report.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-report-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.scss'],
})
export class ReportCreateComponent implements OnInit {
  errormsg: any;
  reportGenerateForm = new FormGroup({
    start_date: new FormControl('', [Validators.required,]),
    end_date: new FormControl('', [Validators.required,]),
    selected_fields: new FormControl([]),
    department: new FormControl(''),
  })
  fieldValues = [];
  mainteneceDepartmentCount: any;
  maintenecepage = 1;
  maintenance_departments: any;
  displayFilterOption: boolean = false;
  maxDate = new Date();

  constructor(private reportService: ReportService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService) { }

  ngOnInit() {
    this.getFieldValues();
    this.getMaintenanceDepartmentsList();
  }

  getFieldValues() {
    {
      this.reportService.getFieldValues().subscribe((res: any) => {
        this.fieldValues = res.selected_fields;
      },
        (error) => {
          // console.log(error);

          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Report Successfully');
        });
    }
  }



  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onSubmit() {
    let payload = {
      start_date: this.convert(this.reportGenerateForm.value.start_date),
      end_date: this.convert(this.reportGenerateForm.value.end_date),
      //selected_fields: this.reportGenerateForm.value.selected_fields.join(","),
      selected_fields: this.reportGenerateForm.value.selected_fields,
      department: this.reportGenerateForm.value.department
    }

    if (this.reportGenerateForm.value.department == '') {
      delete payload.department;
    } else if (this.reportGenerateForm.value.department == null) {
      delete payload.department;
    }

    {
      this.reportService.getreportData(payload).subscribe((res: any) => {
        const path = res.file_path;
        window.location.href = this.reportService.downloadFile(path);

        this.reportGenerateForm.patchValue({
          // start_date: '',
          // end_date: '',
          // selected_fields: this.reportGenerateForm.value.selected_fields.join(","),
          selected_fields: [],
          department: '',

        });

        this.displayFilterOption = false;
      },
        (error) => {
          // console.log(error);
          this.toastPopupService.openToast('Unable to get reports');
        });
    }
  }

  onFieldvalueSelected(e) {
    if (e == "department") {
      this.displayFilterOption = true;
    }
  }

  onFieldvalueRemove(e) {
    if (e.value == "department") {
      this.displayFilterOption = false;
    }
    this.reportGenerateForm.patchValue({
      department: null,
    });
  }

  /* Func to get list of departments */
  getMaintenanceDepartmentsList() {
    {
      this.departmentService.getMainDepartments(this.maintenecepage).subscribe((res: any) => {
        this.mainteneceDepartmentCount = res.count;
        this.maintenance_departments = res.results;
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get departments list');
        });
    }

  }


}
