import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketlistComponent } from './ticket/ticketlist/ticketlist.component';
import { HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TicketcreateComponent } from './ticket/ticketcreate/ticketcreate.component';
import { IonicModule } from '@ionic/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { TicketAssignComponent } from './ticket/ticket-assign/ticket-assign.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MaterialExampleModule } from 'src/material.module';
import { HeaderComponent } from './header/header.component';
import { TicketViewComponent } from './ticket/ticket-view/ticket-view.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserViewComponent } from './users/user-view/user-view.component';
import { GroupCreateComponent } from './groups/group-create/group-create.component';
import { GroupListComponent } from './groups/group-list/group-list.component';
import { EquipmentListComponent } from './equipments/equipment-list/equipment-list.component';
import { EquipCreateComponent } from './equipments/equip-create/equip-create.component';
import { MyTicketListComponent } from './ticket/my-ticket-list/my-ticket-list.component';
import { RoasterListComponent } from './roaster/roaster-list/roaster-list.component';
import { RoasterCreateComponent } from './roaster/roaster-create/roaster-create.component';
import { UserActivilityListComponent } from './activity/user-activility-list/user-activility-list.component';
import { TicketCreateChatbotComponent } from './ticket/ticket-create-chatbot/ticket-create-chatbot.component';
import { MyprofileComponent } from './myprofile/myprofile/myprofile.component';
import { HomeComponent } from './charts-dashboard/home/home.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { Widget1Component } from './charts-dashboard/widget1/widget1.component';
import { Widget2Component } from './charts-dashboard/widget2/widget2.component';
import { TaskSummaryComponent } from './charts-dashboard/charts/task-summary/task-summary.component';
import { TicketCountComponent } from './charts-dashboard/charts/ticket-count/ticket-count.component';
import { TicketStatusPieComponent } from './charts-dashboard/charts/ticket-status-pie/ticket-status-pie.component';
import { TicketUnassignedOpenCountComponent } from './charts-dashboard/charts/ticket-unassigned-open-count/ticket-unassigned-open-count.component';
import { TicketLayoutComponent } from './ticket/ticket-layout/ticket-layout.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { EquipmentViewComponent } from './equipments/equipment-view/equipment-view.component';
import { TicketCountAsPerDepartmentComponent } from './charts-dashboard/charts/ticket-count-as-per-department/ticket-count-as-per-department.component';
import { CategoryCreateComponent } from './category/category-create/category-create.component';
import { CategoryListComponent } from './category/category-list/category-list.component';
import { SubcategoryCreateComponent } from './category/subcategory-create/subcategory-create.component';
import { TicketChangeDepartmentComponent } from './ticket/ticket-change-department/ticket-change-department.component';
import { TicketNotesComponent } from './ticket/ticket-notes/ticket-notes.component';
import { CustomRoleCreateComponent } from './custom-role/custom-role-create/custom-role-create.component';
import { CustomRoleListComponent } from './custom-role/custom-role-list/custom-role-list.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { CustomRoleViewComponent } from './custom-role/custom-role-view/custom-role-view.component';
import { DepartmentCreateComponent } from './department/department-create/department-create.component';
import { DepartmentListComponent } from './department/department-list/department-list.component';
import { FormBuilderComponent } from './form-builder/form-builder/form-builder.component';
import { LocationListComponent } from './location/location-list/location-list.component';
import { LocationCreateComponent } from './location/location-create/location-create.component';
import { ReportCreateComponent } from './report/report-create/report-create.component';
import { AssetsLayoutComponent } from './assets/assets-layout/assets-layout.component';
import { TicketFeedbackComponent } from './ticket/ticket-feedback/ticket-feedback.component';
import { WidgetsModule } from '../widgets/widgets.module';
import { NotificationControllersComponent } from './notification-controllers/notification-controllers.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { TicketTemplateListComponent } from './ticket-flow/ticket-template-list/ticket-template-list.component';
import { TicketTemplateCreateComponent } from './ticket-flow/ticket-template-create/ticket-template-create.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { TicketTemplateViewComponent } from './ticket-flow/ticket-template-view/ticket-template-view.component';
import { ContenteditableValueAccessor } from '../../custom-directives/contenteditable.directive';
import { BypassHtmlPipe } from 'src/app/pipe/bypassHtml.pipe';
import { InventoryListComponent } from './inventory/inventory-list/inventory-list.component';
import { QuestionTypeComponent } from './ticket-flow-question-type/question-type/question-type.component';
import { QuestionTypeCreateComponent } from './ticket-flow-question-type/question-type-create/question-type-create.component';
//import { ContentEditableFormDirective } from '../custom-directives/contenteditable.directive';
import { MatTreeModule } from '@angular/material/tree';
import { CdkTreeModule } from '@angular/cdk/tree';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ModalQuestionListComponent } from './ticket-flow-modal-question/modal-question-list/modal-question-list.component';
import { ModalQuestionCreateComponent } from './ticket-flow-modal-question/modal-question-create/modal-question-create.component';
import { FieldTypeListComponent } from './ticket-flow-field-type/field-type-list/field-type-list.component';
import { FieldTypeCreateComponent } from './ticket-flow-field-type/field-type-create/field-type-create.component';
import { SystemStatusListComponent } from './system-status/system-status-list/system-status-list.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';
import { ReportGenerateComponent } from './report/report-generate/report-generate.component';
import { ReportListComponent } from './report/report-list/report-list.component';
import { RegionCreateComponent } from './region/region-create/region-create.component';
import { RegionListComponent } from './region/region-list/region-list.component';
import { CountryListComponent } from './country/country-list/country-list.component';
import { CountryCreateComponent } from 'src/app/pages/components/country/country-create/country-create.component';
import { UserResetPasswordComponent } from './users/user-reset-password/user-reset-password.component';
import { StateCreateComponent } from './state/state-create/state-create.component';
import { StateListComponent } from './state/state-list/state-list.component';
import { CityCreateComponent } from './city/city-create/city-create.component';
import { CityListComponent } from './city/city-list/city-list.component';
import { FloorCreateComponent } from './floor/floor-create/floor-create.component';
import { FloorListComponent } from './floor/floor-list/floor-list.component';
import { RoomCreateComponent } from './room/room-create/room-create.component';
import { RoomListComponent } from './room/room-list/room-list.component';
import { OtpDialogComponent } from './Dialogs/otp-dialog/otp-dialog.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { TicketCountAsPerUserComponent } from './charts-dashboard/charts/ticket-count-as-per-user/ticket-count-as-per-user.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({ declarations: [
        ReportListComponent,
        PushNotificationComponent,
        FieldTypeCreateComponent,
        FieldTypeListComponent,
        InventoryListComponent,
        ContenteditableValueAccessor,
        BypassHtmlPipe,
        TicketTemplateViewComponent,
        TicketLayoutComponent,
        TicketlistComponent,
        TicketcreateComponent,
        TicketAssignComponent,
        TicketViewComponent,
        TicketCreateChatbotComponent,
        LocationListComponent,
        LocationCreateComponent,
        NotificationControllersComponent,
        SidemenuComponent,
        TicketNotesComponent,
        HeaderComponent,
        MyTicketListComponent,
        UsersListComponent,
        UserCreateComponent,
        UserViewComponent,
        GroupListComponent,
        GroupCreateComponent,
        EquipmentListComponent,
        EquipCreateComponent,
        EquipmentViewComponent,
        RoasterListComponent,
        RoasterCreateComponent,
        UserActivilityListComponent,
        MyprofileComponent,
        HomeComponent,
        NotificationsComponent,
        ChangePasswordComponent,
        Widget1Component,
        Widget2Component,
        TaskSummaryComponent,
        TicketCountComponent,
        TicketStatusPieComponent,
        TicketUnassignedOpenCountComponent,
        TicketCountAsPerDepartmentComponent,
        TicketCountAsPerUserComponent,
        CategoryCreateComponent,
        CategoryListComponent,
        SubcategoryCreateComponent,
        TicketChangeDepartmentComponent,
        CustomRoleCreateComponent,
        CustomRoleListComponent,
        CustomRoleViewComponent,
        DepartmentListComponent,
        DepartmentCreateComponent,
        FormBuilderComponent,
        ReportCreateComponent,
        TicketTemplateListComponent,
        AssetsLayoutComponent,
        TicketFeedbackComponent,
        TicketTemplateCreateComponent,
        HeaderMenuComponent,
        QuestionTypeComponent,
        QuestionTypeCreateComponent,
        ModalQuestionListComponent,
        ModalQuestionCreateComponent,
        SystemStatusListComponent,
        ReportGenerateComponent,
        RegionCreateComponent,
        RegionListComponent,
        CountryListComponent,
        CountryCreateComponent,
        UserResetPasswordComponent,
        StateListComponent,
        StateCreateComponent,
        CityCreateComponent,
        CityListComponent,
        FloorCreateComponent,
        FloorListComponent,
        RoomCreateComponent,
        RoomListComponent,
        OtpDialogComponent
    ],
    exports: [
        ReportListComponent,
        PushNotificationComponent,
        FieldTypeCreateComponent,
        FieldTypeListComponent,
        QuestionTypeComponent,
        InventoryListComponent,
        TicketTemplateViewComponent,
        HeaderMenuComponent,
        TicketTemplateCreateComponent,
        TicketTemplateListComponent,
        SidemenuComponent,
        TicketLayoutComponent,
        TicketlistComponent,
        TicketcreateComponent,
        TicketAssignComponent,
        TicketViewComponent,
        TicketNotesComponent,
        MyTicketListComponent,
        NotificationControllersComponent,
        TicketCreateChatbotComponent,
        HeaderComponent,
        UsersListComponent,
        UserCreateComponent,
        UserViewComponent,
        GroupListComponent,
        GroupCreateComponent,
        EquipmentListComponent,
        EquipCreateComponent,
        EquipmentViewComponent,
        RoasterListComponent,
        RoasterCreateComponent,
        UserActivilityListComponent,
        MyprofileComponent,
        HomeComponent,
        NotificationsComponent,
        ChangePasswordComponent,
        TicketCountAsPerDepartmentComponent,
        TicketCountAsPerUserComponent,
        CategoryCreateComponent,
        CategoryListComponent,
        SubcategoryCreateComponent,
        TicketChangeDepartmentComponent,
        CustomRoleCreateComponent,
        CustomRoleListComponent,
        CustomRoleViewComponent,
        DepartmentListComponent,
        DepartmentCreateComponent,
        FormBuilderComponent,
        LocationListComponent,
        LocationCreateComponent,
        ReportCreateComponent,
        AssetsLayoutComponent,
        TicketFeedbackComponent,
        QuestionTypeComponent,
        QuestionTypeCreateComponent,
        ModalQuestionListComponent,
        ModalQuestionCreateComponent,
        SystemStatusListComponent,
        ReportGenerateComponent,
        RegionCreateComponent,
        CountryCreateComponent,
        UserResetPasswordComponent,
        StateCreateComponent,
        CityCreateComponent,
        FloorCreateComponent,
        RoomCreateComponent,
        OtpDialogComponent
    ], imports: [SweetAlert2Module,
        MatTreeModule,
        CdkTreeModule,
        WidgetsModule,
        NgxMaterialTimepickerModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        IonicModule,
        ReactiveFormsModule,
        NgSelectModule,
        MatDialogModule,
        MaterialExampleModule,
        NgApexchartsModule,
        NgOtpInputModule,
        // SweetAlert2Module.forRoot()
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class ComponentsModule { }