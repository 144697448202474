import { Component, OnInit } from '@angular/core';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
//import * as echarts from 'echarts';
import { ChartService } from 'src/app/service/charts.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-ticket-count',
  templateUrl: './ticket-count.component.html',
  styleUrls: ['./ticket-count.component.scss'],
})
export class TicketCountComponent implements OnInit {

  today: any;
  month: any;
  week: any;
  year: any;
  total: any;
  options: any;
  role: string;
  errormsg: any;
  constructor(
    private chartService: ChartService,
    private loginService: LoginService,
    private toastPopupService: ToastPopupService
  ) { }

  ngOnInit() {
    this.role = this.loginService.getRole();  //get loginUser Role 
    this.getticketcount();

  }

  getticketcount() {
    // var chartDom = document.getElementById('bar');
    // var myChart = echarts.init(chartDom);
    // var option;

    this.chartService.getChartData(this.role).subscribe((res) => {

      //   console.log(res,'bbb')

      this.today = res.ticket_today;
      this.week = res.ticket_week;
      this.month = res.ticket_month;
      this.year = res.ticket_year;

      //   console.log([this.today,this.week,this.month,this.year]);



      this.options = {
        title: {
        //  text: 'Ticket Counts',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: ['Today', 'Week', 'Monthly', 'Yearly'],
            axisTick: {
              alignWithLabel: true
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: 'Count',
            type: 'bar',
            barWidth: '60%',
            data: [this.today, this.week, this.month, this.year]
          }
        ]
      }
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to Create Category');
      }
    )
  };

}