import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Widget2Component } from '../widget2/widget2.component';

@Component({
  selector: 'app-widget1',
  templateUrl: './widget1.component.html',
  styleUrls: ['./widget1.component.scss']
})
export class Widget1Component implements OnInit {
  @Input() item: any ;
  @Input() product:any;
  isShown = true; // hidden by default

  constructor(public dialog: MatDialog) { }
  ngOnInit(): void {
  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(Widget2Component, {
      height: '500px',
      width: '600px',
      data: {
        product: this.product,
        name: this.item
      },
    });
  }
}
