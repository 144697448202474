import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

//MeaningClssificationService 
export class TodoallusersService {
  url: string;

  constructor(public http: HttpClient) {
    this.url = environment.BASE_URL;
  }
  private _refreshneeded = new Subject<void>();

  get refreshneeded() {
    return this._refreshneeded;
  }



  createtodo(ngform, ParentId) {
    //console.log('calling fromservice', ngform)
    return this.http.post<Todousers>(
      // this.url + 'crm/create-todo-list', ngform
      this.url + 'to_do/create-todo-list', ngform
    ).pipe(tap(() => {
      this.getChilds(ParentId);
      this._refreshneeded.next();
    })
    );
  }

  getChilds(id) {
    return this.http.get(this.url + 'to_do/child-todo/' + id);

  }

  putTodoUpdate(ngForm, slug) {
    return this.http.put<Todousers>(
      //  this.url + 'crm/todo-task/' + slug + '',

      this.url + 'to_do/todo-task/' + slug + '',
      ngForm).pipe(tap(() => {
        this._refreshneeded.next();
      })
      );
  }
  deletetodo(slug) {
    return this.http.delete<Todousers>(
      //this.url + 'crm/todo-task/' + slug + ''
      this.url + 'to_do/todo-task/' + slug + ''
    ).pipe(tap(() => {
      this._refreshneeded.next();
    })
    );
  }


  getalltodousers() {
    return this.http.get<Todousers[]>(
      this.url +
      'lead/list-users'
    )
  }

  /*to get all tasktype values for dropdown */
  //change the url when u get real url
  getalltasktype() {
    return this.http.get<Todousers[]>(
      this.url +
      'lead/list-users'
    )
  }

  userparticularagenttodolist(param) {
    return this.http.get<Todousers[]>(
      this.url + 'to_do/assigned-todo-tasks/' + param
    )
  }

  getalltodolist(param) {
    return this.http.get<Todousers[]>(
      this.url + 'to_do/todo-list', param
    );
  }


  gettodoBySlag(param) {
    return this.http.get<Todousers>(
      this.url + 'to_do/todo-task/' + param
    );

  }


  updatetodolist(task_list, status) {
    const data = {
      task_status: status,
      task_list: task_list
    }
    return this.http.put<Todousers[]>(

      this.url + 'to_do/todo-change-status', data
    )
  }




}
export interface Todousers {
  username: string;
  emp_id: number;
  response: [];
  task_status: string;
}
