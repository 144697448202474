import { Component,Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-reset-password',
  templateUrl: './user-reset-password.component.html',
  styleUrls: ['./user-reset-password.component.scss'],
})
export class UserResetPasswordComponent implements OnInit {

  /*intalise form */
  changePasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required,]),
    password2: new FormControl('', [Validators.required,]),
  })

  user_id: any;
  errormsg: any;
  error: string;

  constructor(
    private toastPopupService: ToastPopupService,
    private userService: UserService,
    public dialogRef: MatDialogRef<UserResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  ngOnInit() {
    if (this.data.id) {
      this.user_id = this.data.id;
    }
  }

  onSubmit() {
    if (this.changePasswordForm.value.password2 != this.changePasswordForm.value.password) {
      this.error = "password and repassword not matched"
    }
    else {
      this.userService.resetUserPassword(this.changePasswordForm.value,this.user_id).subscribe((res) => {
        this.closeDialogNoRefresh();
        this.toastPopupService.openToast('Updated Password');

      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Update Password');
        }
      )
    }
  }

  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

}
