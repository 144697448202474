// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-area {
  margin-bottom: 20px;
}

.m-t-5 {
  margin-top: 5px !important;
}

label {
  text-transform: capitalize;
}

.bottom-area {
  margin-top: 30px;
}

.mid-area .form-check-label {
  margin-top: 3px;
}

.mid-area .form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 2.5em;
  margin-bottom: 0.125rem;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbS1yb2xlLWNyZWF0ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1CQUFBO0FBQ0Y7O0FBTUE7RUFDRSwwQkFBQTtBQUhGOztBQUtBO0VBQ0UsMEJBQUE7QUFGRjs7QUFJQTtFQUNFLGdCQUFBO0FBREY7O0FBSUE7RUFDRSxlQUFBO0FBREY7O0FBSUE7RUFDRSxjQUFBO0VBQ0Esa0JBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0FBREYiLCJmaWxlIjoiY3VzdG9tLXJvbGUtY3JlYXRlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmlucHV0LWFyZWEge1xyXG4gIG1hcmdpbi1ib3R0b206IDIwcHg7XHJcbn1cclxuXHJcbi8vIC5taWQtYXJlYVxyXG4vLyB7XHJcbi8vICAgbWFyZ2luLXRvcDogMzBweDtcclxuLy8gfVxyXG4ubS10LTUge1xyXG4gIG1hcmdpbi10b3A6IDVweCAhaW1wb3J0YW50O1xyXG59XHJcbmxhYmVsIHtcclxuICB0ZXh0LXRyYW5zZm9ybTogY2FwaXRhbGl6ZTtcclxufVxyXG4uYm90dG9tLWFyZWEge1xyXG4gIG1hcmdpbi10b3A6IDMwcHg7XHJcbn1cclxuXHJcbi5taWQtYXJlYSAuZm9ybS1jaGVjay1sYWJlbCB7XHJcbiAgbWFyZ2luLXRvcDogM3B4O1xyXG59XHJcblxyXG4ubWlkLWFyZWEgLmZvcm0tY2hlY2sge1xyXG4gIGRpc3BsYXk6IGJsb2NrO1xyXG4gIG1pbi1oZWlnaHQ6IDEuNXJlbTtcclxuICBwYWRkaW5nLWxlZnQ6IDIuNWVtO1xyXG4gIG1hcmdpbi1ib3R0b206IDAuMTI1cmVtO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/custom-role/custom-role-create/custom-role-create.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAMA;EACE,0BAAA;AAHF;;AAKA;EACE,0BAAA;AAFF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,uBAAA;AADF;AACA,wjCAAwjC","sourcesContent":[".input-area {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n// .mid-area\r\n// {\r\n//   margin-top: 30px;\r\n// }\r\n.m-t-5 {\r\n  margin-top: 5px !important;\r\n}\r\nlabel {\r\n  text-transform: capitalize;\r\n}\r\n.bottom-area {\r\n  margin-top: 30px;\r\n}\r\n\r\n.mid-area .form-check-label {\r\n  margin-top: 3px;\r\n}\r\n\r\n.mid-area .form-check {\r\n  display: block;\r\n  min-height: 1.5rem;\r\n  padding-left: 2.5em;\r\n  margin-bottom: 0.125rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
