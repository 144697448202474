import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Floor } from '../interface/Floor';

@Injectable({
  providedIn: 'root'
})
export class FloorService {
  constructor(public http: HttpClient) {
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }


  getFloors(page = 1): Observable<Floor> {
    return this.http.get<Floor>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/floors?page=${page}`,
    );
  }

  addFloor(postData): Observable<Floor> {
    return this.http.post<Floor>(
      `${this.endpoint}api/floors/create/`,
      postData
    )
  }

  updateFloor(id, postData) {
    return this.http.put<Floor>(
      `${this.endpoint}api/floors/${id}/`,
      postData
    )
  }

  getFloorData(id) {
    return this.http.get<Floor>(
      `${this.endpoint}api/floors/${id}/`,

    )
  }
  getFloorSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Floor>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/floors?page=${page}&search=${result.search}`
    );
  }
}
