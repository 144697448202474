import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RoasterCreateComponent } from '../roaster-create/roaster-create.component';
import { MaintenanceService } from '../../../../service/maintenance.service';//
import { IonInfiniteScroll } from '@ionic/angular';
import { RoasterService } from 'src/app/service/roaster.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { LoginService } from 'src/app/service/login.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-roaster-list',
  templateUrl: './roaster-list.component.html',
  styleUrls: ['./roaster-list.component.scss'],
})
export class RoasterListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  dialogRef: MatDialogRef<any>;
  tickets: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  roasters: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;
  errormsg: any;

  searchRoasterForm = new FormGroup({
    search: new FormControl('',),
  })


  filterRoasterForm = new FormGroup({
    start_date: new FormControl('',),
    end_date: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;
  LoggedUsername: any;

  constructor(
    private roasterService: RoasterService,
    public dialog: MatDialog, private toastPopupService: ToastPopupService,
  ) { }

  ngOnInit() {
    this.getRoastersList();
  }

  /*func to get ticket list */
  getRoastersList() {

    if (this.refresh == true) {
      this.roasters = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    {
      this.roasterService.getRoasterSearch(this.page, this.searchRoasterForm.value).subscribe((res: any) => {
        //console.log(res);
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.roasters = [
          ...new Map(
            this.roasters
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      },
        (error) => {
          // console.log(error);

          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get Roaster  list');
        });
    }
  }

  /**func to refresh the list */
  refreshRoaster() {
    this.search = false;
    this.refresh = true;
    this.searchRoasterForm.patchValue({
      search: ''

    });
    this.getRoastersList();
  }

  /*func to open create roaster modal */
  addRoasterDialog() {
    this.dialogRef = this.dialog.open(RoasterCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }


  /*func to update roaster modal */
  updateRoasterDialog(res) {
    this.dialogRef = this.dialog.open(RoasterCreateComponent, {
      width: '80%',
      data: res
    });
    this.reloadList();
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getRoastersList();
      }

    });
  }

  /**onscroll load more data into roaster list */
  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.roasters.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getRoastersList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  onSearchSubmit() {
    this.search = true;
    this.refresh = true;
    this.getRoastersList();
  }



}
