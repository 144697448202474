import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CityService } from 'src/app/service/city.service';
import { FloorService } from 'src/app/service/floor.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-floor-create',
  templateUrl: './floor-create.component.html',
  styleUrls: ['./floor-create.component.scss'],
})
export class FloorCreateComponent implements OnInit {

  name: any;
  /*intalise form */
  createFloorForm = new FormGroup({
    name: new FormControl('',[Validators.required]),
    city: new FormControl('',[Validators.required]),
  })
  floor_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  cities: any = [];
  citiesCount: number;

  constructor(
    public dialogRef: MatDialogRef<FloorCreateComponent>,
    private cityService: CityService,
    private floorService: FloorService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data?.id) {
      this.floor_id = this.data.id;
      this.getFloorDetails();
    }
    this.getCityList();
  }

  getFloorDetails() {
    this.floorService.getFloorData(this.floor_id).subscribe((res: any) => {
      this.createFloorForm.patchValue({
        name: res.name,
        city: res?.city.id   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Floor Details');
      }
    )

  }
  onSubmit() {
    if (this.floor_id) {
      this.floorService.updateFloor(this.floor_id, this.createFloorForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Floor update Successfully');   //display success mesg
      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update Floor');
        }
      )
    }
    else {
      this.floorService.addFloor(this.createFloorForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Floor Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Floor');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  getCityList() {
    this.cityService.getCities().subscribe(
      (res: any) => {
        this.cities = [
          ...new Map(
            this.cities.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.citiesCount = res.count;
        this.onScrollCities();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get City List');
      }
    );
  }

  onScrollCities() {
    // console.log('hello');
    if (this.citiesCount != this.cities.length) {
      this.page++;

      this.getCityList();
    }
  }
}
