// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changepassword {
  box-shadow: 0 3px 10px -5px;
  padding: 20px;
}

.changepassword .form-label {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem !important;
}

.heightscroll {
  height: calc(100vh - 13rem) !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNoYW5nZS1wYXNzd29yZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDJCQUFBO0VBQ0EsYUFBQTtBQUNGOztBQUNBO0VBQ0Usa0JBQUE7RUFDQSxnQ0FBQTtBQUVGOztBQUFBO0VBQ0Usc0NBQUE7QUFHRiIsImZpbGUiOiJjaGFuZ2UtcGFzc3dvcmQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY2hhbmdlcGFzc3dvcmQge1xyXG4gIGJveC1zaGFkb3c6IDAgM3B4IDEwcHggLTVweDtcclxuICBwYWRkaW5nOiAyMHB4O1xyXG59XHJcbi5jaGFuZ2VwYXNzd29yZCAuZm9ybS1sYWJlbCB7XHJcbiAgbWFyZ2luLXRvcDogMC41cmVtO1xyXG4gIG1hcmdpbi1ib3R0b206IDAuMnJlbSAhaW1wb3J0YW50O1xyXG59XHJcbi5oZWlnaHRzY3JvbGwge1xyXG4gIGhlaWdodDogY2FsYygxMDB2aCAtIDEzcmVtKSAhaW1wb3J0YW50O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/change-password/change-password.component.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;AACF;;AACA;EACE,kBAAA;EACA,gCAAA;AAEF;;AAAA;EACE,sCAAA;AAGF;AACA,wrBAAwrB","sourcesContent":[".changepassword {\r\n  box-shadow: 0 3px 10px -5px;\r\n  padding: 20px;\r\n}\r\n.changepassword .form-label {\r\n  margin-top: 0.5rem;\r\n  margin-bottom: 0.2rem !important;\r\n}\r\n.heightscroll {\r\n  height: calc(100vh - 13rem) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
