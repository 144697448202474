import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryService } from 'src/app/service/country.service';
import { RegionService } from 'src/app/service/region.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-country-create',
  templateUrl: './country-create.component.html',
  styleUrls: ['./country-create.component.scss'],
})
export class CountryCreateComponent implements OnInit {
  name: any;
  /*intalise form */
  createCountryForm = new FormGroup({
    name: new FormControl('',[Validators.required]),
    region: new FormControl('',[Validators.required]),
  })
  country_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  regions: any = [];
  regionsCount: number;

  constructor(
    public dialogRef: MatDialogRef<CountryCreateComponent>,
    private countryService: CountryService,
    private regionService: RegionService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data?.id) {
      this.country_id = this.data.id;
      this.getCountryDetails();
    }
    this.getRegionsList();
  }

  getCountryDetails() {
    this.countryService.getCountryData(this.country_id).subscribe((res: any) => {
      this.createCountryForm.patchValue({
        name: res.name,
        region: res?.region.id   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Country Details');
      }
    )

  }
  onSubmit() {
    if (this.country_id) {
      this.countryService.updateCountry(this.country_id, this.createCountryForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Country update Successfully');   //display success mesg
        // }


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update country');
        }
      )
    }
    else {
      this.countryService.addCountry(this.createCountryForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Country Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Country');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  getRegionsList() {
    this.regionService.getregions().subscribe(
      (res: any) => {
        //this.roleTypes = res.results;
        this.regions = [
          ...new Map(
            this.regions.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.regionsCount = res.count;
        this.onScrollRegions();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Region List');
      }
    );
  }

  onScrollRegions() {
    // console.log('hello');
    if (this.regionsCount != this.regions.length) {
      this.page++;

      this.getRegionsList();
    }
  }
}
