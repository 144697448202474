import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { VERSION, AfterViewChecked } from '@angular/core';
import { LoginService } from 'src/app/service/login.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
//import { CxmChartService } from '../../../shared/services/cxm-chart.service';
//import { allusersService } from '../../../shared/services/allusers.service';

//declare var jQuery;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  column1=[];
  column2=[];
  role: any;
  username: any;
  displaySidemenu: number = 0;

  constructor(
    private loginService: LoginService,
    private toastPopupService: ToastPopupService,
    //private CxmChartService: CxmChartService,
    // private allusersService: allusersService
  ) { }
  ngOnInit() {
    //this.toastPopupService.openToast('Successfully logedIn');
    // this.CxmChartService.getleadsStagesData().subscribe((res: any) => {});
    // this.allusersService.getallusers().subscribe((data) => {});
    // this.allusersService.getallLeads().subscribe((data) => {});
    this.username = this.loginService.getUsername(); //get loginUser Role
    this.setMenu();
    this.role = this.loginService.getRole();  //get loginUser Role
    if (this.role == 'client' || this.role == 'Client' || this.role == 'user' || this.role == 'User') {
      this.column1 = [ {
        "id": "3",
        "name": "Ticket Status",
      },];
      this.column2 = [ {
        "id": "2",
        "name": "Ticket Counts",
      },];
    } else {
      //this.column1 = ['1', '3', '4'];
    //  this.column2 = ['2', '5'];
    this.column1=[
      {
      "id": "1",
      "name": "Assigned Tickets",
    },
    {
      "id": "3",
      "name": "Ticket Status",
    },
    {
      "id": "4",
      "name": "Department Ticket Counts",
    }
  ]

  this.column2=[
    {
    "id": "2",
    "name": "Ticket Counts",
  },
  {
    "id": "5",
    "name": "New Ticket",
  },
]
    }
  }


  // done = [ '11','12','13','14'];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  setMenu() {
    if (this.username == 'admin') {
      this.displaySidemenu = 1;
    } else {
      this.displaySidemenu = 0;
    }
  }



}

