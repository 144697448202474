import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../../../service/maintenance.service';//
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomRoleCreateComponent } from '../custom-role-create/custom-role-create.component';
import { CustomRoleService } from 'src/app/service/customrole.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { LoginService } from 'src/app/service/login.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomRoleViewComponent } from '../custom-role-view/custom-role-view.component';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-custom-role-list',
  templateUrl: './custom-role-list.component.html',
  styleUrls: ['./custom-role-list.component.scss'],
})
export class CustomRoleListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  tickets: any;
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  customroles: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;
  // filterData: any;
  // filter: boolean;
  errormsg: any;
  /*intalise form */
  searchCustomRoleForm = new FormGroup({
    search: new FormControl('',),
  })
  search: boolean = false;
  results_per_page: any;
  LoggedCustomRolename: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private customroleService: CustomRoleService,
    private screensizeService: ScreensizeService,
    private loginService: LoginService,
    public dialog: MatDialog, private toastPopupService: ToastPopupService
  ) { }

  formatRole(role) {
    if (role == 'client') {
      return 'customrole'
    }
    else {
      return role;
    }
  }

  ngOnInit() {
    this.LoggedCustomRolename = this.loginService.getUsername();
    this.isDesktop = this.screensizeService.getIsDesktop();
    this.getCustomRolesList();
    // this.addticket();
    this.role = this.loginService.getRole();  //get loginUser Role 
    this.getpermissions();
    // console.log(this.permissions);

  }


  getpermissions() {
    this.loginService.getUserPermissions(this.role).subscribe((res: any) => {
      this.permissions = res.results;
    },
      (error) => {

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to Get User Permissions');
      }
    );
  }
  /*func to get ticket list */
  getCustomRolesList() {

    if (this.refresh == true) {
      this.customroles = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    if (this.search) {
      this.customroleService.getSearchCustomRoles(this.page, this.searchCustomRoleForm.value).subscribe((res: any) => {
        //console.log(res);
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.customroles = [
          ...new Map(
            this.customroles
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      });
    } else

    //search


    {
      this.customroleService.getCustomRoles(this.page).subscribe((res: any) => {
        // console.log(res);
        // this.customroles = res.results;
        this.count = res.count;
        this.customroles = [
          ...new Map(
            this.customroles
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      },
        (error) => {

          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Get Custom Role');
        }
      )
    };
  }


  /*func to open create ticket modal */
  addCustomRoleDialog() {
    this.dialogRef = this.dialog.open(CustomRoleCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }

  editCustomRole(id) {
    this.dialogRef = this.dialog.open(CustomRoleCreateComponent, {
      width: '80%',
      data: {
        id: id
      }
    });
    this.reloadList();
  }
  camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getCustomRolesList();
      }

    });
  }

  viewCustomRole(id, res) {
    this.dialogRef = this.dialog.open(CustomRoleViewComponent, {
      data: {
        customrole_id: id,
        customrole_data: res
      },
      width: '80%',
    });
    this.reloadList();
  }

  updateCustomRole(id, res) {
    this.dialogRef = this.dialog.open(CustomRoleCreateComponent, {
      data: {
        customrole_id: id,
        customrole_data: res
      },
      width: '80%',
    });
    this.reloadList();
  }



  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.customroles.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getCustomRolesList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }


  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getCustomRolesList();
  }

  refreshCustomRoles() {
    // this.filter=false;
    this.search = false;
    this.refresh = true;
    this.searchCustomRoleForm.patchValue({
      search: ''

    });
    this.getCustomRolesList();
  }
}

