import { Component, OnInit } from '@angular/core';

import { ToastPopupService } from 'src/app/service/toast-popup.service';
import * as echarts from 'echarts';
import { ChartService } from 'src/app/service/charts.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-task-summary',
  templateUrl: './task-summary.component.html',
  styleUrls: ['./task-summary.component.scss'],
})
export class TaskSummaryComponent implements OnInit {
  errormsg: any;
  data: any;
  nam: any;
  count: any;
  result: any;
  A_key: any;
  A_ke: any;
  A_val: any;
  role: any;
  unassignedCount: any;

  constructor(
    private chartService: ChartService,
    private toastPopupService: ToastPopupService,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.role = this.loginService.getRole(); //get loginUser Role
    this.getticketsumary();
    this.getUnassignedCount();
    //  this.data=[
    //   {
    //     "technician__username": "lead",
    //     "count": 0
    //   },
    //   {
    //     "technician__username": "manager",
    //     "count": 1
    //   },
    //   {
    //     "technician__username": "technician",
    //     "count": 2
    //   }
    //     ];
  }

  getticketsumary() {
    this.chartService.getticketsummary().subscribe((res) => {
      //    this.data = res;
      this.nam = res.users;
      this.count = res.no_of_tickets;
      //     delete this.nam[1];
      //     delete this.count[1];
      //     this.result = this.nam.concat(this.count);
      this.A_key = (Object.values(this.nam));
      this.A_ke = (Object.keys(this.nam));
      this.A_val = (Object.values(this.count));

      // console.log(this.A_key,'ssss');
      // console.log(this.A_ke,'sssskkk');
      // this.data = Object.keys(this.A_key).map(key => ({technician__username:this.A_key[key],count: key}));
      // console.log(this.A_val,';;;;;;;');
      // this.A_key=this.A_key.filter((x:any)=>{ return x.technician__username!=null;});
      // this.A_val=this.A_val.filter((x:any)=>{ return x.count!=null;});

    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Category list');
      });
  }

  getUnassignedCount(){
    this.chartService.getticketstatusData(this.role).subscribe((res) => {
     this.unassignedCount=res.pending_tickets;

    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Category list');
      });

}
}

