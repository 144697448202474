import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/service/users.service';

@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss'],
})
export class GroupCreateComponent implements OnInit {
  /*intalise form */
  createGroupForm = new FormGroup({
    name: new FormControl('',)
  })
  errormsg: any;
  toastPopupService: any;
  constructor(private userService: UserService, public dialogRef: MatDialogRef<GroupCreateComponent>,) { }

  ngOnInit() { }


  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }


  onSubmit() {
    this.userService.addGroup(this.createGroupForm.value).subscribe((res: any) => {
      // this.groupTypes = res.results;
      this.closeDialog();
    },

      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to Create Group');
      });
  }
}
