export const Status = {
    Created : {
        status: "Created",
        status_code: 1,
        status_color: "darkGrey"
    },
    Approved: {
        status: "Approved",
        status_code: 2,
        status_color: "lightGreen"
    },
    Assigned : {
        status: "Assigned",
        status_code: 3,
        status_color: "purple"
    },
    Opened : {
        status: "Opened",
        status_code: 4,
        status_color: "yellow"
    },
    Solved : {
        status: "Solved",
        status_code: 5,
        status_color: "green"
    },
    Cancelled: {
        status: "Cancelled",
        status_code: 6,
        status_color: "red"
    },
    Reopened: {
        status: "Reopened",
        status_code: 7,
        status_color: "brightRed"
    },
    Closed: {
        status: "Closed",
        status_code: 8,
        status_color: "blue"
    },
    OnHold: {
        status: "On Hold",
        status_code: 9,
        status_color: "lightBlue"
    },
    Deleted: {
        status: "Deleted",
        status_code: 10,
        status_color: "lightGray"
    },
}

export const ticketStatus = [
    {
        status: "Created",
        status_code: 1,
        status_color: "darkGrey"
    },{
        status: "Approved",
        status_code: 2,
        status_color: "lightGreen"
    },{
        status: "Assigned",
        status_code: 3,
        status_color: "purple"
    },{
        status: "Opened",
        status_code: 4,
        status_color: "yellow"
    },{
        status: "Solved",
        status_code: 5,
        status_color: "green"
    },{
        status: "Cancelled",
        status_code: 6,
        status_color: "red"
    },{
        status: "Reopened",
        status_code: 7,
        status_color: "brightRed"
    },{
        status: "Closed",
        status_code: 8,
        status_color: "blue"
    },{
        status: "On Hold",
        status_code: 9,
        status_color: "lightBlue"
    },{
        status: "Deleted",
        status_code: 10,
        status_color: "lightGray"
    },
];