// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-product.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f8fa !important;
}

.table-product td {
  min-width: 120px;
  border-top: 0px solid #dee2e6 !important;
  color: #728299 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVxdWlwbWVudC12aWV3LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0Usb0NBQUE7QUFDRjs7QUFDQTtFQUNFLGdCQUFBO0VBQ0Esd0NBQUE7RUFDQSx5QkFBQTtBQUVGIiwiZmlsZSI6ImVxdWlwbWVudC12aWV3LmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLnRhYmxlLXByb2R1Y3QudGFibGUtc3RyaXBlZCB0Ym9keSB0cjpudGgtb2YtdHlwZShvZGQpIHtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjZjNmOGZhICFpbXBvcnRhbnQ7XHJcbn1cclxuLnRhYmxlLXByb2R1Y3QgdGQge1xyXG4gIG1pbi13aWR0aDogMTIwcHg7XHJcbiAgYm9yZGVyLXRvcDogMHB4IHNvbGlkICNkZWUyZTYgIWltcG9ydGFudDtcclxuICBjb2xvcjogIzcyODI5OSAhaW1wb3J0YW50O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/equipments/equipment-view/equipment-view.component.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;AACF;;AACA;EACE,gBAAA;EACA,wCAAA;EACA,yBAAA;AAEF;AACA,woBAAwoB","sourcesContent":[".table-product.table-striped tbody tr:nth-of-type(odd) {\r\n  background-color: #f3f8fa !important;\r\n}\r\n.table-product td {\r\n  min-width: 120px;\r\n  border-top: 0px solid #dee2e6 !important;\r\n  color: #728299 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
