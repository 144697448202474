import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../interface/User';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  private myData: BehaviorSubject<User[]> = new BehaviorSubject<User[]>(
    []
  );

  private _refreshneeded = new Subject<void>();

  get refreshneeded() {
    return this._refreshneeded;
  }

  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }



  modalQuestionsCreate(payload): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/model_question/create/`, payload
    )
  }

  getModalQuestionsList(): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/model_question/`,
    )
  }

  getQuestionsList(template_id): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/question/?template=${template_id}`,
    )
  }

  makeDefaultTemplate(id, payload): Observable<any> {
    return this.http.put<any>(
      `${this.endpoint}api/template/${id}/`, payload
    )
  }

  getTemplate(search = ''): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/template/?search=${search}`
    )
  }

  createTemplate(payload): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/template/create/`, payload
    )
  }


  deleteTemplate(id): Observable<any> {
    return this.http.delete<any>(
      `${this.endpoint}api/template/${id}/delete/`
    )
  }

  updateTemplate(payload): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/template/edit/`, payload
    )
  }

  getQuestions(template_id, sno): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/question/${template_id}/${sno}`,
    )
  }

  saveAnswers(postData): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/answer_group/create/`,
      postData
    )
  }

  createTicket(): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/ticket/create/`,

    )
  }


  questionTypeTicket(): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/question_type/`,
    )
  }

  questionTypeTicketCreate(payload): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/question_type/create/`, payload
    )
  }




  fieldTypeList(): Observable<any> {
    return this.http.get<any>(
      `${this.endpoint}api/question_type/`,
    )
  }

  fieldTypeCreate(payload): Observable<any> {
    return this.http.post<any>(
      `${this.endpoint}api/question_type/create/`, payload
    )
  }



}