import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Equipment } from '../interface/Equipment';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getEquipments(page = 1, sub_category = ''): Observable<Equipment> {

    return this.http.get<Equipment>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}equipment/api/?page=${page}&sub_category=${sub_category}`,
      //this.endpoint +'auth/',

    );

  }


  discardEquipments(id, payload) {

    return this.http.put<Equipment>(

      `${this.endpoint}equipment/api/discard/${id}/`, payload
    );
  }


  getEquipmentsSearch(page = 1, search = '', subcategoryID = '', department_id) {
    // if(result.search==undefined){
    //   result.search='';
    // }
    return this.http.get<Equipment>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}equipment/api/?page=${page}&search=${search}&sub_category=${subcategoryID}`
    );
  }

  getEquipmentDetail(id: any) {

    return this.http.get<any>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}equipment/api/${id}`,
      //this.endpoint +'auth/',

    );

  }



  addEquipment(postData) {
    return this.http.post<Equipment>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}equipment/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  updateEquipment(id, postData) {
    return this.http.put<Equipment>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}equipment/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }
}
