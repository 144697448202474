import { Component, Inject, OnInit } from '@angular/core';
import {
  CdkDrag,
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { ChatbotService } from 'src/app/service/chatbot.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalQuestion } from 'src/app/interface/ModalQuestion';

@Component({
  selector: 'app-ticket-template-create',
  templateUrl: './ticket-template-create.component.html',
  styleUrls: ['./ticket-template-create.component.scss'],
})
export class TicketTemplateCreateComponent implements OnInit {
  text = 'Angular 5\nfoo';
  enabled = true;

  question_count: any;
  questions: any;
  todo = [];

  Form = new FormGroup({
    template: new FormControl('', [Validators.required]),
  });

  templateId: any;
  template_id: any;
  //viewmode: number = 0;
  template_name: any;
  constructor(
    private chatbotService: ChatbotService,
    public dialogRef: MatDialogRef<TicketTemplateCreateComponent>,
    //refrence to modal
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data) {
      this.template_id = this.data.id;
      // //console.log(this.data);
      this.template_name = this.data.Data.name;
      this.Form.controls['template'].disable();
      this.Form.patchValue({
        template: this.template_name,
        // monitoring_team: res.monitoring_team,
      });
      // this.viewmode = 1;
      // this.getQuestionsList();
      this.getModalQuestionsList();
    } else {
      this.getModalQuestionsList();
    }
  }

  public getPendingQuestionsInModalList() { }

  public getQuestionsList() {
    this.chatbotService
      .getQuestionsList(this.template_id)
      .subscribe((res: any) => {
        this.question_count = res.count;
        this.done = res.results;
        // let array2=this.done;
        // let array1=this.todo;
        // const array1 = [1, 2, 3, 4, 5, 6];
        // const array2 = [1, 2, 3, 4, 5, 6, 7, 8, 9];
        var arr1 = this.done;
        var arr2 = this.todo;
        var uniqueArr = [];
        this.todo = this.unique(arr1, arr2, uniqueArr).flat();
        
      });
  }

  public unique(arr1, arr2, uniqueArr) {
    let flag;
    for (var i = 0; i < arr1.length; i++) {
      flag = 0;
      for (var j = 0; j < arr2.length; j++) {
        if (arr1[i].question_type === arr2[j].question_type) {
          arr2.splice(j, 1);
          j--;
          flag = 1;
        }
      }

      if (flag == 0) {
        uniqueArr.push(arr1[i]);
      }
    }
    uniqueArr.push(arr2);
    return uniqueArr;
  }

  public getModalQuestionsList() {
    this.chatbotService
      .getModalQuestionsList
      //this.template_id
      ()
      .subscribe((res: any) => {
        this.question_count = res.count;
        this.questions = res.results;
        // //console.log(res);
        let result = res.results;
        this.todo = result;

        if (this.data) {
          this.getQuestionsList();
        } else {
          this.done = result.filter((x) => x.basic == true); //if it is not in edit mode, add default questions here
          this.todo = result.filter((x) => x.basic == false);
        }
        // //console.log(this.todo);
        // //console.log(this.question_count,'actual count');
        // this.startFlow();
      });
  }

  /**
   *
   * @param e - event to know checked or uncheck
   * @param skipitem - which item is pressed
   * Function to update skip value of item
   */
  skipanswer(e, skipitem) {
    //console.log(e.detail.checked, 'event');
    //console.log(skipitem.question_type);
    //console.log(this.done);
    /*find index of pressed item*/
    let item_index = this.done.findIndex(
      (item) => item.question_type === skipitem.question_type
    );
    /*update the skip value of item*/
    this.done[item_index].skip = e.detail.checked;
    //console.log(this.done);
  }


  enterPred(item: CdkDrag<ModalQuestion>) {
    console.log(item.dropContainer.data);
    let flag;

    if (item.data.question_type == 'city') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'floor')
          .length > 0;
    } else if (item.data.question_type == 'floor') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'room')
          .length > 0;
    } else if (item.data.question_type == 'category') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'sub_category')
          .length > 0;
    } else if (item.data.question_type == 'sub_category') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'item')
          .length > 0;
    }
    // this.sortPredicate(0, item);
    return !flag;
  }

  enterPredicate(item: CdkDrag<ModalQuestion>) {
    //console.log(item.dropContainer.data);
    let flag;
    if (item.data.question_type == 'room') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'floor')
          .length > 0;
    } else if (item.data.question_type == 'floor') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'city')
          .length > 0;
    } else if (item.data.question_type == 'sub_category') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'category')
          .length > 0;
    } else if (item.data.question_type == 'item') {
      flag =
        item.dropContainer.data.filter((x) => x.question_type == 'sub_category')
          .length > 0;
    }
    // this.sortPredicate(0, item);
    return !flag;
  }

  /**
   * Predicate function that only allows even numbers to be
   * sorted into even indices and odd numbers at odd indices.
   */
  sortPredicate(index: number, item: CdkDrag<ModalQuestion>) {
    //console.log(item.dropContainer.data);
    // //console.log(item.data);
    //console.log(index, 'item_index');
    let city_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'city'
    );
    let floor_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'floor'
    );
    let room_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'room'
    );
    ////console.log(room_index,"room_index" );

    let flag = true;
    if (item.data.question_type == 'city') {
      flag = floor_index > index;
    } else if (item.data.question_type == 'floor') {
      let f1 = false,
        f2 = false;
      if (index > city_index) {
        //floor index should be greter than city
        f1 = true;
      }
      if (room_index > -1) {
        //if room exists --room index should be more
        if (room_index > index) {
          f2 = true;
        }
      } else {
        //if room not there make it true
        f2 = true;
      }
      flag = f1 && f2;
      //  flag = room_index > index && index > city_index; //child_index>parent_index
    } else if (item.data.question_type == 'room') {
      flag = index > floor_index;
    }

    let department_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'department'
    );
    let category_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'category'
    );
    let sub_category_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'sub_category'
    );
    let equip_index = item.dropContainer.data.findIndex(
      (item) => item.question_type === 'item'
    );

    if (item.data.question_type == 'department') {
      flag = category_index > index;
    }
    else if (item.data.question_type == 'category') {
      let f1 = false,
        f2 = false;
      if (index > department_index) {
        //floor index should be greter than city
        f1 = true;
      }
      if (sub_category_index > -1) {
        //if room exists --room index should be more
        if (sub_category_index > index) {
          f2 = true;
        }
      } else {
        //if room not there make it true
        f2 = true;
      }
      flag = f1 && f2;

    }
    else if (item.data.question_type == 'sub_category') {
      let f1 = false,
        f2 = false;
      if (index > category_index) {
        //floor index should be greter than city
        f1 = true;
      }
      if (equip_index > -1) {
        //if room exists --room index should be more
        if (equip_index > index) {
          f2 = true;
        }
      } else {
        //if room not there make it true
        f2 = true;
      }

      flag = f1 && f2;

      //  flag = equip_index > index && index > category_index;
    }
    else if (item.data.question_type == 'item') {
      flag = index > sub_category_index;
    }
    return flag;
  }

  // sequencePredicate(index: number, item: CdkDrag<any>) {
  //         //console.log(item.data
  //           );
  //           //console.log(item.data[0].question_type
  //             );
  //       //return true;
  //       return (index + 1) % 2 === item.data % 2;
  //     }

  // todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = [];

  test(e) {
    //console.log(e, 'hiii');
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      // //console.log(event.container.data,
      //   event.previousIndex,
      //   event.currentIndex, "move");
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      this.validateOrder(event.item.data, event.container.connectedTo);
    }
  }

  validateOrder(item, connectedTo) {
    let array;
    if (connectedTo == 'all') {
      array = this.done;
    } else if (connectedTo == 'even') {
      array = this.todo;
    }
    let city_index = array.findIndex((item) => item.question_type === 'city');
    let floor_index = array.findIndex((item) => item.question_type === 'floor');
    let room_index = array.findIndex((item) => item.question_type === 'room');
    let department_index = array.findIndex(
      (item) => item.question_type === 'department'
    );
    let category_index = array.findIndex(
      (item) => item.question_type === 'category'
    );
    let sub_category_index = array.findIndex(
      (item) => item.question_type === 'sub_category'
    );
    let equip_index = array.findIndex((item) => item.question_type === 'item');
    // this.done = this.done.filter((item) => item.question_type !== item.question_type);

    if (item.question_type == 'category') {
      array = array.filter((x) => x.question_type !== item.question_type);
      array.splice(department_index + 1, 0, item);
    }

    if (item.question_type == 'sub_category') {
      array = array.filter((x) => x.question_type !== item.question_type);
      array.splice(category_index + 1, 0, item);
    }

    if (item.question_type == 'item') {
      array = array.filter((x) => x.question_type !== item.question_type);
      array.splice(sub_category_index + 1, 0, item);
    }

    if (item.question_type == 'floor') {
      array = array.filter((x) => x.question_type !== item.question_type);
      array.splice(city_index + 1, 0, item);
    }

    if (item.question_type == 'room') {
      array = array.filter((x) => x.question_type !== item.question_type);
      array.splice(floor_index + 1, 0, item);
    }

    if (connectedTo == 'all') {
      this.done = array;
    } else if (connectedTo == 'even') {
      array = this.done;
    }

    //    console.log(department_index,"department",category_index,"category",sub_category_index,"sub");
    // if(item.question_type == 'category'){
    //     if (department_index > category_index) {
    //       if(this.array_move(this.done, department_index, category_index))
    //       console.log(department_index,"department",category_index,"category");
    //     }
    //   }

    // if(item.question_type == 'sub_category'){
    //   // if (department_index > category_index) {
    //   //   if(this.array_move(this.done, department_index, category_index))
    //   //   console.log(department_index,"department",category_index,"category");
    //   // }
    //   if (category_index > sub_category_index) {
    //     if(this.array_move(this.done, category_index, sub_category_index))
    //     {
    //     if (department_index > category_index) {
    //       this.array_move(this.done, department_index, category_index)
    //     }
    //   }
    // }

    // }

    // if (category_index > sub_category_index) {
    //   this.array_move(this.done, category_index, sub_category_index);
    //   console.log(department_index,"department",category_index,"category",sub_category_index,"sub");

    // }

    // if (sub_category_index > equip_index) {
    //       //console.log(this.array_move(this.done, sub_category_index, equip_index));
    //     }

    // if (item.question_type == 'item') {
    //   if (sub_category_index > equip_index) {
    //     //console.log(this.array_move(this.done, sub_category_index, equip_index));
    //   }
    // }
    // else if (item.question_type == 'floor') {

    //   if (city_index > floor_index) {    //parent_index > child_index
    //     //console.log(this.array_move(this.done, city_index, floor_index));
    //   }
    // }
    // else if (item.question_type == 'room') {

    //   if (floor_index > room_index) {    //parent_index > child_index
    //     //console.log(this.array_move(this.done, floor_index, room_index));
    //   }
    // }
  }

  onSubmit() {
    if (this.data) {
      this.updateTemplate();
    } else {
      this.createTemplate();
    }
  }

  createTemplate() {
    // //console.log(this.done);
    // //console.log(this.todo);
    const payload = {
      template: this.Form.value.template,
      questions: this.done,
    };
    this.chatbotService
      .createTemplate(
        payload
        //this.template_id
      )
      .subscribe((res: any) => {
        this.closeDialog();
        // //console.log(res);
        // //console.log(this.question_count,'actual count');
        // this.startFlow();
      });
  }

  updateTemplate() {
    // //console.log(this.done);
    // //console.log(this.todo);
    const payload = {
      template: this.template_name,
      questions: this.done,
    };
    this.chatbotService
      .updateTemplate(
        payload
        //this.template_id
      )
      .subscribe((res: any) => {
        this.closeDialog();
        // //console.log(res);
        // //console.log(this.question_count,'actual count');
        // this.startFlow();
      });
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  editQuestiontext(item) {
    //console.log(item, 'editquestion');
  }

  model = 'initial model';

  disabled = false;

  formGroup = new FormGroup({
    name: new FormControl('initial control'),
  });

  formControl = new FormControl('initial control', { updateOn: 'blur' });

  disableModel() {
    this.disabled = !this.disabled;
  }

  resetModel() {
    this.model = 'initial model';
  }

  disableFormControl() {
    if (this.formControl.disabled) {
      this.formControl.enable();
    } else {
      this.formControl.disable();
    }
  }

  resetFormControl() {
    this.formControl.reset('initial control');
  }

  disableFormGroup() {
    if (this.formGroup.disabled) {
      this.formGroup.enable();
    } else {
      this.formGroup.disable();
    }
  }

  resetFormGroup() {
    this.formGroup.reset({ name: 'initial control' });
  }

  // returns [2, 1, 3]
}
