import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})

export class SidemenuComponent implements OnInit {
  Gender: any;
  firstname: any;
  lastname: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: string;
  menuList = [];
  selectedItem: any;
  subMenuList = [];
  username: any;
  userFullName: any;
  adminMenuList = [];
  showNotifications: boolean;
  isDesktop: boolean = false;
  permissions: any;
  LoggedUserPermission: any;
  role_id: any;
  permission: any;
  errormsg: any;
  constructor(private router: Router) { }

  ngOnInit() {
    this.setMenu();
  }

/**Func to set the menu items */
setMenu(){
    this.adminMenuList = [   
      {
        id: 1,
        name: 'Dashboard',
        value: 'home',
        page: '/home/dashboard',
        icon: 'fa fa-home',
      },     
      {
        id: 2,
        name: 'Roster',
        value: 'roaster',
        page: '/home/roaster',
        icon: 'fa fa-users',
      },
      {
        id: 3,
        name: 'Activity',
        value: 'acticity',
        page: '/home/activity',
        icon: 'fa fa-tasks',
      },
      {
        id: 4,
        name: 'Assets',
        value: 'Assets',
        page: '/home/assets',
        icon: 'fa fa-area-chart',
      },
      // {
      //   id: 4,
      //   name: 'Inventory',
      //   value: 'Inventory',
      //   page: '/home/inventory',
      //   icon: 'fa fa-inbox',
      // },
      {   
        id: 5,
        name: 'reports',
        value: 'reports',
        page: '/home/reports',
        icon: 'fa fa-line-chart',
      },
      // {
      //   id: 15,
      //   name: 'Reports',
      //   value: 'report-generate',
      //   page: '/home/report-generate',
      //   icon: 'fa fa-line-chart',
      // },
      {
        id: 6,
        name: 'Users',
        value: 'users',
        page: '/home/users',
        icon: 'fa fa-user',
      },
      {
        id: 7,
        name: 'Roles',
        value: 'customrole',
        page: '/home/custom-role',
        icon: 'fa fa-address-card',
      },
      {
        id: 8,
        name: 'Department',
        value: 'department',
        page: '/home/department',
        icon: 'fa fa-building-o',
      },
      {
        id: 9,
        name: 'Region',
        value: 'region',
        page: '/home/region',
        icon: 'fa fa-globe',
      },
      {
        id: 9,
        name: 'Country',
        value: 'country',
        page: '/home/country',
        icon: 'fa fa-map-marker',
      },
      {
        id: 9,
        name: 'State',
        value: 'state',
        page: '/home/state',
        icon: 'fa fa-map-marker',
      },
      {
        id: 10,
        name: 'City',
        value: 'city',
        page: '/home/city',
        icon: 'fa fa-map-marker',
      },
      {
        id: 11,
        name: 'Floor',
        value: 'floor',
        page: '/home/floor',
        icon: 'fa fa-building-o',
      },
      {
        id: 12,
        name: 'Room',
        value: 'room',
        page: '/home/room',
        icon: 'fa fa-building-o',
      },
      {
        id: 13,
        name: 'ticket Flow',
        value: 'Ticket Flow',
        page: '/home/ticketq',
        icon: 'fa fa-ticket',
      },
      {
        id: 14,
        name: 'Modal questions',
        value: 'Modal questions',
        page: '/home/ticketModalQuestion',
        icon: 'fa fa-question',
      },
      {
        id: 15,
        name: 'Notifications',
        value: 'Notifications',
        page: '/home/autoassign',
        icon: 'fa fa-bell',
      },
      // {
      //   id: 11,
      //   name: 'Push Notification',
      //   value: 'home',
      //   page: '/home/push-notification',
      //   icon: 'fa fa-bell',
      // },
    ];
  }

  /*depending on menuItem open the page*/
  menuItem(value) {
    this.router.navigate([value.page]);
    this.selectedItem = value;
  }

}