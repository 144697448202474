import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../../../service/maintenance.service'; //
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { EquipCreateComponent } from '../equip-create/equip-create.component';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormGroup, FormControl } from '@angular/forms';
import { EquipmentViewComponent } from '../equipment-view/equipment-view.component';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
//import Swal from 'sweetalert2/dist/sweetalert2.js'
//import 'sweetalert2/src/sweetalert2.scss'
//import 'sweetalert2/src/sweetalert2.scss'
@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
})
export class EquipmentListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Input('subcategoryID') subcategoryID = '';
  @Input('departmentID') departmentID = '';
  @Input('refreshPage') refreshPage = '';

  private searchEventSubscription: Subscription;
  errormsg: any;
  tickets: any;
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  Equipments: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  refresh: boolean;
  search: boolean = false;

  searchUserForm = new FormGroup({
    search: new FormControl(''),
  });
  results_per_page: any;
  displayAddIcon = false;

  constructor(
    private maintenanceService: MaintenanceService,
    private equipmentService: EquipmentService,
    private screensizeService: ScreensizeService,
    public dialog: MatDialog,
    private toastPopupService: ToastPopupService,
  ) { }

  ngOnInit() {
    // console.log(this.refreshPage, 'hiiiiiiiiii');
    this.isDesktop = this.screensizeService.getIsDesktop();

    if (this.subcategoryID) {
      //this.displayAddIcon=true;
      this.getEquipmentsList();
    }

    if (this.refreshPage == 'refresh') {
      this.getEquipmentsList();
    }
  }

  /*func to get ticket list */
  getEquipmentsList() {
   
    if (this.subcategoryID == '0') {
      this.displayAddIcon = false;
    } else {
      this.displayAddIcon = true;
    }
    if (this.refresh == true) {
      this.Equipments = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    if (this.subcategoryID != '0') {
      this.searchEventSubscription = this.equipmentService
        .getEquipmentsSearch(
          this.page,
          this.searchUserForm.value.search,
          this.subcategoryID,
          this.departmentID
        )
        .subscribe((res: any) => {
          //console.log(res);
          // this.Equipments=res;
          this.results_per_page = res.results_per_page;
          //const tickets1 = res.results;
          this.count = res.count;
          this.Equipments = [
            ...new Map(
              this.Equipments.concat(res.results).map((item) => [
                item['id'],
                item,
              ])
            ).values(),
          ];
        },
          (error) => {
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to get Assets List');
          }
        )
    };
  }


  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();
      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.Equipments.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      } else {
        this.page++;
        this.getEquipmentsList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  refreshEquipment() {
    // this.filter=false;
    this.search = false;
    this.refresh = true;
    this.searchUserForm.patchValue({
      search: '',
    });
    this.getEquipmentsList();
  }

  onSearchSubmit() {
    this.search = true;
    this.refresh = true;
    this.getEquipmentsList();
  }

  /*func to open create ticket modal */
  addEquipmentDialog() {
    this.dialogRef = this.dialog.open(EquipCreateComponent, {
      width: '80%',
      data: {
        subcategoryID: this.subcategoryID,
      },
    });
    this.reloadList();
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getEquipmentsList();
      } else {
      }
    });
  }

  discard(equip_id, equip_data) {

    this.dialogRef = this.dialog.open(EquipmentViewComponent, {
      data: {
        equip_id: equip_id,
        equip_data: equip_data,
        deleteButton: true
      },
      width: '80%',
    });

    this.reloadList();
    /*
     Swal.fire({
       title: 'Are you sure?',
       text: "You won't be able to revert this!",
       icon: 'warning',
       heightAuto: false,
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, delete it!'
     }).then((result) => {
       if (result.isConfirmed) {
       //  this.del(id);
         // Swal.fire(
         //   'Deleted!',
         //   'Your file has been deleted.',
         //   'success',
         // )
   
         this.del(equip_id);
       }
     })
   
     */

    // Swal.fire({
    //   title: 'Error!',
    //   text: 'Do you want to continue',
    //   icon: 'error',
    //   confirmButtonText: 'Cool',
    //   heightAuto: false
    // })


  }

  del(id) {
    const payload = {
      "is_discarded": true,
    }

    {
      this.equipmentService.discardEquipments(id, payload)
        .subscribe((res: any) => {
          this.refreshEquipment();
        });
    }
  }


  /*func to open create ticket modal */
  updateEquipment(id, equip_data) {
    this.dialogRef = this.dialog.open(EquipCreateComponent, {
      width: '80%',
      data: {
        id: id,
        equip_data: equip_data,
        subcategoryID: this.subcategoryID,
      },
    });
    this.reloadList();
  }

  viewEquipment(equip_id, equip_data) {
    this.dialogRef = this.dialog.open(EquipmentViewComponent, {
      data: {
        equip_id: equip_id,
        equip_data: equip_data,
      },
      width: '80%',
    });
  }

  ngOnChanges() {
    // console.log(this.refreshPage);
    if (this.subcategoryID) {

      this.refresh = true;
      this.getEquipmentsList();
    }
    if (this.refreshPage == 'refresh') {
      this.refresh = true;
      // console.log(this.Equipments);
      this.Equipments = [];
      // console.log(this.Equipments);
      //  this.getEquipmentsList();
    }
  }


}
