import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Department } from '../interface/Department';
import { CustomRole } from '../interface/CustomRole';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { TechnicianUser } from '../interface/TechnicianUser';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '../interface/User';

@Injectable({
  providedIn: 'root'
})
export class CustomRoleService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getCustomRoles(page = 1): Observable<CustomRole> {
    return this.http.get<CustomRole>(
      `${this.endpoint}api/role/?page=${page}`,
    );
  }

  createCustomRole(postData) {
    return this.http.post<CustomRole>(
      `${this.endpoint}api/role/create/`,
      postData
    )
  }



  getPermissions(page = 1): Observable<CustomRole> {
    return this.http.get<CustomRole>(
      `${this.endpoint}api/permission/?page=${page}`,
    );
  }

  readCustomRole(id): Observable<CustomRole> {
    return this.http.get<CustomRole>(
      `${this.endpoint}api/role/${id}/`,
    );
  }

  getSearchCustomRoles(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<User>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/role/?page=${page}&search=${result.search}`
    );
  }

  /*api for getting role*/

  /* getTechnicians(){
    return this.http.get<TechnicianUser>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
     // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/technician/`      
      );
  }*/


  addCustomRole(postData) {
    return this.http.post<User>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}user/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    );
  }


  updateCustomRole(id, postData) {
    return this.http.put<CustomRole>(
      `${this.endpoint}api/role/${id}/`,
      postData
    )
  }


  updatePassword(postData) {
    return this.http.put<User>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}api/change/password/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }

  getCustomRoleData(id) {
    return this.http.get<User>(
      `${this.endpoint}user/api/${id}/`,
    )
  }

}
