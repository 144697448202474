// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  padding: 0px;
  margin: 0px;
}

#title-bar {
  -webkit-app-region: drag;
  height: 24px;
  background-color: darkviolet;
  padding: none;
  margin: 0px;
}

#title {
  position: fixed;
  top: 0px;
  left: 6px;
}

#title-bar-btns {
  -webkit-app-region: no-drag;
  position: fixed;
  top: 0px;
  right: 6px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFwcC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSx3QkFBQTtFQUNBLFlBQUE7RUFDQSw0QkFBQTtFQUNBLGFBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBRUE7RUFDRSxlQUFBO0VBQ0EsUUFBQTtFQUNBLFNBQUE7QUFDRjs7QUFFQTtFQUNFLDJCQUFBO0VBQ0EsZUFBQTtFQUNBLFFBQUE7RUFDQSxVQUFBO0FBQ0YiLCJmaWxlIjoiYXBwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiYm9keSB7XHJcbiAgcGFkZGluZzogMHB4O1xyXG4gIG1hcmdpbjogMHB4O1xyXG59XHJcblxyXG4jdGl0bGUtYmFyIHtcclxuICAtd2Via2l0LWFwcC1yZWdpb246IGRyYWc7XHJcbiAgaGVpZ2h0OiAyNHB4O1xyXG4gIGJhY2tncm91bmQtY29sb3I6IGRhcmt2aW9sZXQ7XHJcbiAgcGFkZGluZzogbm9uZTtcclxuICBtYXJnaW46IDBweDtcclxufVxyXG5cclxuI3RpdGxlIHtcclxuICBwb3NpdGlvbjogZml4ZWQ7XHJcbiAgdG9wOiAwcHg7XHJcbiAgbGVmdDogNnB4O1xyXG59XHJcblxyXG4jdGl0bGUtYmFyLWJ0bnMge1xyXG4gIC13ZWJraXQtYXBwLXJlZ2lvbjogbm8tZHJhZztcclxuICBwb3NpdGlvbjogZml4ZWQ7XHJcbiAgdG9wOiAwcHg7XHJcbiAgcmlnaHQ6IDZweDtcclxufVxyXG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;;AAEA;EACE,wBAAA;EACA,YAAA;EACA,4BAAA;EACA,aAAA;EACA,WAAA;AACF;;AAEA;EACE,eAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,2BAAA;EACA,eAAA;EACA,QAAA;EACA,UAAA;AACF;AACA,w9BAAw9B","sourcesContent":["body {\r\n  padding: 0px;\r\n  margin: 0px;\r\n}\r\n\r\n#title-bar {\r\n  -webkit-app-region: drag;\r\n  height: 24px;\r\n  background-color: darkviolet;\r\n  padding: none;\r\n  margin: 0px;\r\n}\r\n\r\n#title {\r\n  position: fixed;\r\n  top: 0px;\r\n  left: 6px;\r\n}\r\n\r\n#title-bar-btns {\r\n  -webkit-app-region: no-drag;\r\n  position: fixed;\r\n  top: 0px;\r\n  right: 6px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
