import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { City } from '../interface/City';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  constructor(public http: HttpClient) {
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }


  getCities(page = 1): Observable<City> {
    return this.http.get<City>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/cities?page=${page}`,
    );
  }

  addCity(postData): Observable<City> {
    return this.http.post<City>(
      `${this.endpoint}api/cities/create/`,
      postData
    )
  }

  updateCity(id, postData) {
    return this.http.put<City>(
      `${this.endpoint}api/cities/${id}/`,
      postData
    )
  }

  getCityData(id) {
    return this.http.get<City>(
      `${this.endpoint}api/cities/${id}/`,

    )
  }
  getCitySearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<City>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/cities?page=${page}&search=${result.search}`
    );
  }
}
