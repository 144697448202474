import { Component, OnInit } from '@angular/core';
//import * as echarts from 'echarts';
import { ChartService } from 'src/app/service/charts.service';

@Component({
  selector: 'app-ticket-unassigned-open-count',
  templateUrl: './ticket-unassigned-open-count.component.html',
  styleUrls: ['./ticket-unassigned-open-count.component.scss'],
})
export class TicketUnassignedOpenCountComponent implements OnInit {
  new_tick: any;
  unass: any;
  data: any;
  A_key: any;
  A_val: any;
  options: any;

  constructor(private chartService: ChartService) {}

  ngOnInit() {
    this.getticketassign();
  }

  getticketassign() {
    // var chartDom = document.getElementById('tick');
    // var myChart = echarts.init(chartDom);
    // var option;

    this.chartService.getticketAssignedunassignData().subscribe((res) => {
      this.data = res;

      this.data['New Tickets'] = this.data['pending_tickets'];
      delete this.data['pending_tickets'];

      delete this.data['assigned_tickets'];
      delete this.data['in_progress_tickets'];
      delete this.data['completed_tickets'];
      delete this.data['cancelled_tickets'];
      delete this.data['reopened_tickets'];
      delete this.data['closed_tickets'];
      delete this.data['approved_tickets'];

      this.data = Object.keys(this.data).map((key) => ({
        value: this.data[key],
        name: key,
      }));
      // console.log(this.data, 'ccc');

      //     this.A_key = (Object.keys(this.data));
      //     this.A_val = (Object.values(this.data));

      //     console.log(this.A_key,'kkk');
      //     console.log(this.A_val,'vvv');
      this.options = {
       // title: { text: 'New Ticket', subtext: '', left: 'center' },
        tooltip: { trigger: 'item' },
        legend: { top: '6%', left: 'left' },
        series: [
          {
           // name: 'Count',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              color: '#fc8452',
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 1,
            },
            label: { show: false, position: 'center' },
            emphasis: {
              label: { show: true, fontSize: 40, fontWeight: 'bold' },
            },
            labelLine: { show: false },
            data: this.data,
          },
        ],
      };

      //option && myChart.setOption(option);
    });
  }
}

// option = {
//   title: {
//     text: 'new_ticket and unassign_ticket',
//     subtext: '',
//     left: 'center'
//   },
//   tooltip: {
//     trigger: 'axis',
//     axisPointer: {
//       type: 'shadow'
//     }
//   },
//   grid: {
//     left: '3%',
//     right: '4%',
//     bottom: '3%',
//     containLabel: true
//   },
//   xAxis: [
//     {
//       type: 'category',
//       data: ['new_tickets','unassigned'],
//       axisTick: {
//         alignWithLabel: true
//       }
//     }
//   ],
//   yAxis: [
//     {
//       type: 'value'
//     }
//   ],
//   series: [
//     {
//       name: 'Direct',
//       type: 'bar',
//       barWidth: '60%',
//       data: this.A_val
//     }
//   ]
// };

// option && myChart.setOption(option);

// });
