import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  errormsg: any;
  Gender: any;
  firstname: any;
  lastname: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: string;
  menuList = [];
  selectedItem: any;
  subMenuList = [];
  username: any;
  userFullName: any;
  adminMenuList = [];
  showNotifications: boolean;
  isDesktop: boolean = false;
  permissions: any;
  LoggedUserPermission: any;
  role_id: any;
  permission: any;

  constructor(
    private router: Router,
    private screensizeService: ScreensizeService,
    private maintenanceService: MaintenanceService,
    private loginService: LoginService, private toastPopupService: ToastPopupService
  ) { }

  ngOnInit() {
    this.isDesktop = this.screensizeService.getIsDesktop();
    this.username = this.loginService.getUsername(); //get loginUser Role
    this.userFullName = this.loginService.getFullName(); //get loginUser Role
    this.firstname = this.loginService.getFirstName(); //get loginUser Role
    this.lastname = this.loginService.getLastName(); //get loginUser Role

    this.showNotifications = false;
    this.getPermissions();
  }

  getPermissions() {
    this.permissions = this.loginService.currentUserPermissions();
    // console.log("hiiii",this.LoggedUserPermission);
    this.setMenu();
  }

  showNoti() {
    this.showNotifications = true;
  }
  closeNoti() {
    this.showNotifications = false;
  }
  setMenu() {
    this.subMenuList = [
      {
        id: 1,
        name: 'MyProfile',
        value: 'myProfile',
        page: '/home/profile',
        icon: 'fa fa-user-circle',
      },
      {
        id: 2,
        name: 'Password',
        value: 'ChangePassword',
        page: '/home/cpassword',
        icon: 'fa fa-key',
      },
    ];

    this.menuList = [
      {
        id: 1,
        name: 'Dashboard',
        value: 'home',
        page: '/home/dashboard',
        icon: 'fa fa-home',
      },
    ];

    if (this.permissions.RCP) {
      {
        this.adminMenuList.push({
          id: 5,
          name: 'Roster',
          value: 'roaster',
          page: '/home/roaster',
          icon: 'fa fa-users',
        });
      }
    }

    if (this.permissions.CCA) {
      {
        this.adminMenuList.push({
          id: 12,
          name: 'Assets',
          value: 'Assets',
          page: '/home/assets',
          icon: 'fa fa-area-chart',
        });
      }
    }

    if (this.permissions.CCN) {
      {
        this.adminMenuList.push({
          id: 11,
          name: 'Notifications',
          value: 'Auto-assign',
          page: '/home/autoassign',
          icon: 'fa fa-bell',
        });
      }
    }

    if (this.permissions.ACP) {
      {
        this.adminMenuList.push({
          id: 6,
          name: 'Activity',
          value: 'acticity',
          page: '/home/activity',
          icon: 'fa fa-tasks',
        });
      }
    }

    if (this.permissions.CGR) {
      {
        this.adminMenuList.push({
          id: 12,
          name: 'Reports',
          value: 'report-generate',
          page: '/home/report-generate',
          icon: 'fa fa-line-chart',
        });
      }
    }
    if (this.permissions.UVP) {
      this.adminMenuList.push({
        id: 3,
        name: 'Users',
        value: 'users',
        page: '/home/users',
        icon: 'fa fa-user',
      });
    }

    if (this.username == 'admin') {
      this.adminMenuList = [];        //if logged user is admin
    }

  }

  /*Func to call logout api and redirect to login page*/
  logout() {
    this.loginService.logout('').subscribe(
      (res: any) => {
        // console.log(res);
        localStorage.clear(); //clear the headers
        window.location.href = '/';
        // this.router.navigate(['/']); //redirect to login page
      },
      (error) => {
        localStorage.clear(); //clear the headers
        // window.location.reload();
        // this.router.navigate(['/']);
        window.location.href = '/';
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Successfully Log out');
      }
    );
  }

  /*depending on menuItem open the page*/
  menuItem(value) {
    this.router.navigate([value.page]);
    this.selectedItem = value;
  }

  ngOnDestroy() {
    this.subMenuList = [];
  }
}
