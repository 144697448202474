/* All widgets are availble at this module */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MaterialExampleModule } from 'src/material.module';

@NgModule({
  declarations: [StarRatingComponent],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MaterialExampleModule
  ],
  exports: [
    StarRatingComponent
  ]
})
export class WidgetsModule { }
