// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // BASE_URL: 'https://demo1.wegren.com/',
  // IMAGE_URL: 'https://demo1.wegren.com/'

  // BASE_URL:'http://endpoint.helpdesk.api.nuhospitals.com/',
  // IMAGE_URL:'http://endpoint.helpdesk.api.nuhospitals.com/'

  //testing
  // BASE_URL: 'http://localhost:8000/',
  // IMAGE_URL: 'http://localhost:8000/'
  BASE_URL: 'http://192.168.143.237:8002/',
  IMAGE_URL: 'http://192.168.143.237:8002/'
  // BASE_URL:'http://10.2.33.135:8002/',
  // IMAGE_URL:'http://10.2.33.135:8002/'

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
