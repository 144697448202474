// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.approval_permission ion-label {
  position: relative;
  bottom: 2px;
  text-transform: capitalize;
  padding-left: 10px;
}

.details_user {
  background: #c3c5c5;
  padding: 5px 20px 5px 3px;
  width: -moz-fit-content;
  width: fit-content;
}

.approval_permission ion-checkbox {
  margin-top: 6px;
}

ion-checkbox.checkbox-disabled {
  opacity: 1;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbS1yb2xlLWxpc3QuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQkFBQTtFQUNBLFdBQUE7RUFDQSwwQkFBQTtFQUNBLGtCQUFBO0FBQ0Y7O0FBQ0E7RUFDRSxtQkFBQTtFQUNBLHlCQUFBO0VBQ0EsdUJBQUE7RUFBQSxrQkFBQTtBQUVGOztBQUFBO0VBQ0UsZUFBQTtBQUdGOztBQUFBO0VBQ0UsVUFBQTtBQUdGIiwiZmlsZSI6ImN1c3RvbS1yb2xlLWxpc3QuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuYXBwcm92YWxfcGVybWlzc2lvbiBpb24tbGFiZWwge1xyXG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcclxuICBib3R0b206IDJweDtcclxuICB0ZXh0LXRyYW5zZm9ybTogY2FwaXRhbGl6ZTtcclxuICBwYWRkaW5nLWxlZnQ6IDEwcHg7XHJcbn1cclxuLmRldGFpbHNfdXNlciB7XHJcbiAgYmFja2dyb3VuZDogI2MzYzVjNTtcclxuICBwYWRkaW5nOiA1cHggMjBweCA1cHggM3B4O1xyXG4gIHdpZHRoOiBmaXQtY29udGVudDtcclxufVxyXG4uYXBwcm92YWxfcGVybWlzc2lvbiBpb24tY2hlY2tib3gge1xyXG4gIG1hcmdpbi10b3A6IDZweDtcclxufVxyXG5cclxuaW9uLWNoZWNrYm94LmNoZWNrYm94LWRpc2FibGVkIHtcclxuICBvcGFjaXR5OiAxO1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/custom-role/custom-role-list/custom-role-list.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,0BAAA;EACA,kBAAA;AACF;;AACA;EACE,mBAAA;EACA,yBAAA;EACA,uBAAA;EAAA,kBAAA;AAEF;;AAAA;EACE,eAAA;AAGF;;AAAA;EACE,UAAA;AAGF;AAEA,w7BAAw7B","sourcesContent":[".approval_permission ion-label {\r\n  position: relative;\r\n  bottom: 2px;\r\n  text-transform: capitalize;\r\n  padding-left: 10px;\r\n}\r\n.details_user {\r\n  background: #c3c5c5;\r\n  padding: 5px 20px 5px 3px;\r\n  width: fit-content;\r\n}\r\n.approval_permission ion-checkbox {\r\n  margin-top: 6px;\r\n}\r\n\r\nion-checkbox.checkbox-disabled {\r\n  opacity: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
