import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Department } from '../interface/Department';
import { Groups } from '../interface/Groups';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { TechnicianUser } from '../interface/TechnicianUser';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '../interface/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getGroups(page = 1): Observable<Groups> {
    return this.http.get<Groups>(
      `${this.endpoint}group/api/?page=${page}`,
    );
  }

  addGroup(postData) {
    return this.http.post<Groups>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}group/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      //  this.getGroups();
    );
  }


  getusers(page = 1): Observable<User> {
    return this.http.get<User>(
      `${this.endpoint}user/api/?page=${page}`,
    );
  }
  getSearchUsers(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<User>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}user/api?page=${page}&search=${result.search}`
    );
  }

  deleteUser(id) {
    return this.http.delete<User>(
      `${this.endpoint}user/api/${id}/`
    );
  }

  /*api for getting role*/

  /* getTechnicians(){
    return this.http.get<TechnicianUser>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
     // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/technician/`      
      );
  }*/

  /*api for getting role*/

  getTechnicians(page = 1) {
    return this.http.get<TechnicianUser>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/technician/?page=${page}`
    );
  }


  addUser(postData) {
    return this.http.post<User>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}user/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    );
  }


  updateUser(id, postData) {
    return this.http.put<User>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}user/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  updatePassword(postData) {
    return this.http.put<User>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}api/change/password/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }

  getUserData(id) {
    return this.http.get<User>(
      `${this.endpoint}user/api/${id}/`,
    )
  }

  resetUserPassword(data,userId) {
    return this.http.put<User>(
      `${this.endpoint}api/admin/change-password/${userId}/`,data
    );
  }

}
