import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
//import { environment } from '../../environments/environment';
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class NotificationService {
  apiUrl: string;
 
 constructor(
    private toastr: ToastrService,
    private http: HttpClient
    ) {
      this.apiUrl = environment.BASE_URL;
     }

  showSuccess(message: string, title: string) {
   this.toastr.success(message, title);
  }

  showError(message: string, title: string) {
    this.toastr.error(message, title);
  }

  showInfo(message: string, title: string) {
    this.toastr.info(message, title);
  }

  showWarning(message: string, title: string) {
    this.toastr.warning(message, title);
  }

  subscribe(data) {
    //post requests or any mutating requests wont be cached
    return this.http.post(this.apiUrl + 'push/subscribe/', data);
  }

  triggerMessage(data) {
    return this.http.post(this.apiUrl + 'send_push/', data);
  }

}
