import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Roaster } from '../interface/Roaster';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class RoasterService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getRoasters(page = 1): Observable<Roaster> {
    return this.http.get<Roaster>(
      `${this.endpoint}api/roster/?page=${page}`,
    );
  }

  createRoaster(postData): Observable<Roaster> {
    return this.http.post<Roaster>(
      `${this.endpoint}api/roster/create/many/`,
      postData
    )
  }

  updateRoaster(id, postData) {
    return this.http.put<Roaster>(
      `${this.endpoint}api/roster/${id}/`,
      postData
    )
  }

  getRoasterData(id) {
    return this.http.get<Roaster>(
      `${this.endpoint}api/roster/${id}/`,

    )
  }
  getRoasterSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Roaster>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/roster/?page=${page}&search=${result.search}`
    );
  }

}
