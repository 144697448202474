import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Category } from '../interface/Category';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getCategories(page = 1): Observable<Category> {

    return this.http.get<Category>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}category/subcategory/api/?page=${page}`,
      //this.endpoint +'auth/',

    );
  }

  getCategory(page = 1, department_id = ''): Observable<Category> {
    if (department_id == null) {
      department_id = '';
    }
    return this.http.get<Category>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}category/api/?page=${page}&department=${department_id}`,
      //this.endpoint +'auth/',

    );
  }
  getCategorySearch(page = 1, result, department_id = '') {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Category>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}category/subcategory/api/?page=${page}&search=${result.search}&department=${department_id}`
    );
  }

  getCategoryDetail(id: any) {

    return this.http.get<any>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}category/subcategory/api/${id}`,
      //this.endpoint +'auth/',

    );

  }
  getSubCategoryDetail(id: any) {

    return this.http.get<any>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}category/subcategory/api/${id}`,
      //this.endpoint +'auth/',

    );

  }


  readSubCategoryDetail(id: any) {

    return this.http.get<any>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}subcategory/api/${id}`,
      //this.endpoint +'auth/',

    );

  }


  addCategory(postData) {
    return this.http.post<Category>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}category/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }
  addSubCategory(postData) {
    return this.http.post<Category>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}subcategory/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  updateCategory(id, postData) {
    return this.http.put<Category>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}category/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  updateSubCategory(id, postData) {
    return this.http.put<Category>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}subcategory/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }



  getSubCategories(page = 1, category_id = '', case_type = ''): Observable<Category> {
    if (case_type == null) {
      case_type = '';
    }
    return this.http.get<Category>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}subcategory/api/?page=${page}&category=${category_id}&sub_category_type=${case_type}`,
      //this.endpoint +'auth/',

    );

  }

}