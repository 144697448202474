import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { InventoryService } from 'src/app/service/inventory.service';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
})
export class InventoryListComponent implements OnInit {

  /*inialize varaibale for technician dropdown */
  selectedUser: any;
  technicians = [];
  error: any;
  count: any;
  page: number = 1;
  Inventory: any = [];
  refresh: boolean = false;

  /*intalise form */
  searchForm = new FormGroup({
    search: new FormControl('',),
  })

  filteruserActivityForm = new FormGroup({
    user: new FormControl('',),
    created_at: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;

  constructor(
    private inventoryService: InventoryService,
    //  @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.getInventoryList();
  }

  /*func to get ticket list */
  getInventoryList() {
    this.inventoryService.getSearchInventory(this.page, this.searchForm.value).subscribe((res: any) => {
      this.Inventory = res;
    });
  }

  onSearchSubmit() {
    this.inventoryService.getSearchInventory(this.page, this.searchForm.value).subscribe((res: any) => {
      this.Inventory = res;
    });
  }


  refreshList() {
    this.inventoryService.getSearchInventory(this.page, this.searchForm.value).subscribe((res: any) => {
      this.Inventory = res;
    });
  }


}






