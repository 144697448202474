import { Component, Input, OnInit } from '@angular/core';
import { MaintenanceService } from '../../../../service/maintenance.service'; //
import { MatDialog } from '@angular/material/dialog';
import { TicketAssignComponent } from '../ticket-assign/ticket-assign.component';
import { TicketcreateComponent } from '../ticketcreate/ticketcreate.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TicketViewComponent } from '../ticket-view/ticket-view.component';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { TicketChangeDepartmentComponent } from '../ticket-change-department/ticket-change-department.component';
import { TicketNotesComponent } from '../ticket-notes/ticket-notes.component';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { Status, ticketStatus } from 'src/app/constant/status';
import { OtpDialogComponent } from '../../Dialogs/otp-dialog/otp-dialog.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-ticketlist',
  templateUrl: './ticketlist.component.html',
  styleUrls: ['./ticketlist.component.scss'],
})
export class TicketlistComponent implements OnInit {
  @Input() ticketStatus = '';
  @Input() working = '';
  @Input() requestedForApproval = '';
  @Input() grabTheTicket = '';
  tickets: any = [];
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  count: any;
  page: number = 1;
  results_per_page: any;
  refresh: boolean = false;
  saveCurrentPageNumber: number;
  isDesktop: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  subscription: Subscription;
  filterData: any;
  filter: boolean;
  permissions: any;
  selectedCreatedBy: any;
  statusConst = Status;

  /*  ticketTypes */
  selectedTicketStatus: any;
  ticket_status = ticketStatus;

  /* priorties */
  selectedPriority: string;
  priorties = [
    { id: 1, name: 'Low' },
    { id: 2, name: 'Medium' },
    { id: 3, name: 'High' },
    { id: 4, name: 'Critical' },
  ];

  /*intalise form */
  searchTicketForm = new FormGroup({
    search: new FormControl(''),
  });

  /*intalise form */
  filterTicketForm = new FormGroup({
    ticket_status: new FormControl(''),
    created_by: new FormControl(''),
  });
  search: boolean = false;
  maintenance_departments = [];
  mainteneceDepartmentCount: any;
  maintenecepage: number = 1;
  username: any;
  errormsg: any;
  departments: any;
  usersList = [];
  technicianspage: number = 1;
  technicians = [];
  badgeCount = 0;

  constructor(
    private loginService: LoginService,
    private maintenanceService: MaintenanceService,
    public dialog: MatDialog,
    private screensizeService: ScreensizeService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private userService: UserService
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    // console.log("desktop", this.isDesktop)
  }

  ngOnInit() {
    this.username = this.loginService.getUsername();
    this.getMaintenanceDepartmentsList();
    this.isDesktop = this.screensizeService.getIsDesktop();
    this.image_url = environment.IMAGE_URL;
    this.getpermissions();
    this.getUserList();
  }

  getpermissions() {
    this.permissions = this.loginService.currentUserPermissions();
    this.getticketList();
  }

  deleteTicket(id) {
    this.maintenanceService.deleteticket(id).subscribe(
      (res: any) => {
        this.refreshTickets();
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to discard ticket');
      }
    );
  }

  /*func to get ticket list */
  getticketList() {
    // this.permissions = this.loginService.getUserPermissions();
    // console.log(this.page);
    if (this.refresh == true) {
      this.tickets = [];
      this.page = 1;
      this.saveCurrentPageNumber = 1;
      this.refresh = false;
    }
    {
      // if(this.search)
      {
        this.maintenanceService
          .getSearchTickets(
            this.page,
            this.searchTicketForm.value,
            this.ticketStatus
          )
          .subscribe(
            (res: any) => {
              //console.log(res);
              this.results_per_page = res.results_per_page;
              //const tickets1 = res.results;
              this.count = res.count;
              this.tickets = [
                ...new Map(
                  this.tickets
                    .concat(res.results)
                    .map((item) => [item['id'], item])
                ).values(),
              ];
              this.getWorkingTickets();
              this.getRequestedForApprovalTickets();
              this.getUnassignedTickets();
            },
            (error) => {
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to get ticket');
            }
          );
      }
    }
  }

  /*func to get ticket list */
  getSearchticketList() {
    // this.permissions = this.loginService.getUserPermissions();
    // console.log(this.page);
    if (this.refresh == true) {
      this.tickets = [];
      this.page = 1;
      this.saveCurrentPageNumber = 1;
      this.refresh = false;
    }
    {
      {
        this.maintenanceService
          .getSearchTickets(
            this.page,
            this.searchTicketForm.value,
            this.ticketStatus
          )
          .subscribe(
            (res: any) => {
              //console.log(res);
              this.results_per_page = res.results_per_page;
              //const tickets1 = res.results;
              this.count = res.count;
              this.tickets = [
                ...new Map(
                  this.tickets
                    .concat(res.results)
                    .map((item) => [item['id'], item])
                ).values(),
              ];
              this.getWorkingTickets();
              this.getRequestedForApprovalTickets();
              this.getUnassignedTickets();
            },
            (error) => {
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to get ticket');
            }
          );
      }
    }
  }

  /*func to get ticket list */
  getFilterticketList() {
    // this.permissions = this.loginService.getUserPermissions();
    // console.log(this.page);
    if (this.filterTicketForm.value.ticket_status != '' && this.badgeCount < 2)
      this.badgeCount++;
    if (this.filterTicketForm.value.created_by != '' && this.badgeCount < 2)
      this.badgeCount++;

    if (this.refresh == true) {
      this.tickets = [];
      this.page = 1;
      this.saveCurrentPageNumber = 1;
      this.refresh = false;
    }
    {
      {
        this.maintenanceService
          .getFilterTickets(this.page, this.filterTicketForm.value)
          .subscribe(
            (res: any) => {
              //console.log(res);
              this.results_per_page = res.results_per_page;
              //const tickets1 = res.results;
              this.count = res.count;
              this.tickets = [
                ...new Map(
                  this.tickets
                    .concat(res.results)
                    .map((item) => [item['id'], item])
                ).values(),
              ];
              this.getWorkingTickets();
              this.getRequestedForApprovalTickets();
              this.getUnassignedTickets();
            },
            (error) => {
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to get ticket');
            }
          );
      }
    }
  }

  getWorkingTickets() {
    // console.log("priya");
    if (this.working == 'yes') {
      this.tickets = this.tickets.filter((x: any) => {
        return x.assigned_to_me == true;
      });
      this.count = this.tickets.length;
      // console.log("priya", this.tickets);
    }
  }

  getRequestedForApprovalTickets() {
    // console.log("priya",1);
    if (this.requestedForApproval == 'yes') {
      // console.log("priya",1);
      this.tickets = this.tickets.filter((x: any) => {
        return x.require_additional_permission == true;
      });
      //  this.tickets = this.tickets.filter((x: any) => { return x.is_approved == false });
      this.count = this.tickets.length;
      // console.log("priya", this.tickets);
    }
  }

  getUnassignedTickets() {
    // console.log("priya",1);
    if (this.grabTheTicket == 'yes') {
      // console.log("priya",1);
      this.tickets = this.tickets.filter((x: any) => {
        return x.is_assigned == false;
      });
      this.tickets = this.tickets.filter((x: any) => {
        return x.require_additional_permission == false;
      });
      this.count = this.tickets.length;
      // console.log("priya", this.tickets);
    }
  }

  loadData(event) {
    // console.log(this.page);
    setTimeout(() => {
      // console.log('Done');
      event.target.complete();

      if (this.tickets.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      } else {
        this.page++;
        this.getticketList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  /**
   * infinite scroll function
   */
  onScroll() {
    if (this.count != this.tickets.length) {
      this.page++;

      this.getticketList();
    }
  }

  onScrollUp() {
    // console.log(this.page);
  }

  /*func to merge ticket */
  merge(id, sno) {
    //this.saveCurrentPageNumber=this.page;
    //this.page=Math.floor((sno+1)/(this.results_per_page))+1;
    // console.log(this.page);
    const payload = {
      is_merged: true,
    };
    this.maintenanceService.mergeTicket(id, payload).subscribe(
      (res) => {
        // console.log(res);
        //  this.page--;
        this.refresh = true;
        this.getticketList();
        // this.page=this.saveCurrentPageNumber;
        //this.tickets = res;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to merge ticket');
      }
    );
  }

  /*func to approve ticket */
  approve(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    const payload = {
      require_additional_permission: false,
    };
    this.maintenanceService.requestApproveTicket(id, payload).subscribe(
      (res) => {
        // console.log(res);
        //  this.page--;
        this.refresh = true;
        this.getticketList();
        this.page = this.saveCurrentPageNumber;
        //this.tickets = res;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to approve ticket');
      }
    );
  }

  /*func to approve ticket */
  editrequest(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    const payload = {
      edit_requested: true,
    };
    this.maintenanceService.requestEditTicket(id, payload).subscribe(
      (res) => {
        // console.log(res);
        //  this.page--;
        this.refresh = true;
        this.getticketList();
        this.page = this.saveCurrentPageNumber;
        //this.tickets = res;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to request');
      }
    );
  }

  /*func to approve ticket */
  deptRequest(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    const payload = {
      dept_change_requested: true,
    };
    this.maintenanceService
      .requestDepartmentChangeTicket(id, payload)
      .subscribe(
        (res) => {
          // console.log(res);
          //  this.page--;
          this.refresh = true;
          this.getticketList();
          this.page = this.saveCurrentPageNumber;
          //this.tickets = res;
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to request');
        }
      );
  }

  /*func to approve ticket */
  cancelDeptRequest(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    const payload = {
      dept_change_requested: false,
    };
    this.maintenanceService
      .requestDepartmentChangeTicket(id, payload)
      .subscribe(
        (res) => {
          this.refresh = true;
          this.getticketList();
          this.page = this.saveCurrentPageNumber;
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to request');
        }
      );
  }

  /*func to approve ticket */
  approvalrequest(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    const payload = {
      require_additional_permission: true,
    };
    this.maintenanceService.requestApproveTicket(id, payload).subscribe(
      (res) => {
        // console.log(res);
        //  this.page--;
        this.refresh = true;
        this.getticketList();
        this.page = this.saveCurrentPageNumber;
        //this.tickets = res;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to request');
      }
    );
  }

  /*func to open modal assign-task, with task details*/
  forward(res, sno, case_type) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    this.dialogRef = this.dialog.open(TicketChangeDepartmentComponent, {
      data: {
        id: res.id,
        res: res,
        department: res.department,
        case_type: case_type,
        //main_department:main_department
      },
      width: '80%',
    });
    this.reloadList();
  }

  /* Func to get list of departments */
  getMaintenanceDepartmentsList() {
    {
      this.departmentService.getMainDepartments(this.maintenecepage).subscribe(
        (res: any) => {
          this.mainteneceDepartmentCount = res.count;
          // console.log(this.mainteneceDepartmentCount)
          // this.maintenance_departments = res.results;
          this.maintenance_departments = [
            ...new Map(
              this.maintenance_departments
                .concat(res.results)
                .map((item) => [item['id'], item])
            ).values(),
          ];
          this.onScrollDepartmentList();
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get departments list');
        }
      );
    }
  }

  onScrollDepartmentList() {
    if (this.mainteneceDepartmentCount != this.maintenance_departments.length) {
      this.maintenecepage++;
      this.getMaintenanceDepartmentsList();
    }
  }

  formateDate(date) {
    let date1 = date.split(' ')[0];
    let date2 = date1.split('-').reverse().join('-');
    let date3: Date = new Date(
      date2.split(' ')[0] + 'T' + date.split(' ')[1] + 'Z'
    );
    return date3;
  }

  /*func to open modal ticket-assign */
  assign(
    ticket_id,
    ticket_number,
    sno,
    department,
    category,
    is_assigned,
    assigType
  ) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You want to Grab this ticket.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {
        this.saveCurrentPageNumber = this.page;
        this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
        const payload = {
          id: ticket_id,
          ticket: ticket_number,
          department: department,
          category: category,
          is_assigned: is_assigned,
          assigType: assigType,
          technician: this.loginService.getUserId(),
        };
        this.updateTask(ticket_id, payload);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  updateTask(ticket_id, payload) {
    {
      this.maintenanceService.updateAssignTicket(ticket_id, payload).subscribe(
        (data) => {
          this.refresh = true;
          this.getticketList();
          this.toastPopupService.openToast('Ticket Assigned Successfully');
        },
        (error) => {}
      );
    }
  }

  /*func to open ticket view modal*/
  viewTicket(ticketData) {
    this.dialog.open(TicketViewComponent, {
      data: {
        ticketData: ticketData,
        myticket: 0,
      },
      width: '80%',
    });
    // this.reloadList();
  }

  /*func to open modal ticket-assign */
  addNotesTicket(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    this.dialogRef = this.dialog.open(TicketNotesComponent, {
      data: {
        ticket_id: id,
        name: 'Technician Notes',
      },
      width: '80%',
    });
    this.reloadList(); //reload ticket list if modal closed
    // this.page=saveCurrentPageNumber;
  }

  /*func to open create ticket modal */
  addTicketDialog() {
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      width: '80%',
    });
    // this.tickets=[];

    this.reloadList();
  }

  /*func to open create ticket modal */
  filterTicketDialog() {
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      width: '80%',
      data: {
        filter: true,
        filterData: this.filterData,
      },
    });
    // this.tickets=[];

    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`, result.department);
      // console.log('The dialog was closed');
      if (result == 'norefresh') {
      } else {
        // console.log(result);
        this.search = false;

        this.searchTicketForm.patchValue({
          search: '',
        });
        this.filter = true;
        this.refresh = true;
        this.filterData = result;
        this.getticketList();
      }
    });
  }

  /*func to reload ticketList */
  reloadList() {
    // console.log("at reload page", this.page, this.saveCurrentPageNumber)
    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getticketList();
      } else if (result == 'norefresh') {
      } /*else if (result == 'refreshCurrentScreen') {
        this.getticketList();
      }*/ else {
        this.refresh = true;
        this.getticketList();
      }
      this.page = this.saveCurrentPageNumber;
    });
  }

  /*func to update ticket status*/
  ticketStatusUpdate(id, sno, statusValue) {
    const saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);

    const payload = {
      status_code: statusValue,
    };
    this.maintenanceService.updateTicketStatus(id, payload).subscribe(
      (res) => {
        // console.log(res);
        this.getticketList();
        this.page = saveCurrentPageNumber;
        //this.tickets = res;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to update ticket status');
      }
    );
  }

  closeTicket(id, sno, statusValue) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    this.dialogRef = this.dialog.open(OtpDialogComponent, {
      data: { ticketId: id, statusCode: statusValue },
      width: '50%',
    });
    this.reloadList();
  }

  ticketReOpen(id, sno) {
    this.refresh = true;
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    const payload = {
      is_reopened: true,
    };
    this.maintenanceService.reOpenTicket(id, payload).subscribe(
      (res) => {
        this.getticketList();
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to reopen ticket');
      }
    );
  }

  /*func to open modal ticket-assign */
  updateUserTicket(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      data: {
        ticket_id: id,
      },
      width: '80%',
    });
    this.reloadList(); //reload ticket list if modal closed
    // this.page=saveCurrentPageNumber;
  }

  viewUserTicket(ticketData) {
    this.dialog.open(TicketViewComponent, {
      data: {
        ticketData: ticketData,
      },
      width: '80%',
    });
  }

  onSearchSubmit() {
    this.badgeCount = 0;
    this.filter = false;
    this.search = true;
    this.refresh = true;
    this.getSearchticketList();
  }

  onFilterSubmit() {
    this.filter = true;
    this.search = false;
    this.refresh = true;
    this.getFilterticketList();
  }

  refreshTickets() {
    this.badgeCount = 0;
    this.filter = false;
    this.search = false;
    this.refresh = true;
    this.searchTicketForm.patchValue({
      search: '',
    });
    this.filterTicketForm.patchValue({
      ticket_status: '',
      created_by: '',
    });

    this.getticketList();
  }

  // addTicketService(id, res) {
  //   console.log(this.page);
  //   this.dialogRef = this.dialog.open(TaskUpdateComponent, {
  //     data: {
  //       id: id,
  //       ticketData: res
  //     },
  //     width: '80%',
  //   });
  //   this.reloadList();
  // }

  // addTicketServiceByTechnician() {
  //   console.log(this.page);
  //   this.dialogRef = this.dialog.open(TaskUpdateComponent, {
  //     width: '80%',
  //   });
  //   this.reloadList();
  // }

  /* Func to get list of departments */
  getDepartmentsList() {
    this.departmentService.getDepartments().subscribe(
      (res: any) => {
        this.departments = res;
        //  this.getEquipmentsList();
        //  this.getEquipmentsList();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get Department list');
      }
    );
  }

  /* Func to get list of activity */
  getUserList() {
    this.userService.getusers(this.technicianspage).subscribe(
      (res: any) => {
        // this.technicians = res.results;
        this.technicians = [
          ...new Map(
            this.technicians
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = res.count;
        // this.ongetUserListScroll();
      },
      (error) => {
        // console.log(error);
        // this.error = error.error;
        this.toastPopupService.openToast('Unable to get Users List');
      }
    );
  }

  ongetUserListScroll() {
    //console.log("hello")
    if (this.count != this.technicians.length) {
      this.technicianspage++;
      this.getUserList();
    }
  }
  // getStatus(name) {
  //   for(let key in this.statusConst){
  //     if(this.statusConst[key]['status'] == name) {
  //       return this.statusConst[key];
  //     }
  //   }
  //   return {status:"",color:""};
  // }
}
