import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-type-create',
  templateUrl: './field-type-create.component.html',
  styleUrls: ['./field-type-create.component.scss'],
})
export class FieldTypeCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
