import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticket-layout',
  templateUrl: './ticket-layout.component.html',
  styleUrls: ['./ticket-layout.component.scss'],
})
export class TicketLayoutComponent implements OnInit {
  assigned_to_me: string;
  ticket_status: string;
  display: any;
  requested_for_approval: string;

  constructor() { }

  ngOnInit() {
    this.assigned_to_me = '';
    this.ticket_status = '';
    this.display = 1;
  }
  change(e) {
    if (e == 'all') {
      this.display = 1;

    } else if (e == 'unapproved') {
      this.display = 2;

    } else if (e == 'unassigned') {
      this.display = 3;

    } else if (e == 'assignedtome') {
      this.display = 4;

    }
  }
}
