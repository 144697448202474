import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  Gender: any;
  firstname: any;
  lastname: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: string;
  menuList = [];
  selectedItem: any;
  subMenuList = [];
  username: any;
  userFullName: any;
  adminMenuList = [];
  showNotifications: boolean;
  isDesktop: boolean = false;
  permissions: any;
  LoggedUserPermission: any;
  role_id: any;
  permission: any;

  constructor(
    private router: Router,
    private screensizeService: ScreensizeService,
    private maintenanceService: MaintenanceService,
    private loginService: LoginService
  ) {

  }

  ngOnInit() {

    this.isDesktop = this.screensizeService.getIsDesktop();
    this.username = this.loginService.getUsername(); //get loginUser Role
    this.userFullName = this.loginService.getFullName(); //get loginUser Role
    this.firstname = this.loginService.getFirstName(); //get loginUser Role
    this.lastname = this.loginService.getLastName(); //get loginUser Role

    this.showNotifications = false;
    this.getPermissions()
  }

  getPermissions() {
    this.permissions = this.loginService.currentUserPermissions();
    // console.log("hiiii",this.LoggedUserPermission);
    this.setMenu();
  }


  setMenu() {


    this.menuList = [
      {
        id: 1,
        name: 'Dashboard',
        value: 'home',
        page: '/home/dashboard',
        icon: 'fa fa-home',
      },

    ];

    this.selectedItem = this.menuList[0];

    if (this.permissions.TVP) {
      this.menuList.push({
        id: 3,
        name: 'Tickets',
        value: 'tickets',
        page: '/home/ticket',
        icon: 'fa fa-ticket',
      });
    }



    if (this.permissions.TRP) {

      this.menuList.push({

        id: 4,
        name: 'MyTickets',
        value: 'mytickets',
        page: '/home/myticket',
        icon: 'fa fa-info',

      });
      this.menuList.push({
        id: 2,
        name: 'Create Ticket',
        value: 'ticketcreate',
        page: '/home/ticketcreate',
        icon: 'fa fa-plus',
      });
    }



    if (this.permissions.RCP) {
      {
        this.adminMenuList.push(
          {
            id: 5,
            name: 'Roster',
            value: 'roaster',
            page: '/home/roaster',
            icon: 'fa fa-users',
          },

        );
      }
    }


    if (this.permissions.CCA) {
      {
        this.adminMenuList.push(
          {
            id: 12,
            name: 'Assets',
            value: 'Assets',
            page: '/home/assets',
            icon: 'fa fa-area-chart',
          },

        );
      }
    }

    if (this.permissions.CCN) {
      {
        this.adminMenuList.push(
          {
            id: 11,
            name: 'Notifications',
            value: 'Auto-assign',
            page: '/home/autoassign',
            icon: 'fa fa-bell',
          },

        );
      }
    }


    if (this.permissions.ACP) {
      {
        this.adminMenuList.push(

          {
            id: 6,
            name: 'Activity',
            value: 'acticity',
            page: '/home/activity',
            icon: 'fa fa-tasks',
          },

        );
      }
    }



    if (this.username == 'admin') {
      this.adminMenuList = [];        //if logged user is admin
    }
  }


  /*depending on menuItem open the page*/
  menuItem(value) {
    this.router.navigate([value.page]);
    this.selectedItem = value;
  }


  showNoti() {
    this.showNotifications = true;
  }
  closeNoti() {
    this.showNotifications = false;
  }

  ngOnDestroy() {
    this.menuList = [];
    this.subMenuList = [];
  }
}
