import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Inventory } from '../interface/inventory';
//import { User } from '../interface/User';

@Injectable({
  providedIn: 'root',
})

export class InventoryService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getInventory(page = 1): Observable<Inventory> {
    return this.http.get<Inventory>(
      `${this.endpoint}inventory/api/`
    );
  }

  getSearchInventory(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Inventory>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}inventory/api/`
    );
  }


}
