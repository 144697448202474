import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../../../../service/maintenance.service';
import { MatDialog } from '@angular/material/dialog';
import { TicketcreateComponent } from '../ticketcreate/ticketcreate.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TicketViewComponent } from '../ticket-view/ticket-view.component';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationsService } from 'src/app/service/notifications.service';
import { TicketNotesComponent } from '../ticket-notes/ticket-notes.component';
import { TicketFeedbackComponent } from '../ticket-feedback/ticket-feedback.component';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { Status } from 'src/app/constant/status';

@Component({
  selector: 'app-my-ticket-list',
  templateUrl: './my-ticket-list.component.html',
  styleUrls: ['./my-ticket-list.component.scss'],
})
export class MyTicketListComponent implements OnInit {
  tickets: any = [];
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  count: any;
  page: number = 1;
  results_per_page: any;
  refresh: boolean = false;
  saveCurrentPageNumber: number;
  isDesktop: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  subscription: Subscription;
  filterData: any;
  filter: boolean;
  permissions: any;
  /*intalise form */
  searchTicketForm = new FormGroup({
    search: new FormControl(''),
  });
  search: boolean = false;
  notifications = [];
  notificationCount: number;
  notificationsPage = 1;
  errormsg: any;
  statusConst = Status;
  constructor(
    private loginService: LoginService,
    private maintenanceService: MaintenanceService,
    public dialog: MatDialog,
    private screensizeService: ScreensizeService,
    private notificationsService: NotificationsService,
    private toastPopupService: ToastPopupService
  ) {
    this.screensizeService.isDesktopView().subscribe((isDesktop) => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
    // console.log("desktop", this.isDesktop)
  }

  ngOnInit() {
    this.isDesktop = this.screensizeService.getIsDesktop();

    this.image_url = environment.IMAGE_URL;
    this.role = this.loginService.getUserRole(); //get loginUser Role
    // this.getNotificationList();
    this.getticketList();
    // this.addticket();
    this.role = this.loginService.getRole(); //get loginUser Role
    this.getpermissions();
    // console.log(this.permissions);
  }

  getpermissions() {
    this.loginService.getUserPermissions(this.role).subscribe(
      (res: any) => {
        this.permissions = res.results;
        this.permissions = this.permissions[0];
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get User Permissions');
      }
    );
  }

  /*func to get ticket list */
  getticketList() {
    //   this.permissions = this.loginService.getUserPermissions();
    // console.log(this.page);
    if (this.refresh == true) {
      this.tickets = [];
      this.page = 1;
      this.saveCurrentPageNumber = 1;
      this.refresh = false;
    }

    {
      if (this.search) {
        this.maintenanceService
          .getMyticketsSearch(this.page, this.searchTicketForm.value)
          .subscribe((res: any) => {
            //console.log(res);
            this.results_per_page = res.results_per_page;
            //const tickets1 = res.results;
            this.count = res.count;
            this.tickets = [
              ...new Map(
                this.tickets
                  .concat(res.results)
                  .map((item) => [item['id'], item])
              ).values(),
            ];
          });
      } else if (this.filter) {
        this.maintenanceService
          .getMyticketsFilter(this.page, this.filterData)
          .subscribe(
            (res: any) => {
              //console.log(res);
              this.results_per_page = res.results_per_page;
              //const tickets1 = res.results;
              this.count = res.count;
              this.tickets = [
                ...new Map(
                  this.tickets
                    .concat(res.results)
                    .map((item) => [item['id'], item])
                ).values(),
              ];
            },
            (error) => {
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to get ticket data');
            }
          );
      } else {
        this.maintenanceService.getMytickets(this.page).subscribe(
          (res: any) => {
            // console.log(res);
            // this.tickets = res.results;
            this.count = res.count;
            this.results_per_page = res.results_per_page;

            this.tickets = [
              ...new Map(
                this.tickets
                  .concat(res.results)
                  .map((item) => [item['id'], item])
              ).values(),
            ];
          },
          (error) => {
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to get ticket data');
          }
        );
      }
    }
  }

  loadData(event) {
    // console.log(this.page);
    setTimeout(() => {
      // console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.tickets.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      } else {
        this.page++;
        this.getticketList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  /**
   * infinite scroll function
   */
  onScroll() {
    if (this.count != this.tickets.length) {
      this.page++;

      this.getticketList();
    }
  }

  /*func to update ticket status*/
  ticketReOpen(id, sno) {
    const saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    const payload = {
      is_reopened: true,
    };
    this.maintenanceService.reOpenTicket(id, payload).subscribe(
      (res) => {
        this.getticketList();
        this.page = saveCurrentPageNumber;
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to reopen ticket');
      }
    );
  }

  /*func to update ticket status*/
  ticketFeedback(id, sno) {
    const saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);

    this.dialog.open(TicketFeedbackComponent, {
      data: {
        ticketId: id,
      },
      width: '80%',
    });
    this.reloadList();
  }

  formateDate(date) {
    // console.log(date);
    let date1 = date.split(' ')[0];
    let date2 = date1.split('-').reverse().join('-');
    let date3: Date = new Date(
      date2.split(' ')[0] + 'T' + date.split(' ')[1] + 'Z'
    );
    // console.log("Date = " + date3);
    return date3;
  }

  /*func to open ticket view modal*/
  viewTicket(ticketData) {
    this.dialog.open(TicketViewComponent, {
      data: {
        ticketData: ticketData,
      },
      width: '80%',
    });
    // this.reloadList();
  }

  /*func to open create ticket modal */
  addTicketDialog() {
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      width: '80%',
    });
    // this.tickets=[];

    this.reloadList();
  }

  /*func to open create ticket modal */
  filterTicketDialog() {
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      width: '80%',
      data: {
        filter: true,
        filterData: this.filterData,
      },
    });
    // this.tickets=[];

    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`, result.department);
      // console.log('The dialog was closed');
      if (result == 'norefresh') {
      } else {
        // console.log(result);
        this.search = false;

        this.searchTicketForm.patchValue({
          search: '',
        });
        this.filter = true;
        this.refresh = true;
        this.filterData = result;
        this.getticketList();
      }
    });
  }

  /*func to reload ticketList */
  reloadList() {
    // console.log("at reload page", this.page, this.saveCurrentPageNumber)
    this.dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getticketList();
      } else if (result == 'norefresh') {
      } else if (result == 'refreshCurrentScreen') {
        this.getticketList();
      } else {
        this.getticketList();
      }
      this.page = this.saveCurrentPageNumber;
    });
  }

  /*func to open modal ticket-assign */
  addNotesTicket(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    this.dialogRef = this.dialog.open(TicketNotesComponent, {
      data: {
        ticket_id: id,
        name: 'Additional Notes',
        myticketNotes: 1,
      },
      width: '80%',
    });
    this.reloadList(); //reload ticket list if modal closed
    // this.page=saveCurrentPageNumber;
  }

  /*func to open modal ticket-assign */
  updateUserTicket(id, sno) {
    this.saveCurrentPageNumber = this.page;
    this.page = Math.floor((sno + 1) / this.results_per_page) + 1;
    // console.log(this.page);
    this.dialogRef = this.dialog.open(TicketcreateComponent, {
      data: {
        ticket_id: id,
      },
      width: '80%',
    });
    this.reloadList(); //reload ticket list if modal closed
    // this.page=saveCurrentPageNumber;
  }

  viewUserTicket(ticketData) {
    this.dialog.open(TicketViewComponent, {
      data: {
        ticketData: ticketData,
        myticket: 1,
      },
      width: '80%',
    });
  }

  onSearchSubmit() {
    this.filter = false;
    this.search = true;
    this.refresh = true;

    this.getticketList();
  }

  refreshTickets() {
    this.filter = false;
    this.search = false;
    this.refresh = true;
    this.searchTicketForm.patchValue({
      search: '',
    });
    this.getticketList();
  }

  /* Func to get list of notifications */
  getNotificationList() {
    this.notificationsService
      .getNotifications(this.notificationsPage)
      .subscribe(
        (res: any) => {
          this.notifications = res.results;
          this.notificationCount = res.count;
          this.notifications = [
            ...new Map(
              this.notifications
                .concat(res.results)
                .map((item) => [item['id'], item])
            ).values(),
          ];
          this.loadMoreNotifications();
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast(
            'Unable to get Notifications Details'
          );
        }
      );
  }
  loadMoreNotifications() {
    // console.log(this.maintenance_departments.length);
    if (this.notificationCount != this.notifications.length) {
      this.notificationsPage++;
      this.getNotificationList();
    }
  }

  closeNotification(id) {
    const payload = {
      is_read: true,
    };
    this.notificationsService.updateNotifications(id, payload).subscribe(
      (res: any) => {
        // this.notifications = res.results;
        // this.notifications=[];
        // this.getNotificationList();
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to update Notifications');
      }
    );
  }
}
