import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { CategoryService } from 'src/app/service/category.service';
import { ChatbotService } from 'src/app/service/chatbot.service';
import { LocationService } from 'src/app/service/location.service';

@Component({
  selector: 'app-ticket-create-chatbot',
  templateUrl: './ticket-create-chatbot.component.html',
  styleUrls: ['./ticket-create-chatbot.component.scss'],
})
export class TicketCreateChatbotComponent implements OnInit, OnDestroy {
  comments: any[] = [];
  @ViewChild('comment') comment: ElementRef;
  scrolltop: number = 0;
  userMessages = [];
  botMessages = [];

  loggedInrole: any;
  display: string;
  departments: any;

  control: FormControl = new FormControl('', Validators.minLength(2));
  no_of_questions: number;
  sno = 0;
  question_id: any;
  List: any;
  field_type: string;
  uploadedImage = null;
  imgerrormsg: string;
  errormsg: string;
  isEditable: boolean;
  answer: any;
  selected_ng: any;
  obj: any;
  image = null;
  question_Type_id: any;
  question_type: any;
  department_answer: any;
  category_answer: any;
  ticket_type_answer: any;
  sub_category_answer: any;
  question_count: any;
  sub_item_answer: any;
  skipQuestionsCount = 0;
  region_answer: any;
  state_answer: any;
  room_answer: any;
  country_answer: any;
  floor_answer: any;
  city_answer: any;
  optionsCount: any;
  // Optionpage: any;
  Optionspage = 1;
  on_behalf_answer: any;
  template_id: any;
  templateList = [];
  selected_template: any;
  hide_Template_dropdown: boolean = false;
  skip_question: any;
  skipAnswerCount: any = 0;
  imageUploadcheck: number = 0;
  loadingImage: number = 0;
  url: string | ArrayBuffer;
  priorty_answer: any;

  constructor(
    private router: Router,
    private maintenanceService: MaintenanceService,
    private loginService: LoginService,
    private equipmentService: EquipmentService,
    private departmentService: DepartmentService,
    private categoryService: CategoryService,
    private chatbotService: ChatbotService,
    private userService: UserService,
    private toastPopupService: ToastPopupService,
    private locationService: LocationService,
    private ref: ChangeDetectorRef
  ) { }


  /* main function*/
  ngOnInit() {
    // this.onSubmit();
    this.loggedInrole = this.loginService.getRole(); //get loginUser Role
    this.display = 'chat';
    this.getTemplateList();
    // console.log(this.comment.nativeElement.scrollHeight);
  }

  /*to detect scroll height*/
  ngAfterContentChecked() {
    // console.log('hello');
    setTimeout(() => {
      this.scrolltop = this.comment.nativeElement.scrollHeight;
    });
    this.ref.detectChanges();
  }

  pushChatbotHeight() {
    this.scrolltop = this.comment.nativeElement.scrollHeight;
  }

  getTemplateList() {
    this.chatbotService.getTemplate().subscribe((res: any) => {
      // console.log(res);
      this.templateList = res.results;
      /**To enable default template -uncomment this */
      this.saveTemplateId(
        this.templateList.filter((x) => x.default == true)[0]
      );
      /** end---To enable default template -uncomment this */

    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get default template');
      });
  }


  saveTemplateId(e) {
    // // console.log(e, 'template');
    this.template_id = e.id;
    this.question_count = e.total_questions;
    this.selected_template = null;
    this.hide_Template_dropdown = true;
    this.startFlow();
  }



  public startFlow() {
    this.getQuestions();
  }

  public getQuestions() {
    this.sno++;
    this.chatbotService
      .getQuestions(this.template_id, this.sno)
      .subscribe((res: any) => {
        // let res=response.results[0];
        this.question_id = res.id;
        this.field_type = res.type;
        this.question_type = res.question_type;
        // this.skip_question=res.skip;
        this.botMessages.push(res);
        //this.pushChatbotHeight();
        //this.isEditable = callback(this.field_type);
        //this.getOptions();
        this.fieldType(this.field_type);
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get question');
        });
  }


  fieldType(field_type) {

    if (field_type == 'text') {
      this.isEditable = true;
    } else if (field_type != 'text') {
      this.isEditable = false;
      this.getOptions();
    }
    // this.pushChatbotHeight();
    // this.isEditable = callback(this.field_type);

  }

  public enableFieldType(field_type) {
    if (field_type == 'text') {
      return true;
    } else if (field_type != 'text') {
      return false;
    }
  }







  public getOptions() {
    if (this.question_type == 'department') {
      this.getDepartmentsList();
    } else if (this.question_type == 'ticket_type') {
      this.getTicketType();
    } else if (this.question_type == 'category') {
      this.getCategories();
    } else if (this.question_type == 'sub_category') {
      this.getSubCategories();
    } else if (this.question_type == 'city') {
      this.getCity();
    } else if (this.question_type == 'floor') {
      this.getFloor();
    } else if (this.question_type == 'room') {
      this.getRoom();
    } else if (this.question_type == 'region') {
      this.getRegions();
    } else if (this.question_type == 'country') {
      this.getCountry();
    } else if (this.question_type == 'state') {
      this.getState();
    } else if (this.question_type == 'item') {
      this.getItems();
    } else if (this.question_type == 'on_behalf') {
      this.getUsers();
    }
    else if (this.question_type == 'priorty') {
      this.getPriorties();
    }
    
  }

  saveAnswers(value = null) {
    if (value == 'skip') {
      this.skipAnswer();
    } else {
      this.saveAnswerAsPerQuestionType(value);
    }
  }

  skipAnswer() {
    this.obj = {
      question: this.question_id,
      answer_text: 'skipped',
    };
    this.userMessages.push(this.obj);
    // this.pushChatbotHeight();
    this.getQuestions();
    this.skipAnswerCount++;
    this.precheckFinalSubmit();
  }

  saveAnswerAsPerQuestionType(value) {
    // console.log('hiiiiiiiiiiiiiiiiiii', value);
    if (this.question_type == 'department') {
      this.department_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'ticket_type') {
      this.ticket_type_answer = value.name;

      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'category') {
      this.category_answer = value.id;

      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'sub_category') {
      this.sub_category_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'item') {
      this.sub_item_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'region') {
      this.region_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'country') {
      this.country_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'state') {
      this.state_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'city') {
      this.city_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'floor') {
      this.floor_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'room') {
      this.room_answer = value.id;
      this.saveAnswersAsPerFieldType(value);
    } else if (this.question_type == 'on_behalf') {
      this.on_behalf_answer = value.name;
      this.saveAnswersAsPerFieldType(value);
    }
    else if (this.question_type == 'priorty') {
      this.priorty_answer = value.name;
      this.saveAnswersAsPerFieldType(value);
    }
    else {
      this.saveAnswersAsPerFieldType(value);
    }
  }

  saveAnswersAsPerFieldType(value) {
    // console.log(value);
    if (this.field_type == 'list_type' || this.field_type == 'dropdown') {
      this.answer = value.name;
      this.selected_ng = null;
      this.submitAnswers();
    } else if (this.field_type == 'attachment') {
      this.submitImageValue(value);
    } else if (this.field_type == 'text') {
      this.answer = value;
      this.control.setValue('');
      this.submitAnswers();
    }
  }

  submitAnswers() {
    this.obj = {
      question: this.question_id,
      answer_text: this.answer,
    };
    if (this.answer) {
      if (this.answer.length > 0)   //if answer has vale, then only submit
        this.userMessages.push(this.obj);
      //  this.pushChatbotHeight();
      this.chatbotService.saveAnswers(this.obj).subscribe((res: any) => {
        // console.log(res);
        this.getQuestions();
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to save answer');
        });

      this.precheckFinalSubmit();
    }
  }

  /*func to update image value*/
  submitImageValue(e) {
    this.loadingImage = 1;

    /* if image available add below to payload */
    if (this.imageUploadcheck == 1) {
      this.obj = {
        question: this.question_id,
        answer_text: 'loading...',
      };

      this.userMessages.push(this.obj);
      //  this.pushChatbotHeight();
      /* to send payload in formData (bez it has image field)*/
      const imageFormData = new FormData();
      imageFormData.append('question', this.question_id);
      imageFormData.append('image', this.uploadedImage);

      this.chatbotService.saveAnswers(imageFormData).subscribe(
        (res) => {
          this.userMessages.pop();

          this.obj = {
            question: this.question_id,
            answer_text: 'image',
            imgsrc: res.image,
          };

          this.userMessages.push(this.obj);
          //  this.pushChatbotHeight();
          this.imageUploadcheck = 0;
          this.precheckFinalSubmitForImage();
          this.loadingImage = 0;
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to save image answer');
        }
      );
    } else if (this.imageUploadcheck == 0) {
      this.obj = {
        question: this.question_id,
        answer_text: 'Image skipped',
      };
      this.userMessages.push(this.obj);
      this.precheckFinalSubmitForImage();
    }
    /* subscribe create ticket api*/


    // if (
    //   this.question_count ==
    //   this.botMessages.length + this.skipQuestionsCount
    // ) {
    //   this.finalSubmitForm();
    // }
    // else {
    //   this.getQuestions();
    // }

  }


  precheckFinalSubmitForImage() {
    if (
      this.question_count ==
      this.botMessages.length + this.skipQuestionsCount
    ) {
      this.finalSubmitForm();
    }
    else {
      this.getQuestions();
    }
  }

  precheckFinalSubmit() {
    if (
      this.question_count ==
      this.botMessages.length + this.skipQuestionsCount
    ) {
      this.finalSubmitForm();
    }
  }

  finalSubmitForm() {
    {
      this.chatbotService.createTicket().subscribe(
        (res: any) => {
          // console.log(res);
          this.obj = {
            question_text: res.message,
          };
          this.botMessages.push(this.obj);
          //  this.pushChatbotHeight();
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to create ticket');
        }
      );
    }
  }

  refreshchatbot() {
    this.addanotherTicket('yes');
  }

  /* add another ticket */
  addanotherTicket(value: string) {
    if (value == 'yes') {
      this.imageUploadcheck = 0;
      this.selected_ng = null;
      this.sno = 0;
      this.botMessages = [];
      this.userMessages = [];
      this.getTemplateList();
      this.hide_Template_dropdown = false;
      this.skipAnswerCount = 0;
      this.skipQuestionsCount = 0;
      this.department_answer = '';
      this.uploadedImage = null;
      this.url = '';
      // this.startFlow();
    }
  }

  /*func to get departmentlist */
  getDepartmentsList() {
    this.departmentService
      .getDepartments(this.Optionspage)
      .subscribe((res: any) => {
        this.optionsCount = res.count;
        this.List = res.results;

        //  this.pushChatbotHeight();
        if (this.List.length == 0) {
          this.skipThisQuestion();
        }
      }, (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to department data');
      });
  }

  loadmoreOptions() {
    // console.log('test');
    // if (this.optionsCount != this.List.length) {
    //   this.getDepartmentsList();
    //   this.Optionspage++;
    // }
  }
  // (scroll)

  scroll(callback) {
    if (this.optionsCount != this.List.length) {
      callback();
    }
  }

  getCity() {
    this.locationService
      .getcities(1, this.state_answer)
      .subscribe((res: any) => {
        this.List = res.results;
        //  this.pushChatbotHeight();
        if (this.List.length == 0) {
          this.skipThisQuestion();
        }
      }, (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get city details');
      });
  }

  getFloor() {
    if (this.city_answer == null) {
      this.skipThisQuestion();
    } else {
      this.locationService
        .getFloor(1, this.city_answer)
        .subscribe((res: any) => {
          this.List = res.results;
          //  this.pushChatbotHeight();
          if (this.List.length == 0) {
            this.skipThisQuestion();
          }
        },
          (error) => {
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to get floor data');
          });
    }
  }

  getRoom() {
    if (this.floor_answer == null) {
      this.skipThisQuestion();
    } else {
      this.locationService
        .getRoom(1, this.floor_answer)
        .subscribe((res: any) => {
          this.List = res.results;
          //  this.pushChatbotHeight();
          if (this.List.length == 0) {
            this.skipThisQuestion();
          }
        }, (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get rooms data');
        });
    }
  }

  getRegions() {
    this.locationService.getregions(1).subscribe((res: any) => {
      this.List = res.results;
      //  this.pushChatbotHeight();
      if (this.List.length == 0) {
        this.skipThisQuestion();
      }
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get region data');
      });
  }

  getItems() {
    if (this.sub_category_answer == null) {
      this.skipThisQuestion();
    } else {
      this.equipmentService
        .getEquipments(1, this.sub_category_answer)
        .subscribe((res: any) => {
          this.List = res.results;
          //  this.pushChatbotHeight();
          if (this.List.length == 0) {
            this.skipThisQuestion();
          }
        }, (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get Equipments data');
        });
    }
  }

  getUsers() {
    this.userService.getusers(1).subscribe((res: any) => {
      //this.List = res.results;
      this.List = res.results.map((item) => {
        return { name: item.username };
      });
      if (this.List.length == 0) {
        this.skipThisQuestion();
      }
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get users data');
      });
  }

  skipThisQuestion() {
    this.botMessages.pop();
    this.skipQuestionsCount++;
    this.getnextQuestion();
  }

  getnextQuestion() {
    this.precheckFinalSubmit();
    this.getQuestions();
  }

  getState() {
    this.locationService
      .getstate(1, this.country_answer)
      .subscribe((res: any) => {
        this.List = res.results;
        //  this.pushChatbotHeight();
        if (this.List.length == 0) {
          this.skipThisQuestion();
        }
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get state data');
        });
  }

  getCountry() {
    this.locationService
      .getcountry(1, this.region_answer)
      .subscribe((res: any) => {
        this.List = res.results;
        //  this.pushChatbotHeight();
        if (this.List.length == 0) {
          this.skipThisQuestion();
        }
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get country data');
        });
  }

  /*func to get departmentlist */
  getCategories() {
    if (this.department_answer == null) {
      this.skipThisQuestion();
    } else {
      this.categoryService
        .getCategory(1, this.department_answer)
        .subscribe((res: any) => {
          this.List = res.results;
          //  this.pushChatbotHeight();
          if (this.List.length == 0) {
            this.skipThisQuestion();
          }
        }, (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get category data');
        });
    }
  }

  /*func to get departmentlist */
  getSubCategories() {
    if (this.category_answer == null) {
      this.skipThisQuestion();
    } else {
      this.categoryService
        .getSubCategories(1, this.category_answer, this.ticket_type_answer)
        .subscribe((res: any) => {
          this.List = res.results;
          //  this.pushChatbotHeight();

          if (this.List.length == 0) {
            this.skipThisQuestion();
          }
        });
    }
  }

  getPriorties() {
    this.List = [
      { id: 1, name: 'Low' },
      { id: 2, name: 'Medium' },
      { id: 3, name: 'High' },
      { id: 4, name: 'Critical' },
    ];
  }

  getTicketType() {
    this.List = [
      { id: 1, name: 'Incident', value: 'Incident' },
      { id: 2, name: 'Request', value: 'Request' },
    ];
  }

  /*Func to validate image*/
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.imageUploadcheck = 0;
  }

  /* Function called on choosing a pic */
  onImageUpload(event) {
    let files = event.target.files;
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
    }
    //check file is valid
    if (!this.validateFile(files[0].name)) {
      // console.log('Selected file format is not supported');
      this.errormsg =
        'Selected file format is not supported. Choose ".jpg" format only.';
      return false;
    } else {
      this.uploadedImage = event.target.files[0];
      this.imageUploadcheck = 1;
      this.imgerrormsg = '';
      // console.log("   this.imageUploadcheck",   this.imageUploadcheck)
    }
  }

  public delete() {
    this.url = null;
  }
}
