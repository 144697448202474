import { Component } from '@angular/core';
// import { SwPush } from '@angular/service-worker';
import { NotificationService } from './service/notification.service';
//import { NotificationService } from './views/shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  title = 'vlseedfrontend';
  // vapidKeys: string =
  //   'BFDGd6z8tXmwOuSgrBqvhkpPi9dPSuoZ3EHPVMDs7XnP1t6G-vC8uM8JRinWI6SExvPzEQ4tGfovcy4yHJBFELI';
  // payload = JSON.stringify({
  //   "notification": {
  //     "title": "Web Mail Notification",
  //     "body": "New Mail Received!",
  //     "icon": "images/bell.jpg",
  //     "vibrate": [100, 50, 100], //will cause the device to vibrate for 100 ms, be still for 50 ms, and then vibrate for 100 ms
  //     "requireInteraction": true,
  //     "data": { "dateOfArrival": Date.now(), },
  //     "actions": [{
  //       "action": "inbox",
  //       "title": "Go to Web Mail",
  //     }]
  //   }
  // })
  message: string = 'This is a test notification';

  payload: any = {
    head: 'Test Notification',
    body: this.message,
    id: 4,
  };

  constructor(private service: NotificationService) {
   // this.subscribeToNotifications();
  }

  ngOnInit() {
    // if (navigator.serviceWorker) {
    //   // First, do a one-off check if there's currently a service worker in control.
    //   if (navigator.serviceWorker.controller) {
    //     console.log(
    //       `This page is currently controlled by: ${navigator.serviceWorker.controller}`
    //     );
    //     navigator.serviceWorker.controller.onstatechange = function () {
    //       console.log('state' + navigator.serviceWorker.controller.state);
    //     };
    //   } else {
    //     //This happens on ctrl+f5(force refresh)
    //     console.log(
    //       'This page is not currently controlled by a service worker.'
    //     );
    //     navigator.serviceWorker
    //       .register('./ngsw-worker.js')
    //       .then(function (registration) {
    //         console.log('Service worker registration succeeded:', registration);
    //         window.location.reload();
    //       }),
    //       function (error) {
    //         console.log('Service worker registration failed:', error);
    //       };
    //   }
    //   // Then, register a handler to detect when a new or
    //   // updated service worker takes control.
    //   navigator.serviceWorker.oncontrollerchange = function () {
    //     console.log(
    //       'This page is now controlled by:',
    //       navigator.serviceWorker.controller
    //     );
    //   };
    // } else {
    //   console.log('Service workers are not supported.');
    // }
  }

  // triggerMessage() {
  //   this.service
  //     .triggerMessage(this.payload)
  //     .subscribe((data) => console.log(data));
  // }

  // subscribeToNotifications() {
  //   //True if the Service Worker is enabled (supported by the browser and enabled via ServiceWorkerModule).
  //   if (this.swPush.isEnabled) {
  //     console.log('hi sub');
  //     this.swPush.notificationClicks.subscribe((x) => console.log(x)); //triggers only on click
  //     //requestSubscription subscribes to Web Push Notifications, after requesting and receiving user permission.
  //     this.swPush
  //       .requestSubscription({
  //         serverPublicKey: this.vapidKeys,
  //       })
  //       .then((sub) => {
  //         let subscription: any = JSON.parse(JSON.stringify(sub));
  //         let data = {
  //           endpoint: subscription?.endpoint,
  //           p256dh: subscription?.keys?.p256dh,
  //           auth: subscription?.keys?.auth,
  //           browser: this.getBrowserName(),
  //           group: 'thedesk'
  //         };
  //         this.service.subscribe(data).subscribe((x) => {let res = x;});
  //       })
  //       .catch((err) =>
  //         console.error('Could not subscribe to notifications', err)
  //       );
  //   }
  // }
  // getBrowserName() {
  //   // Custom function to get the browser name
  //   const userAgent = navigator.userAgent;
  //   if (userAgent.includes('Chrome')) {
  //     return 'Chrome';
  //   } else if (userAgent.includes('Firefox')) {
  //     return 'Firefox';
  //   } else if (userAgent.includes('Safari')) {
  //     return 'Safari';
  //   } else {
  //     return 'Unknown';
  //   }
  // }
}
