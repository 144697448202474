import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CategoryService } from 'src/app/service/category.service';
import { DepartmentService } from 'src/app/service/department.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { LocationService } from 'src/app/service/location.service';
import { LoginService } from 'src/app/service/login.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-equip-create',
  templateUrl: './equip-create.component.html',
  styleUrls: ['./equip-create.component.scss'],
})
export class EquipCreateComponent implements OnInit {
  /*intalise form */
  createEquipmentForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    serial_number: new FormControl(''),
    purchase_date: new FormControl('', [Validators.required]),
    warranty_expiration: new FormControl('', [Validators.required]),
    current_location: new FormControl(''),
    notes: new FormControl(''),
    city: new FormControl(),
    floor: new FormControl(),
    room: new FormControl(),
    // department: new FormControl('',),
    re_serial_number: new FormControl(''),
    sub_category: new FormControl(''),
    current_user: new FormControl(''),
  });

  equip_id: any;
  equip_details: any;
  errormsg: any;
  departments = [];
  selectedDepartment: any;
  errormsg_re_serial_number: string = '';
  mainteneceDepartmentCount: any;
  page: number = 1;
  maintenance_departments = [];
  selectedEquipments: any;
  category: any;
  subCategory: any;
  department_answer: any;
  category_answer = '';
  subcategoryID: any;
  rooms: any;
  floors: any;
  cities: any;
  state_answer: string;
  city_answer: string;
  floor_answer: string;
  loggedUserId: any;
  serialNumberConfirmed = false;

  constructor(
    public dialogRef: MatDialogRef<EquipCreateComponent>,
    private maintenanceService: MaintenanceService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private equipmentService: EquipmentService,
    private userService: UserService,
    private categoryService: CategoryService,
    private locationService: LocationService,
    private loginService: LoginService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.loggedUserId = this.loginService.getUserId();
    // console.log(this.loggedUserId,"loggedUser");
    this.getDepartmentsList();
    // console.log(this.data);
    if (this.data.subcategoryID) {
      this.getsubCategoryName(this.data.subcategoryID);
    }
    if (this.data.equip_data) {
      //this.getTaskDetails();
      //  console.log(this.data.equip_data);
      // this.subcategoryID=this.data.subcategoryID;
      this.equip_id = this.data.equip_data.id;
      this.getSubCategories();
      this.getEquipDetails();
    }
    this.getCity();
    //this.confirmSerailNumber(); 
  }

  getCity() {
    this.locationService
      .getcities(1, this.state_answer)
      .subscribe((res: any) => {
        this.cities = res.results;
      });
  }

  onChangeCity(e) {
    this.city_answer = e;
    this.getFloor();
    // this.getRoom();
  }

  getFloor() {
    {
      this.locationService
        .getFloor(1, this.city_answer)
        .subscribe((res: any) => {
          this.floors = res.results;
        });
    }
  }

  onChangeFloor(e) {
    // console.log('hiii');
    this.floor_answer = e;
    this.getRooms();
    // console.log('hiii');
  }

  getRooms() {
    {
      this.locationService
        .getRoom(1, this.floor_answer)
        .subscribe((res: any) => {
          this.rooms = res.results;
        });
    }
  }

  getsubCategoryName(id) {
    this.categoryService.readSubCategoryDetail(id).subscribe(
      (res: any) => {
        // console.log(res);
        //this.createEquipmentForm.value.sub_category=res.name;
        this.createEquipmentForm.patchValue({
          sub_category: res.name,
        });
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Sub Category List');
      }
    );
  }

  /* Func to get list of departments */
  getDepartmentsList() {
    this.departmentService.getDepartmenties().subscribe(
      (res: any) => {
        this.departments = res.results;
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Deparment list');
      }
    );
  }

  onSelectDepartment(e) {
    this.department_answer = e;
    this.getCategories();
  }
  /*func to get departmentlist */
  getCategories() {
    this.categoryService
      .getCategory(1, this.department_answer)
      .subscribe((res: any) => {
        this.category = res.results;
      });
  }

  onSelectCategory(e) {
    this.category_answer = e;
    this.getSubCategories();
  }

  /*func to get departmentlist */
  getSubCategories() {
    this.categoryService
      .getSubCategories(1, this.category_answer)
      .subscribe((res: any) => {
        this.subCategory = res.results;
      });
  }

  format_date(date) {
    const fdate = date.split('-');
    const finaldate = `${fdate[2]}-${fdate[1]}-${fdate[0]}`;
    return finaldate.toString();
  }
  /*func to get task details and patch values to form */
  getEquipDetails() {
    /* subscribe create ticket api*/
    this.equipmentService.getEquipmentDetail(this.equip_id).subscribe(
      (res) => {
        this.equip_details = res;

        this.createEquipmentForm.patchValue({
          name: this.equip_details.name,
          description: this.equip_details.description,
          serial_number: this.equip_details.serial_number,
          re_serial_number: this.equip_details.serial_number,
          purchase_date: (this.equip_details.purchase_date),
          warranty_expiration: (
            this.equip_details.warranty_expiration
          ),
          current_location: this.equip_details.current_location,
          notes: this.equip_details.notes,
          // department: this.equip_details.department,
          sub_category: this.equip_details.sub_category,
          current_user: this.equip_details.current_user,
          city: this.equip_details.city,
          floor: this.equip_details.floor,
          room: this.equip_details.room,
        });

        // this.selectedDepartment.id=this.equip_details.department
      },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('unable to get Equipment details');
      }
    );
    // console.log(this.equip_details);
  }

  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    this.createEquipmentForm.value.current_user = this.loggedUserId;

    if (!this.serialNumberConfirmed) {
      this.confirmSerailNumber();
    }
    // if (
    //   this.createEquipmentForm.value.serial_number !=
    //   this.createEquipmentForm.value.re_serial_number
    // ) {
    //   this.errormsg_re_serial_number = 'mismatch in serial number';
    // } 
    // else    
    if (this.serialNumberConfirmed) {
      // this.getsubCategoryName(this.data.subcategoryID);
      // console.log(this.createEquipmentForm.value);
      // this.createEquipmentForm.value.sub_category=this.data.cat
      if (this.equip_id) {
        this.equipmentService
          .updateEquipment(this.equip_id, this.createEquipmentForm.value)
          .subscribe(
            (res) => {
              /* if successfully submitted display msg to Equipment in toast then close modal*/
              //console.log("Equipment_id", res.id);
              this.closeDialog();
              this.toastPopupService.openToast(
                'Equipment Created Successfully'
              ); //display success mesg
            },
            (error) => {
              // console.log(error);
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to Create Equipment');
            }
          );
      } else {
        /* subscribe create ticket api*/
        this.equipmentService
          .addEquipment(this.createEquipmentForm.value)
          .subscribe(
            (res) => {
              /* if successfully submitted display msg to Equipment in toast then close modal*/
              // console.log("Equipment_id", res.id);
              this.closeDialog();
              this.toastPopupService.openToast(
                'Equipment Created Successfully'
              ); //display success mesg
            },
            (error) => {
              // console.log(error);
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to Create Equipment');
            });
      }
    }
  }

  confirmSerailNumber() {
    Swal.fire({
      title: 'Confirm the Serial Number',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      inputValue: this.createEquipmentForm.value.serial_number,
      showCancelButton: true,
      heightAuto: false,
      confirmButtonText: 'Confirm',
      showLoaderOnConfirm: true,
      preConfirm: (res) => {
        if (res == this.createEquipmentForm.value.serial_number) {

          return true;
        }
        else {
          Swal.showValidationMessage(
            `Request failed: 'mismatch'`
          )
        }
        // return fetch(`//api.github.com/users/${login}`)
        //   .then(response => {
        //     if (!response.ok) {
        //       throw new Error(response.statusText)
        //     }
        //     return response.json()
        //   })
        //   .catch(error => {
        //     Swal.showValidationMessage(
        //       `Request failed: ${error}`
        //     )
        //   })
        return true
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.serialNumberConfirmed = true;
      }
    })
  }


  focusout(e) {
    this.confirmSerailNumber();
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }


}
