import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Maintenance } from '../interface/Maintenance';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TechnicianTask } from '../interface/TechnicianTask';
import { TechnicianUser } from '../interface/TechnicianUser';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getMytickets(page = 1) {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}mytickets/api/?page=${page}`
    );
  }

  getMyticketsSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}mytickets/apis?page=${page}&search=${result.search}`
    );
  }


  getMyticketsFilter(page = 1, result) {
    if (result.ticket_number == undefined) {
      result.ticket_number = '';
    }
    if (result.department == undefined) {
      result.department = '';
    }
    if (result.ticket_type == undefined) {
      result.ticket_type = '';
    }
    if (result.created_at == undefined) {
      result.created_at = '';
    }
    if (result.ticket_status == undefined) {
      result.ticket_status = '';
    }
    if (result.priority == undefined) {
      result.priority = '';
    }
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}mytickets/api?page=${page}&ticket_number=${result.ticket_number}&department=${result.department}&ticket_type=${result.ticket_type}&status=${result.ticket_status}&created_at=${result.created_at}&priority=${result.priority}`

    );
  }



  getMyticketDetails(id) {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}mytickets/api/${id}/`
    );
  }


  getTechniciantUserTickets(page = 1) {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}technician/tickets/api/?page=${page}`
    );
  }

  ticketList() {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      `${this.endpoint}tickets/api/`
    );
  }

  getTicketDetails(id) {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      `${this.endpoint}tickets/api/${id}/`
    );
  }

  updateTicketDetails(id, payload) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}mytickets/api/${id}/`,payload
      `${this.endpoint}mytickets/api/${id}/description/`, payload

    );
  }


  updateTicketNotes(id, payload, myticketNotes = 0) {
    if (myticketNotes == 1) {
      return this.http.post<Maintenance>(
        //'http://192.168.143.237:8001/maintenance/tickets/api/'
        `${this.endpoint}tickets/api/additionalnotes/create/`, payload
      );
    }
    else {
      return this.http.post<Maintenance>(
        //'http://192.168.143.237:8001/maintenance/tickets/api/'
        `${this.endpoint}tickets/api/techniciannotes/create/`, payload
      );
    }

  }

  addticket(postData) {
    return this.http.post<Maintenance>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}tickets/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  addticketImage(id, postData) {
    return this.http.put<Maintenance>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}tickets/api/${id}/image/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  deleteticket(id) {
    return this.http.delete<Maintenance>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}tickets/api/${id}`

    );
  }

  /*api for admin/manager role*/

  getTickets(page = 1, ticket_status = '') {
    if (ticket_status == undefined) {
      ticket_status = '';
    }
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}team/tickets/api?page=${page}&status=${ticket_status}`
      // team/tickets/api/
    );
  }



  getSearchTickets(page = 1, result, ticket_status = '') {
    if (result.search == undefined) {
      result.search = '';
    }
    if (ticket_status == undefined) {
      ticket_status = '';
    }
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}team/tickets/api?page=${page}&search=${result.search}&status=${ticket_status}`
    );
  }


  getFilterTickets(page = 1, result) {
    if (result.ticket_number == undefined) {
      result.ticket_number = '';
    }
    if (result.department == undefined) {
      result.department = '';
    }
    if (result.ticket_type == undefined) {
      result.ticket_type = '';
    }
    if (result.created_at == undefined) {
      result.created_at = '';
    }

    if (result.priority == undefined) {
      result.priority = '';
    }
    if (result.ticket_status == undefined) {
      result.ticket_status = '';
    }
    if (result.created_by == undefined) {
      result.created_by = '';
    }

    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}team/tickets/api?page=${page}&ticket_number=${result.ticket_number}&department=${result.department}&ticket_type=${result.ticket_type}&status=${result.ticket_status}&created_at=${result.created_at}&priority=${result.priority}&created_by=${result.created_by}`
    );
  }

  approveTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/approve/`,
      postData
    );
  }

  requestEditTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/edit_request/`,
      postData
    );
  }

requestDepartmentChangeTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/dept_change_request/`,
      postData
    );
}


requestApproveTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/additional_permission/`,
      postData
    );
}

  mergeTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/merge/`,
      postData
    );
  }


  forwarddTicket(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/department/`,
      postData
    );
  }




  feedbackTicket(id, postData) {
    return this.http.post<Maintenance>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}api/${id}/feedback/create/`,
      // `${this.endpoint}service/maintenance/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    );
  }

  assignTicket(id, postData) {
    return this.http.put<Maintenance>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}team/tickets/api/${id}/assign/`,
      // `${this.endpoint}service/maintenance/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    );
  }


  updateAssignTicket(id, postData) {
    return this.http.put<Maintenance>(
      `${this.endpoint}team/tickets/api/${id}/assign/`,
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      postData
    );
  }

  getAssignTicket(id) {
    return this.http.get<Maintenance>(
      `${this.endpoint}team/tickets/api/${id}/assign/`,
    );
  }


  readMaintainanceMode() {
    return this.http.get<Maintenance>(
      `${this.endpoint}api/maintenance_mode/list/`,
    );
  }


  readAutoAssignTicket() {
    return this.http.get<Maintenance>(
      `${this.endpoint}api/flow-control/`,
    );
  }



  updateAutoAssignTicket(payload) {
    return this.http.put<Maintenance>(
      `${this.endpoint}api/flow-control/1/`, payload
    );
  }




  updateTicketStatus(id, postData) {
    return this.http.put<Maintenance>(
      `${this.endpoint}team/tickets/api/${id}/status/`,
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      postData
    );
  }

  reOpenTicket(id, postData) {
    return this.http.put<Maintenance>(
      `${this.endpoint}tickets/api/${id}/reopen/`,
      //`${this.endpoint}maintenance/tickets/api/${id}/`,
      postData
    );
  }


  getTicketdetails(id) {
    return this.http.get<Maintenance>(
      `${this.endpoint}team/tickets/api/${id}/`,
    );
  }


  getTicketFullData(id, myticket) {
    if (myticket == 0) {
      return this.http.get<Maintenance>(
        //'http://192.168.143.237:8001/maintenance/tickets/api/'
        `${this.endpoint}team/tickets/api/${id}/`
      );
    }
    else if (myticket == 1) {
      return this.http.get<Maintenance>(
        //'http://192.168.143.237:8001/maintenance/tickets/api/'
        `${this.endpoint}mytickets/api/${id}/`
      );
    }

  }


  /*api for technician */

  getTechnicianTask(page = 1) {
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}technician/maintenance/api/?page=${page}`
    );
  }

  getTechnicianTaskSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}technician/maintenance/api/?page=${page}&search=${result.search}`
    );
  }






  createService(postData) {
    return this.http.post<TechnicianTask>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}technician/maintenance/api/create/`,
      postData
    );
  }

  updatetaskStatus(id, postData) {
    return this.http.put<Maintenance>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}maintenance/tickets/api/${id}/`,
      //`${this.endpoint}technician/maintenance/api/${id}/`, 
      `${this.endpoint}team/tickets/api/${id}/status/`,
      postData
    );
  }


  gettaskDetails(id) {
    return this.http.get<TechnicianTask>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}maintenance/tickets/api/${id}/`,
      `${this.endpoint}team/tickets/api/${id}/status/`

    );
  }


  /*api for getting role*/

  getTechnicians(page = 1) {
    return this.http.get<TechnicianUser>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/technician/?page=${page}`
    );
  }
  closeTicket(id, postData) {
    return this.http.post<Maintenance>(
      `${this.endpoint}validate-happy-code/?ticket_id=${id}`,
      postData
    );
  }

  resndOtp(id) {
    return this.http.post<Maintenance>(
      `${this.endpoint}resend-happycode/?ticket_id=${id}`,{}
    );
  }
}
