import { Component, OnInit } from '@angular/core';

//import * as echarts from 'echarts';
import { ChartService } from 'src/app/service/charts.service';

@Component({
  selector: 'app-ticket-count-as-per-department',
  templateUrl: './ticket-count-as-per-department.component.html',
  styleUrls: ['./ticket-count-as-per-department.component.scss'],
})
export class TicketCountAsPerDepartmentComponent implements OnInit {
  total: any;
  A_key: any;
  A_val: any;
  nam: any;
  tick: any;
  options: any;

  constructor(private chartService: ChartService) {}

  ngOnInit() {
    this.getticketassign();
  }
  capitalizeWords(arr) {
    return arr.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase();
      const rest = word.slice(1).toLowerCase();
      return firstLetter + rest;
    });
  }
  getticketassign() {
    this.chartService.getticketassignedData().subscribe((res) => {
      this.nam = res.ticket_by_dept;
      this.A_key = Object.keys(this.nam);
      this.A_val = Object.values(this.nam);
      this.options = {
        title: {
          // text: 'Department Ticket Counts',
          subtext: '',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.A_key,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [
          {
            name: 'Count',
            type: 'bar',
            barWidth: '60%',
            data: this.A_val,
          },
        ],
      };
    });
  }
}
