import { Component, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChatbotService } from 'src/app/service/chatbot.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { TicketTemplateCreateComponent } from '../ticket-template-create/ticket-template-create.component';
import { TicketTemplateViewComponent } from '../ticket-template-view/ticket-template-view.component';

@Component({
  selector: 'app-ticket-template-list',
  templateUrl: './ticket-template-list.component.html',
  styleUrls: ['./ticket-template-list.component.scss'],
})
export class TicketTemplateListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  dialogRef: MatDialogRef<any>;

  searchForm = new FormGroup({
    search: new FormControl('',),
  })
  errormsg: any;
  refresh: boolean;
  templateList = [];
  search: boolean;
  count: number;
  page: any;
  saveCurrentPageNumber: any;
  default: any;

  constructor(
    private chatbotService: ChatbotService,
    private toastPopupService: ToastPopupService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.getTemplateList();
  }

  addTemplateDialog() {
    this.dialogRef = this.dialog.open(TicketTemplateCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }


  viewDialog(id, Data) {
    this.dialogRef = this.dialog.open(TicketTemplateViewComponent, {
      width: '80%',
      data: {
        id: id,
        Data: Data,
      }
    });
  }

  editDialog(id, Data) {
    this.dialogRef = this.dialog.open(TicketTemplateCreateComponent, {
      width: '80%',
      data: {
        id: id,
        Data: Data,
      }
    });
  }

  deleteDialog(id, Data) {
    this.chatbotService.deleteTemplate(id).subscribe((res: any) => {
      this.getTemplateList();
    },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast(error.error);
      });
  }

  Dialog(id, Data) {
    this.dialogRef = this.dialog.open(TicketTemplateCreateComponent, {
      width: '80%',
      data: {
        id: id,
        Data: Data,
      }

    });
  }

  refreshTemplates() {
    // this.filter=false;
    this.search = false;
    this.refresh = true;
    this.searchForm.patchValue({
      search: ''

    });
    this.getTemplateList();
  }

  onSearchSubmit() {

  }

  /*func to reload ticketList */
  reloadList() {
    // console.log("at reload page", this.page, this.saveCurrentPageNumber)
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getTemplateList()
      } else if (result == 'norefresh') {

      } /*else if (result == 'refreshCurrentScreen') {
        this.getticketList();
      }*/
      else {
        this.refresh = true;
        this.getTemplateList()
      }
      this.page = this.saveCurrentPageNumber;
    });
  }

  getTemplateList() {
    this.chatbotService.getTemplate(this.searchForm.value.search
    ).subscribe((res: any) => {
      // console.log(res);
      this.templateList = res.results;
      this.count = res.count;
      this.default = this.templateList.filter(x => x.default == true)[0].id;
      // console.log(this.default);
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get user details');
      }
    );

  }


  makeDefault(e) {
    // console.log("default template id", e);
    const payload = {
      "name": e.name,
      "default": true
    }
    this.chatbotService.makeDefaultTemplate(e.id, payload
    ).subscribe((res: any) => {
      // console.log(res);
    });
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();
      if (this.templateList.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getTemplateList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }


}

