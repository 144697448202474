import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-notification-controllers',
  templateUrl: './notification-controllers.component.html',
  styleUrls: ['./notification-controllers.component.scss'],
})

export class NotificationControllersComponent implements OnInit {
  data: any;
  errormsg: any;
  /*intalise form */
  notificationControllerForm = new FormGroup({
    auto_assign: new FormControl(false,),
    mail_to_user: new FormControl(false,),
    mail_to_technician: new FormControl(false,),
    mail_to_team: new FormControl(false,),
    sms_to_user: new FormControl(false,),
    sms_to_technician: new FormControl(false,),
    sms_to_team: new FormControl(false,),
  })

  constructor(
    private maintenanceService: MaintenanceService,
    private toastPopupService: ToastPopupService,
  ) { }

  ngOnInit() {
    this.getdetails();
  }

  works: boolean;

  onSubmit() {
    {
      this.maintenanceService.updateAutoAssignTicket(this.notificationControllerForm.value)
        .subscribe((res) => {
          this.toastPopupService.openToast('Updated Successfully');
          // console.log(res,'ppppp');
        },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to Auto Assign Ticket');
          }
        )
    }
  }

  /**func to read notification details */
  getdetails() {
    {
      this.maintenanceService.readAutoAssignTicket().subscribe((res) => {
        this.data = res.results[0]
        this.notificationControllerForm.patchValue({
          auto_assign: this.data.auto_assign,
          mail_to_user: this.data.mail_to_user,
          mail_to_technician: this.data.mail_to_technician,
          mail_to_team: this.data.mail_to_team,
          sms_to_user: this.data.sms_to_user,
          sms_to_technician: this.data.sms_to_technician,
          sms_to_team: this.data.sms_to_team,
        });
        // console.log(res,'rrr');
      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get user details');
        }
      )
    }
  }

}

