import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CityService } from 'src/app/service/city.service';
import { StateService } from 'src/app/service/state.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-city-create',
  templateUrl: './city-create.component.html',
  styleUrls: ['./city-create.component.scss'],
})
export class CityCreateComponent implements OnInit {

  name: any;
  /*intalise form */
  createCityForm = new FormGroup({
    name: new FormControl('',[Validators.required]),
    state: new FormControl('',[Validators.required]),
  })
  city_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  states: any = [];
  statesCount: number;

  constructor(
    public dialogRef: MatDialogRef<CityCreateComponent>,
    private cityService: CityService,
    private stateService: StateService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data?.id) {
      this.city_id = this.data.id;
      this.getCityDetails();
    }
    this.getStateList();
  }

  getCityDetails() {
    this.cityService.getCityData(this.city_id).subscribe((res: any) => {
      this.createCityForm.patchValue({
        name: res.name,
        state: res?.state.id   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get City Details');
      }
    )

  }
  onSubmit() {
    if (this.city_id) {
      this.cityService.updateCity(this.city_id, this.createCityForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('City update Successfully');   //display success mesg
        // }


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update City');
        }
      )
    }
    else {
      this.cityService.addCity(this.createCityForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('City Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create City');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  getStateList() {
    this.stateService.getStates().subscribe(
      (res: any) => {
        //this.roleTypes = res.results;
        this.states = [
          ...new Map(
            this.states.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.statesCount = res.count;
        this.onScrollStates();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get State List');
      }
    );
  }

  onScrollStates() {
    // console.log('hello');
    if (this.statesCount != this.states.length) {
      this.page++;

      this.getStateList();
    }
  }
}
