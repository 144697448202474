import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss'],
})
export class LocationCreateComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
