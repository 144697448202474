import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceService } from '../../../../service/maintenance.service';
import { IonInfiniteScroll } from '@ionic/angular';
import { DepartmentService } from 'src/app/service/department.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { LoginService } from 'src/app/service/login.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { ChatbotService } from 'src/app/service/chatbot.service';
import { ModalQuestionCreateComponent } from '../modal-question-create/modal-question-create.component';

@Component({
  selector: 'app-modal-question-list',
  templateUrl: './modal-question-list.component.html',
  styleUrls: ['./modal-question-list.component.scss'],
})

export class ModalQuestionListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  dialogRef: MatDialogRef<any>;
  errormsg: any;
  tickets: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  departments: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;

  searchDepartmentForm = new FormGroup({
    search: new FormControl('',),
  })

  filterDepartmentForm = new FormGroup({
    start_date: new FormControl('',),
    end_date: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;
  LoggedUsername: any;

  constructor(
    private chatbotService: ChatbotService,
    private maintenanceService: MaintenanceService,
    private departmentService: DepartmentService,
    private screensizeService: ScreensizeService,
    private loginService: LoginService,
    public dialog: MatDialog,
    private toastPopupService: ToastPopupService
  ) { }

  ngOnInit() {
    this.getDepartmentsList();
  }

  /*func to get ticket list */
  getDepartmentsList() {

    if (this.refresh == true) {
      this.departments = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    {
      this.chatbotService.getModalQuestionsList().subscribe((res: any) => {
        //console.log(res);
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.departments = [
          ...new Map(
            this.departments
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      },
        (error) => {

          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Get Custom Role');
        }
      )
    };
  }


  refreshDepartment() {
    this.search = false;
    this.refresh = true;
    this.searchDepartmentForm.patchValue({
      search: ''

    });
    this.getDepartmentsList();
  }

  /*func to open create department modal */
  addDepartmentDialog() {
    this.dialogRef = this.dialog.open(ModalQuestionCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }


  /*func to update department modal */
  updateDepartmentDialog(res) {
    this.dialogRef = this.dialog.open(ModalQuestionCreateComponent, {
      width: '80%',
      data: res
    });
    this.reloadList();
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getDepartmentsList();
      }

    });
  }


  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.departments.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getDepartmentsList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getDepartmentsList();
  }



}

