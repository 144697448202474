import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { UserService } from 'src/app/service/users.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { LoginService } from 'src/app/service/login.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { CustomRoleService } from 'src/app/service/customrole.service';

import { JsonPipe } from '@angular/common';
import { StarRatingColor } from '../../../widgets/star-rating/star-rating.component';
//import { DialogData } from '../../../../interface/DialogData';

@Component({
  selector: 'app-ticket-feedback',
  templateUrl: './ticket-feedback.component.html',
  styleUrls: ['./ticket-feedback.component.scss'],
})
export class TicketFeedbackComponent implements OnInit {
  rating: number = 3;
  starCount: number = 5;
  starColor: StarRatingColor = StarRatingColor.accent;
  starColorP: StarRatingColor = StarRatingColor.primary;
  starColorW: StarRatingColor = StarRatingColor.warn;

  /*inialize form */
  feedbackTicketForm = new FormGroup({
    feedback: new FormControl(''),
  });
  errormsg: any;

  /*constructor func */
  constructor(
    private maintenanceService: MaintenanceService,
    private toastPopupService: ToastPopupService,
    public dialogRef: MatDialogRef<TicketFeedbackComponent>, //refrence to modal
    @Inject(MAT_DIALOG_DATA) public data //injecting data to modal
  ) {
    // console.log(data.ticketId);
  }

  /* ngOnInit() func called at the time of component created */
  ngOnInit() { }

  onRatingChanged(rating) {
    // console.log(rating);
    this.rating = rating;
  }

  /* func called on form submit */
  onSubmit() {
    // console.log(this.feedbackTicketForm.value);
    const payload = {
      feedback: this.feedbackTicketForm.value.feedback,
      ticket: this.data.ticketId,
      rating: this.rating,
    };
    // console.log(payload);

    {
      this.maintenanceService
        .feedbackTicket(this.data.ticketId, payload)

        .subscribe(
          (data) => {
            // console.log(data);
            // this.loginData = data;
            this.closeDialogsimple();
            this.toastPopupService.openToast('Ticket Feedback Successfully');
          },
          (error) => {
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to add feedback');
          }
        );
    }
  }

  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }
}
