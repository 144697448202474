import { Component, HostListener, OnInit } from '@angular/core';
//import { MaintenanceService } from '../../../service/maintenance.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login.service';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { NotificationsService } from 'src/app/service/notifications.service';
import { ToastController } from '@ionic/angular';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { PushnotificationService } from 'src/app/service/pushnotification.service';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss'],
})

export class ReportListComponent implements OnInit {

  notifications = [];
  errormsg: any;
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  count: any;
  page: number = 1;
  results_per_page: any;
  refresh: boolean = false;
  saveCurrentPageNumber: number;
  isDesktop: boolean = false;

  public getScreenWidth: any;
  public getScreenHeight: any;
  subscription: Subscription;
  filterData: any;
  filter: boolean;
  permissions: any;

  /*intalise form */
  searchNotificationForm = new FormGroup({
    search: new FormControl('',),
  })

  search: boolean = false;
  //  notifications=[];
  notificationCount: number;
  notificationsPage = 1;
  notif: any;

  constructor(
    private loginService: LoginService,
   // private maintenanceService: MaintenanceService,
    public dialog: MatDialog,
    private screensizeService: ScreensizeService,
    private notificationsService: NotificationsService, 
    private toastPopupService: ToastPopupService,
  ) {
    this.screensizeService.isDesktopView().subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
      this.isDesktop = isDesktop;
    });
  }

ngOnInit() {
    this.isDesktop = this.screensizeService.getIsDesktop();
    this.image_url = environment.IMAGE_URL;
    this.role = this.loginService.getUserRole();  //get loginUser Role    
    this.getNotificationList();
    this.role = this.loginService.getRole();  //get loginUser Role 
    this.getpermissions();
}


getpermissions() {
    this.loginService.getUserPermissions(this.role).subscribe((res: any) => {
      this.permissions = res.results;
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get User Permissions details');
      }
    );
  }

/*Func to get list of notifications */
getNotificationList() {
    //this.permissions = this.loginService.getUserPermissions(); 
    // console.log(this.page);
    if(this.refresh == true) {
      this.notifications = [];
      this.notificationsPage = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    this.notificationsService.getNotificationSearch(this.notificationsPage, this.searchNotificationForm.value).subscribe((res: any) => {
      this.notifications = res.results;
      this.notificationCount = res.count;
      this.notifications = [
        ...new Map(
          this.notifications
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];

    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Notifications details');
      });
}

loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.notifications.length === this.notificationCount) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getNotificationList();
        // console.log('Inprogress');
      }
    }, 500);
  }

onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getNotificationList();
  }

refreshNotifications() {
    this.filter = false;
    this.search = false;
    this.refresh = true;
    this.searchNotificationForm.patchValue({
      search: ''
    });
    this.getNotificationList();
}

  // this.filter=false;

closeNotification(id) {
    const payload = {
      "is_read": true
    }
    this.notificationsService.updateNotifications(id, payload).subscribe((res: any) => {

    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to Update Notifications');
      });
  }

}