import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../interface/User';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { CustomRole } from '../interface/CustomRole';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private myData: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  private _refreshneeded = new Subject<void>();

  get refreshneeded() {
    return this._refreshneeded;
  }

  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  checkUserNmaeAndSendOTP(postData): Observable<User> {
    return this.http.post<User>(`${this.endpoint}user/api/check-username/`, postData).pipe(
      tap(() => {
        this._refreshneeded.next();
      })
    );
  }
  userSetPassword(postData): Observable<User> {
    return this.http.post<User>(`${this.endpoint}user/api/pin-set-up/`, postData).pipe(
      tap(() => {
        this._refreshneeded.next();
      })
    );
  }

  login(postData): Observable<User> {
    return this.http.post<User>(`${this.endpoint}api/token/`, postData).pipe(
      tap(() => {
        this._refreshneeded.next();
      })
    );
  }

  logout(postData): Observable<User> {
    return this.http.post<User>(`${this.endpoint}user/api/logout/`, postData);
  }

  loginCheck(postData): Observable<User> {
    return;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUserRole() {
    let role = '';
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    if (loginTokenDecodedData.is_technician == true) {
      role = 'is_technician';
    } else if (loginTokenDecodedData.is_supervisor == true) {
      role = 'is_supervisor';
    } else if (loginTokenDecodedData.is_end_user == true) {
      role = 'is_end_user';
    }
    return role;
  }

  getRole() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.roles;
  }

  getUsername() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.username;
  }

  getFullName() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return (
      loginTokenDecodedData.first_name + ' ' + loginTokenDecodedData.last_name
    );
  }

  loggedIn() {
    const isToken = localStorage.getItem('token');
    if (!isToken) {
      return false;
    }

    const helper = new JwtHelperService();
    const isExpire = helper.isTokenExpired(isToken);
    return !isExpire;
  }

  get currentUser() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    return new JwtHelperService().decodeToken(token);
  }

  currentUserPermissions() {
    const token = localStorage.getItem('token');
    if (!token) {
      return false;
    }
    var myArray = new JwtHelperService().decodeToken(token).permissions;

    const myObj = myArray.reduce(
      (a, key) => Object.assign(a, { [key]: true }),
      {}
    );
    // console.log(myObj);

    return myObj;
  }

  getUserPermissions(role): Observable<CustomRole> {
    return this.http.get<CustomRole>(`${this.endpoint}api/role/?name=${role}`);
  }

  getRoleName(id): Observable<CustomRole> {
    return this.http.get<CustomRole>(`${this.endpoint}api/role/${id}`);
  }

  getloggedUserPermission() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    let id = loginTokenDecodedData.user_id;
    return this.http.get<User>(`${this.endpoint}user/api/${id}/`);
  }

  getUserId() {
    // let permissions={};
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    let user_id = loginTokenDecodedData.user_id;
    return user_id;
  }

  getFirstName() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.first_name;
  }
  getLastName() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.last_name;
  }
  get CurrentUserKekaId() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.from_keka_keka_id;
  }
  get CurrentUserReportedToLabelOneManagerID() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.reported_to_level_one_keka_id;
  }
  get CurrentUserReportedToLabelTwoManagerID() {
    const res = localStorage.getItem('token');
    const loginTokenDecodedData: any = jwtDecode(res);
    return loginTokenDecodedData.reported_to_level_two_keka_id;
  }
}
