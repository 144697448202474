import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';


export class ItemNode {
  id: number;
  due_date: string;
  description: string;
  todo_image: null;
  priority: string;
  assigned_by_user: number;
  task_status: string;
  text_note: string;
  assigned_to_user: number;
  have_parent: boolean;
  parent_id: number;
  parent_has_child: boolean;
  parent_node_details: any;
  assigned_to_user_details: any;
  assigned_by_user_details: any;


}
/** Flat node with expandable and level information */
export class ChildNode {
  id: number;
  due_date: string;
  description: string;
  todo_image: null;
  priority: string;
  assigned_by_user: number;
  task_status: string;
  text_note: string;
  assigned_to_user: number;
  have_parent: boolean;
  parent_id: number;
  parent_has_child: boolean;
  parent_node_details: any;
  assigned_to_user_details: any;
  assigned_by_user_details: any;

}


/*Interface that extends IParent to keep also the children in the parent */
export interface IParentWithChildren extends ItemNode {
  children: ItemNode[]
}


@Injectable({
  providedIn: 'root'
})
export class DynamicTreeDatabaseService {

  protected unsubscribe = new Subject();

  apiUrl = environment.BASE_URL;


  constructor(
    private http: HttpClient
  ) {

  }

  /** Initial data from database */
  getParent(page = 1, result, department_id = '') {
    return this.http.get<ItemNode[]>(

      // this.apiUrl + 'category/subcategory/api/'
      `${this.apiUrl}category/subcategory/api/?page=${page}&search=${result.search}&department=${department_id}`

    );
    //     http://192.168.143.237:8000/api/to_do/parent-todo/  
  }



  // getCategorySearch(page = 1, result, department_id = '') {
  //   if (result.search == undefined) {
  //     result.search = '';
  //   }
  //   return this.http.get<Category>(
  //     //'http://192.168.143.237:8001/maintenance/tickets/api/'
  //     // `${this.endpoint}service/maintenance/api`
  //     `${this.endpoint}category/subcategory/api/?page=${page}&search=${result.search}&department=${department_id}`
  //   );
  // }

  getChilds(id) {
    return this.http.get(this.apiUrl + 'subcategory/api/?category=' + id);

  }


}
