// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-area {
  margin-bottom: 20px;
}

.mid-area {
  margin-top: 30px;
}

.bottom-area {
  margin-top: 30px;
}

.equip-submit {
  position: absolute;
  bottom: 13px;
  background: #fff !important;
  width: 100%;
  right: 40px;
  margin-top: 15px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVxdWlwLWNyZWF0ZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1CQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtBQUNGOztBQUVBO0VBQ0UsZ0JBQUE7QUFDRjs7QUFDQTtFQUNFLGtCQUFBO0VBQ0EsWUFBQTtFQUNBLDJCQUFBO0VBQ0EsV0FBQTtFQUNBLFdBQUE7RUFDQSxnQkFBQTtBQUVGIiwiZmlsZSI6ImVxdWlwLWNyZWF0ZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5pbnB1dC1hcmVhIHtcclxuICBtYXJnaW4tYm90dG9tOiAyMHB4O1xyXG59XHJcblxyXG4ubWlkLWFyZWEge1xyXG4gIG1hcmdpbi10b3A6IDMwcHg7XHJcbn1cclxuXHJcbi5ib3R0b20tYXJlYSB7XHJcbiAgbWFyZ2luLXRvcDogMzBweDtcclxufVxyXG4uZXF1aXAtc3VibWl0IHtcclxuICBwb3NpdGlvbjogYWJzb2x1dGU7XHJcbiAgYm90dG9tOiAxM3B4O1xyXG4gIGJhY2tncm91bmQ6ICNmZmYgIWltcG9ydGFudDtcclxuICB3aWR0aDogMTAwJTtcclxuICByaWdodDogNDBweDtcclxuICBtYXJnaW4tdG9wOiAxNXB4O1xyXG59XHJcbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/equipments/equip-create/equip-create.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,2BAAA;EACA,WAAA;EACA,WAAA;EACA,gBAAA;AAEF;AACA,g0BAAg0B","sourcesContent":[".input-area {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n.mid-area {\r\n  margin-top: 30px;\r\n}\r\n\r\n.bottom-area {\r\n  margin-top: 30px;\r\n}\r\n.equip-submit {\r\n  position: absolute;\r\n  bottom: 13px;\r\n  background: #fff !important;\r\n  width: 100%;\r\n  right: 40px;\r\n  margin-top: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
