import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DepartmentService } from 'src/app/service/department.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { CategoryService } from 'src/app/service/category.service';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
  styleUrls: ['./category-create.component.scss'],
})
export class CategoryCreateComponent implements OnInit {

  /*intalise form */
  createCategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required,]),
    department: new FormControl(null, [Validators.required,]),
    // maintenance_department:new FormControl([], [Validators.required,]),
  })

  category_id: any;
  category_details: any;
  errormsg: any;
  departments = [];
  selectedDepartment: any;
  errormsg_re_serial_number: string = '';
  mainteneceDepartmentCount: any;
  page: number = 1;
  maintenance_departments = [];
  selectedCategorys: any;

  constructor(
    public dialogRef: MatDialogRef<CategoryCreateComponent>,
    private maintenanceService: MaintenanceService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private categoryService: CategoryService,
    //  private userService: UserService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }


  ngOnInit() {
    this.getDepartmentsList();
    //  this.getMaintenanceDepartmentsList();
    if (this.data) {
      //this.getTaskDetails();
      //  console.log(this.data.Category_data);
      this.category_id = this.data.category_data.id;

      this.getCategoryDetails();

    }
  }



  /* Func to get list of departments */
  getDepartmentsList() {
    this.departmentService.getDepartments().subscribe((res: any) => {
      this.departments = res.results;
    },
      (error) => {
        //   console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Deparment list');
      });
  }

  format_date(date) {
    const fdate = date.split("-");
    const finaldate = `${fdate[2]}-${fdate[1]}-${fdate[0]}`;
    return finaldate.toString();
  }
  /*func to get task details and patch values to form */
  getCategoryDetails() {
    /* subscribe create ticket api*/
    this.categoryService.getCategoryDetail(this.category_id).subscribe((res) => {
      this.category_details = res;

      const a = [];


      this.createCategoryForm.patchValue({
        name: this.category_details.name,
        department: this.category_details.department

      });

      // this.selectedDepartment.id=this.category_details.department

    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('unable to get Category details');
      }
    )
    // console.log(this.category_details);


  }




  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {

    // if(this.createCategoryForm.value.name!=this.createCategoryForm.value.name){
    // this.errormsg_re_serial_number='mismatch in serial number';
    //}
    //console.log(this.createCategoryForm.value)

    //if(this.createCategoryForm.valid && this.errormsg_re_serial_number=='') 

    if (this.category_id) {
      this.categoryService.updateCategory(this.category_id, this.createCategoryForm.value).subscribe((res) => {
        /* if successfully submitted display msg to Category in toast then close modal*/
        //console.log("Category_id", res.id);
        this.closeDialog();
        this.toastPopupService.openToast('Category Created Successfully');   //display success mesg


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Category');
        }
      )
    } else {
      /* subscribe create ticket api*/
      this.categoryService.addCategory(this.createCategoryForm.value).subscribe((res) => {
        /* if successfully submitted display msg to Category in toast then close modal*/
        // console.log("Category_id", res.id);
        this.closeDialog();
        this.toastPopupService.openToast('Category Created Successfully');   //display success mesg


      },
        (error) => {
          //  console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Category');
        }
      )
    }



  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

}
