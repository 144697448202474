import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexGrid, ApexStroke, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  annotations: ApexAnnotations;
  chart: ApexChart;
  xaxis: ApexXAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  labels: string[];
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-ticket-count-as-per-user',
  templateUrl: './ticket-count-as-per-user.component.html',
  styleUrls: ['./ticket-count-as-per-user.component.scss'],
})
export class TicketCountAsPerUserComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  constructor() {
    this.chartOptions = {
      series: [
        {
          name: 'Ticket',
          data: [10,20,30,5],
        },
      ],
      chart: {
        type: 'bar',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        width: 3,
      },
      grid: {
        padding: {
          right: 30,
          left: 20,
        },
      },
      title: {
        text: 'Department Ticket counts',
        align: 'left',
      },
      labels: ["NU South","NU West","NU North","NU East"],
      xaxis: {
        type: 'category',
      },
    };
   }

  ngOnInit() {}

}
