import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Department } from '../interface/Department';
import { Groups } from '../interface/Groups';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';
import { TechnicianUser } from '../interface/TechnicianUser';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { User } from '../interface/User';

@Injectable({
  providedIn: 'root'
})
export class ChartService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getChartData(role) {
    if (role == 'client' || role == 'Client') {
      return this.http.get<any>(
        `${this.endpoint}api/mytickets/summary2/`,
      )

    }
    else {
      return this.http.get<any>(
        `${this.endpoint}api/tickets/summary1/`,
      )
    }
  }

  getticketstatusData(role) {
    if (role == 'client') {
      return this.http.get<any>(
        `${this.endpoint}api/mytickets/summary1/`,
      )

    } else {
      return this.http.get<any>(
        `${this.endpoint}api/tickets/summary5/`,
      )
    }
  }

  getticketassignedData() {
    return this.http.get<any>(
      `${this.endpoint}api/tickets/summary3/`,
    )
  }

  getticketsummary() {
    return this.http.get<any>(
      `${this.endpoint}api/tickets/summary4/`,
    )
  }

  getticketAssignedunassignData() {
    return this.http.get<any>(
      `${this.endpoint}api/tickets/summary5/`,
    )
  }

}
