import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOtpInputComponent } from 'ng-otp-input';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-otp-dialog',
  templateUrl: './otp-dialog.component.html',
  styleUrls: ['./otp-dialog.component.scss'],
})
export class OtpDialogComponent implements OnInit {
  errormsg: any;
  error: string;
  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  ticketId: number;
  statusCode: number;
  otp: string;
  @ViewChild(NgOtpInputComponent, { static: false }) ngOtpInput: NgOtpInputComponent;

  constructor(public dialogRef: MatDialogRef<OtpDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private toastPopupService: ToastPopupService,
    private maintenanceService: MaintenanceService) {
    this.ticketId = this.data?.ticketId;
    this.statusCode = this.data?.statusCode;
  }

  ngOnInit() { }

  onOtpChange(otp) {
    this.otp = otp;
  }

  onSubmit() {
    const payload = {
      "entered_happy_code": this.otp
    }
    this.maintenanceService.closeTicket(this.ticketId, payload).subscribe((res) => {
      this.closeDialogRefresh();
      this.toastPopupService.openToast('Ticket Closed Successfully');
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Ticket not Closed');
      });
  }

  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  closeDialogRefresh() {
    this.dialogRef.close('refresh');
  }

  resendOtp() {
    this.maintenanceService.resndOtp(this.ticketId).subscribe((res) => {
      this.toastPopupService.openToast('OTP re-sent Successfully');
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('OTP not re-sent');
      });
  }

}
