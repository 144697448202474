import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { GroupCreateComponent } from '../../groups/group-create/group-create.component';
import { LoginService } from 'src/app/service/login.service';
import { CategoryService } from 'src/app/service/category.service';
import { CustomRoleService } from 'src/app/service/customrole.service';


@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss'],
})
export class MyprofileComponent implements OnInit {

  uploadedImage: File;

  /*  roleTypes */
  selectedroleType: any = '';
  roleTypes = [

  ];


  selectedLevel: any;
  levelTypes = [
    { id: 1, name: 'L1', value: 'Level 1' },
    { id: 2, name: 'L2', value: 'Level 2' },
    { id: 2, name: 'L3', value: 'Level 3' },
  ]

  displayDepartment: number = 1;
  /*  roleTypes */
  selectedGroupType: any;
  groupTypes = [

  ];

  /*intalise form */
  createProfileForm = new FormGroup({
    username: new FormControl('', [Validators.required,]),
    email: new FormControl('', [Validators.required,]),
    phone: new FormControl('',),
    //  password: new FormControl('', [Validators.required,]),
    first_name: new FormControl('', [Validators.required,]),
    last_name: new FormControl('',),
    gender: new FormControl('',),
    role: new FormControl('',),
    department: new FormControl('',),
    level: new FormControl('',),

  })

  user_id: any;
  errormsg: any;
  count: any;
  page: any = 1;
  LoggedInrole: any;
  LoggedUsername: any;
  logged_user_id: any;
  maintenance_departments = [];
  mainteneceDepartmentCount: any;
  categorys: any;
  categoryCount: any;

  roleTypesCount: any;
  departmentCount: any;
  departments: any;
  DepartmentPage: number = 1;
  userData: any;
  constructor(
    private maintenanceService: MaintenanceService,
    private customRoleService: CustomRoleService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private userService: UserService,
    public loginService: LoginService,
  ) {

    this.LoggedUsername = this.loginService.getUsername();
    this.logged_user_id = this.loginService.getUserId();
  }


  ngOnInit() {
    this.getRolesList();
    this.getDepartmentsList();
    /*if task deatails alredy exists get task deatails */
    if (this.logged_user_id) {
      this.user_id = this.logged_user_id;
      this.createProfileForm.get('username')?.disable();
      this.createProfileForm.get('email')?.disable();
      this.createProfileForm.get('role')?.disable();
      this.createProfileForm.get('department')?.disable();
      this.createProfileForm.get('level')?.disable();
      this.createProfileForm.get('check_all_permission')?.disable();
      this.createProfileForm.get('has_mail_permission')?.disable();
      this.createProfileForm.get('has_job_assign_permission')?.disable();
      this.createProfileForm.get('has_ticket_create_permission')?.disable();
      this.createProfileForm.get('has_approval_permission')?.disable();
      this.createProfileForm.get('has_status_update_permission')?.disable();
      this.getUserDetails();
    }

  }

  /* Func to get list of departments */
  getDepartmentsList() {
    {
      this.departmentService.getMainDepartments(this.DepartmentPage).subscribe((res: any) => {
        this.departmentCount = res.count;
        this.departments = res.results;
      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get Department list');
        });
    }
  }

  setRole(e) {
    let name = this.getRoleName(e);
    // console.log(name,"name")
    if (name == 'user' || name == 'User' || name == 'client' || name == 'Client'
      || name == 'helpdesk'
      || name == 'Helpdesk') {
      this.displayDepartment = 0;
    } else {
      this.displayDepartment = 1;
    }


  }


  getRoleName(id) {
    let role = this.roleTypes.filter((x: any) => { return x.id == id });
    return role[0].name;
  }



  /*func to get task details and patch values to form */
  getUserDetails() {
    this.userService.getUserData(this.user_id).subscribe((res) => {
      if (res.role.length == 1) {
        let role_id = res.role;
        this.setRole(role_id);
      }

      // console.log(res);
      this.userData = res;
      this.createProfileForm.patchValue({
        username: res.username,
        email: res.email,
        first_name: res.first_name,
        last_name: res.last_name,
        gender: res.gender,
        role: res.role,
        level: res.level,
        phone: res.phone,
        department: res.department,

      });
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get user details');

      }
    )


  }

  getRolesList() {

    this.customRoleService.getCustomRoles().subscribe((res: any) => {
      //   this.roleTypes = res.results;
      this.roleTypes = [
        ...new Map(
          this.roleTypes
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];
      this.roleTypesCount = res.count;
      this.onScrollRoleTypes();
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable');
      });

  }


  onScrollRoleTypes() {
    // console.log("hello")
    if (this.roleTypesCount != this.roleTypes.length) {

      this.page++;

      this.getRolesList();
    }
  }
  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    this.createProfileForm.get('role')?.enable();
    if (this.user_id) {
      /* subscribe create ticket api*/
      this.userService.updateUser(this.logged_user_id, this.createProfileForm.value).subscribe((res) => {
        /* if successfully submitted display msg to user in toast then close modal*/
        this.createProfileForm.get('role')?.disable();
        /* if image available add below to payload */
        if (this.uploadedImage != undefined) {
          this.submitImageValue(res.id);
        }
        else {
          // this.closeDialog();    //close modal
          this.toastPopupService.openToast('My Profile Updated Successfully');   //display success mesg

          // //this._document.defaultView.location.reload();
        }

      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Update My Profile');

          //this._document.defaultView.location.reload();
        }
      )
    }

    else {
      {

        /* subscribe create ticket api*/
        this.userService.addUser(this.createProfileForm.value).subscribe((res) => {
          /* if successfully submitted display msg to user in toast then close modal*/
          // console.log("user_id", res.id);
          /* if image available add below to payload */
          if (this.uploadedImage != undefined) {
            this.submitImageValue(res.id);
          }
          else {
            // this.closeDialog();    //close modal
            this.toastPopupService.openToast('My Profile Updated Successfully');   //display success mesg

            // //this._document.defaultView.location.reload();
          }

        },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to Update My Profile');

            //    //this._document.defaultView.location.reload();    
          }
        )
      }
    }
  }



  submitImageValue(id) {
    /* to send payload in formData (bez it has image field)*/
    const imageFormData = new FormData();
    // console.log(id);

    /* if image available add below to payload */
    if (this.uploadedImage != undefined) {
      imageFormData.append('image', this.uploadedImage);
    }

    /* subscribe create ticket api*/
    this.maintenanceService.addticketImage(id, imageFormData).subscribe((res) => {
      /* if successfully submitted display msg to user in toast then close modal*/

      // this.closeDialog();

    },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to upload image');
      }
    )

  }








  /* Function called on choosing a pic */
  onImageUpload(event) {

    let files = event.target.files;
    //check file is valid
    if (!this.validateFile(files[0].name)) {
      // console.log('Selected file format is not supported');
      this.errormsg = 'Selected file format is not supported. Choose ".jpg" format only.';
      return false;
    } else {
      this.uploadedImage = event.target.files[0];
      this.errormsg = '';
    }
  }


  //check file is valid
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      return true;
    }
    else {
      return false;
    }
  }



  /* Func to get list of equipments */
  getGroupsList() {
    this.userService.getGroups(this.page).subscribe((res: any) => {
      //   this.groupTypes = res.results;
      this.groupTypes = [
        ...new Map(
          this.groupTypes
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];
      this.count = res.count;
      this.onScroll();
    },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get Group list');
      });
  }




  onScroll() {
    // console.log("hello")
    if (this.count != this.groupTypes.length) {

      this.page++;

      this.getGroupsList();
    }
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.groupTypes.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getGroupsList();
        // console.log('Inprogress');
      }
    }, 500);
  }


}



