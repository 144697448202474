import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { CityService } from 'src/app/service/city.service';
import { CityCreateComponent } from '../city-create/city-create.component';

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.scss'],
})
export class CityListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  dialogRef: MatDialogRef<any>;
  errormsg: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  cities: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;
  searchCityForm = new FormGroup({
    search: new FormControl('',),
  })
  filterCityForm = new FormGroup({
    start_date: new FormControl('',),
    end_date: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;
  LoggedUsername: any;

  constructor(
    private cityService: CityService,
    public dialog: MatDialog, private toastPopupService: ToastPopupService
  ) { }

  ngOnInit() {
    this.getCityList();
  }
  /*func to get ticket list */
  getCityList() {
    if (this.refresh == true) {
      this.cities = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    {
      this.cityService.getCitySearch(this.page, this.searchCityForm.value).subscribe((res: any) => {
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.cities = [
          ...new Map(
            this.cities
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Get State');
        }
      )
    };
  }

  refreshCity() {
    this.search = false;
    this.refresh = true;
    this.searchCityForm.patchValue({
      search: ''
    });
    this.getCityList();
  }

  addCityDialog() {
    this.dialogRef = this.dialog.open(CityCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }

  updateCityDialog(res) {
    this.dialogRef = this.dialog.open(CityCreateComponent, {
      width: '80%',
      data: res
    });
    this.reloadList();
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'refresh') {
        this.refresh = true;
        this.getCityList();
      }

    });
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();
      if (this.cities.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getCityList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getCityList();
  }
}
