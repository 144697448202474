import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FloorService } from 'src/app/service/floor.service';
import { RoomService } from 'src/app/service/room.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-room-create',
  templateUrl: './room-create.component.html',
  styleUrls: ['./room-create.component.scss'],
})
export class RoomCreateComponent implements OnInit {

  name: any;
  /*intalise form */
  createRoomForm = new FormGroup({
    name: new FormControl('',[Validators.required]),
    floor: new FormControl('',[Validators.required]),
  })
  room_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  floors: any = [];
  floorsCount: number;

  constructor(
    public dialogRef: MatDialogRef<RoomCreateComponent>,
    private roomService: RoomService,
    private floorService: FloorService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data?.id) {
      this.room_id = this.data.id;
      this.getRoomDetails();
    }
    this.getFloorList();
  }

  getRoomDetails() {
    this.roomService.getRoomData(this.room_id).subscribe((res: any) => {
      this.createRoomForm.patchValue({
        name: res.name,
        floor: res?.floor.id   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Room Details');
      }
    )

  }
  onSubmit() {
    if (this.room_id) {
      this.roomService.updateRoom(this.room_id, this.createRoomForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Room update Successfully');   //display success mesg
      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update Room');
        }
      )
    }
    else {
      this.roomService.addRoom(this.createRoomForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Room Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Room');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  getFloorList() {
    this.floorService.getFloors().subscribe(
      (res: any) => {
        this.floors = [
          ...new Map(
            this.floors.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.floorsCount = res.count;
        this.onScrollFloors();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Floor List');
      }
    );
  }

  onScrollFloors() {
    // console.log('hello');
    if (this.floorsCount != this.floors.length) {
      this.page++;

      this.getFloorList();
    }
  }
}
