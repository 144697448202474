import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CountryService } from 'src/app/service/country.service';
import { StateService } from 'src/app/service/state.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-state-create',
  templateUrl: './state-create.component.html',
  styleUrls: ['./state-create.component.scss'],
})
export class StateCreateComponent implements OnInit {
  name: any;
  /*intalise form */
  createStateForm = new FormGroup({
    name: new FormControl('',[Validators.required]),
    country: new FormControl('',[Validators.required]),
  })
  state_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  countries: any = [];
  countriesCount: number;

  constructor(
    public dialogRef: MatDialogRef<StateCreateComponent>,
    private countryService: CountryService,
    private stateService: StateService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data?.id) {
      this.state_id = this.data.id;
      this.getStateDetails();
    }
    this.getCountryList();
  }

  getStateDetails() {
    this.stateService.getStateData(this.state_id).subscribe((res: any) => {
      this.createStateForm.patchValue({
        name: res.name,
        country: res?.country.id   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get State Details');
      }
    )

  }
  onSubmit() {
    if (this.state_id) {
      this.stateService.updateState(this.state_id, this.createStateForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('State update Successfully');   //display success mesg
        // }


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update state');
        }
      )
    }
    else {
      this.stateService.addState(this.createStateForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('State Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create State');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  getCountryList() {
    this.countryService.getCountries().subscribe(
      (res: any) => {
        //this.roleTypes = res.results;
        this.countries = [
          ...new Map(
            this.countries.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.countriesCount = res.count;
        this.onScrollCountries();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Countries List');
      }
    );
  }

  onScrollCountries() {
    // console.log('hello');
    if (this.countriesCount != this.countries.length) {
      this.page++;

      this.getCountryList();
    }
  }
}
