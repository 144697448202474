import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { RegionService } from 'src/app/service/region.service';

@Component({
  selector: 'app-region-create',
  templateUrl: './region-create.component.html',
  styleUrls: ['./region-create.component.scss'],
})
export class RegionCreateComponent implements OnInit {

  name: any;
  /*intalise form */
  createRegionForm = new FormGroup({
    name: new FormControl('',),
  })
  region_id: any;
  errormsg: any;
  page: number = 1;
  count: any;

  constructor(
    public dialogRef: MatDialogRef<RegionCreateComponent>,
    private regionService: RegionService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {

    if (this.data.id) {
      this.region_id = this.data.id;
      this.getRegionDetails();
    }
  }

  getRegionDetails() {
    this.regionService.getRegionData(this.region_id).subscribe((res) => {
      this.createRegionForm.patchValue({
        name: res.name   
      });
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Region Details');
      }
    )

  }
  onSubmit() {
    if (this.region_id) {
      this.regionService.updateRegion(this.region_id, this.createRegionForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Region update Successfully');   //display success mesg
        // }


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to update region');
        }
      )
    }
    else {
      this.regionService.addRegion(this.createRegionForm.value).subscribe((res) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Region Created Successfully');   //display success mesg
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Region');
        }
      )
    }
  }
  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

}
