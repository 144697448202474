import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/service/report.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-report-generate',
  templateUrl: './report-generate.component.html',
  styleUrls: ['./report-generate.component.scss'],
})

export class ReportGenerateComponent implements OnInit {

  errormsg: any;
  reportGenerateForm = new FormGroup({
    start_date: new FormControl(null, [Validators.required,]),
    end_date: new FormControl(null, [Validators.required,]),
    selected_fields: new FormControl([]),
  })
  fieldValues = [];
  mainteneceDepartmentCount: any;
  maintenecepage = 1;
  maintenance_departments: any;
  displayFilterOption: boolean = false;
  maxDate = new Date();

  constructor(private reportService: ReportService,
    private toastPopupService: ToastPopupService) { }

  ngOnInit() {
    this.getFieldValues();
  }

  getFieldValues() {
    {
      this.reportService.getFieldValues().subscribe((res: any) => {
        this.fieldValues = res.selected_fields;
      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Report Successfully');
        });
    }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  onSubmit() {
    let payload = {
      start_date: this.convert(this.reportGenerateForm.value.start_date),
      end_date: this.convert(this.reportGenerateForm.value.end_date),
      selected_fields: this.reportGenerateForm.value.selected_fields
    }

    {
      this.reportService.getTechnicianReportData(payload).subscribe((res: any) => {
        const path = res.file_path;
        window.location.href = this.reportService.downloadFile(path);
        this.reportGenerateForm.patchValue({
          selected_fields: [],
        });

        this.displayFilterOption = false;
      },
        (error) => {
          // console.log(error);
          this.toastPopupService.openToast('Unable to get reports');
        });
    }
  }
}
