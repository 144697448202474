import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { UserService } from 'src/app/service/users.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { RoasterService } from 'src/app/service/roaster.service';

@Component({
  selector: 'app-roaster-create',
  templateUrl: './roaster-create.component.html',
  styleUrls: ['./roaster-create.component.scss'],
})
export class RoasterCreateComponent implements OnInit {
  /* Users */
  selectedUser: number;
  users = [];

  /*  shiftTypes */
  selectedShiftType: any;
  ShiftTypes = [
    { id: 1, name: 'Morning', value: 'Morning' },
    { id: 2, name: 'Afternoon', value: 'Afternoon' },
    { id: 3, name: 'Night', value: 'Night' },
    { id: 3, name: 'Off', value: 'Off' },
  ];

  /*intalise form */
  createRoasterForm = new FormGroup({
    shift: new FormControl(''),
    user_list: new FormControl([]),
    start_date: new FormControl(''),
    end_date: new FormControl(''),
    date: new FormControl(''),
  });
  roaster_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  multipleUser: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<RoasterCreateComponent>,
    private roasterService: RoasterService,
    private userService: UserService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.getUsersList();

    // console.log(this.data);
    if (this.data.id) {
      this.roaster_id = this.data.id;
      this.getRoasterDeatails();
    }
  }

  getRoasterDeatails() {
    this.roasterService.getRoasterData(this.roaster_id).subscribe(
      (res) => {
        this.createRoasterForm.get('user_list')?.disable();
        this.createRoasterForm.patchValue({
          shift: res.shift,
          date: res.date,
          user_list: [res.user],
        });
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('unable to get Roster Details');
      }
    );
  }
  /* Func onsubmitting form - to create a roaster*/
  onSubmit() {
    if (this.roaster_id) {
      /**update roaster */
      this.roasterService
        .updateRoaster(this.roaster_id, this.createRoasterForm.value)
        .subscribe(
          (res) => {
            this.closeDialog(); //close modal
            this.toastPopupService.openToast('Roaster update Successfully'); //display success mesg
          },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to update Roaster');
          }
        );
    } else {

      /**create roaster */
      this.roasterService.createRoaster(this.createRoasterForm.value).subscribe(
        (res) => {
          this.closeDialog(); //close modal
          this.toastPopupService.openToast('Roaster Created Successfully'); //display success mesg
        },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Roaster');
        }
      );
    }
  }

  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  /* Func to get list of users */
  getUsersList() {
    this.userService.getusers(this.page).subscribe(
      (res: any) => {
        // this.users = res.results;
        this.users = [
          ...new Map(
            this.users.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = res.count;

        this.loadmore();
      },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get user list');
      }
    );
  }

  /**load more users/technicians and filter the invalid users, to whom assign roaster is not possible */
  loadmore() {
    // console.log("hello")
    if (this.count != this.users.length) {
      this.page++;
      this.getUsersList();
    } else {
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'client';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'Client';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'manager';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'Manager';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'helpdesk';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != 'Helpdesk';
      });
      this.users = this.users.filter((x: any) => {
        if (x.role.length == 1) return x.role[0].name != null;
      });
      // console.log("hello",this.users);
    }
  }
}
