import { Component, Inject, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChatbotService } from 'src/app/service/chatbot.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-ticket-template-view',
  templateUrl: './ticket-template-view.component.html',
  styleUrls: ['./ticket-template-view.component.scss'],
})
export class TicketTemplateViewComponent implements OnInit {
  question_count: any;
  questions: any;
  todo: any;

  Form = new FormGroup({
    template: new FormControl('', [Validators.required,]),
  })

  templateId: any;
  template_id: any;
  viewmode: number = 0;
  template_name: any;
  constructor(private chatbotService: ChatbotService,
    public dialogRef: MatDialogRef<TicketTemplateViewComponent>,
    //refrence to modal
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    if (this.data) {
      this.template_id = this.data.id;
      // console.log(this.data);
      this.template_name = this.data.Data.name;
      //this.viewmode = 1;
      this.getQuestionsList();
    } else {
      this.getModalQuestionsList();
    }
  }

  public getQuestionsList() {
    this.chatbotService.getQuestionsList(this.template_id).subscribe((res: any) => {
      this.question_count = res.count;
      this.todo = res.results;



      // console.log(this.question_count, 'actual count');
      // this.startFlow();
    });
  }

  public getModalQuestionsList() {
    this.chatbotService.getModalQuestionsList(
      //this.template_id
    ).subscribe((res: any) => {
      this.question_count = res.count;
      this.questions = res.results;
      // console.log(res); 
      this.todo = res.results;
      // console.log(this.question_count,'actual count'); 
      // this.startFlow();
    });
  }


  // todo = ['Get to work', 'Pick up groceries', 'Go home', 'Fall asleep'];

  done = [];

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }





  createTemplate() {
    // console.log(this.done);
    // console.log(this.todo);

    const payload = {
      template: this.Form.value.template,
      questions: this.done
    }

    this.chatbotService.createTemplate(payload
      //this.template_id
    ).subscribe((res: any) => {

      this.closeDialog();
      // console.log(res); 

      // console.log(this.question_count,'actual count'); 
      // this.startFlow();
    });

  }


  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

}

