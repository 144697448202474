import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-ticketcreate',
  templateUrl: './ticketcreate.component.html',
  styleUrls: ['./ticketcreate.component.scss'],
})
export class TicketcreateComponent implements OnInit {
  uploadedImage: File;

  /* equipments */
  selectedEquipment: any;
  equipments = [];

  /* departments */
  selectedDepartment: number;
  departments = [];

  /*  ticketTypes */
  selectedTicketType: any;
  ticketTypes = [
    { id: 1, name: 'Incident', value: 'Incident' },
    { id: 2, name: 'Request', value: 'Request' },
  ];

  /* priorties */
  selectedPriority: string;
  priorties = [
    { id: 1, name: 'Low' },
    { id: 2, name: 'Medium' },
    { id: 3, name: 'High' },
    { id: 4, name: 'Critical' },
  ];

  /*intalise form */
  createTicketForm = new FormGroup({
    ticket_number: new FormControl(''),
    department: new FormControl(''),
    // equipment: new FormControl('',),
    //  ticket_notes: new FormControl('',),
    ticket_description: new FormControl(''),
    // priority: new FormControl('',),
    //  maintenance_department: new FormControl('',)
  });

  ticket_id: any;
  errormsg: any;
  filter: boolean;
  results_per_page: any;
  count: any;
  page: any = 1;
  imageerrormsg: string;
  selectedMaintenance_departments: any;
  maintenance_departments = [];
  mainteneceDepartmentCount: any;

  constructor(
    public dialogRef: MatDialogRef<TicketcreateComponent>,
    private maintenanceService: MaintenanceService,
    private equipmentService: EquipmentService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getDepartmentsList();
    //this.getEquipmentsList();
    // this.getMaintenanceDepartmentsList();
    // console.log(this.selectedDepartment)
    if (this.data) {
      this.ticket_id = this.data.ticket_id;
      this.getticketDeatils();
    }
    // console.log(this.data.filter);
    if (this.data.filter) {
      this.filter = true;
      // console.log(this.data.filterData);
      /*
            this.createTicketForm.patchValue({
              ticket_type: this.data.filterData.ticket_type,
          department: this.data.filterData.department,
          equipment: this.data.filterData.equipment,
          ticket_notes: this.data.filterData.ticket_notes,
          ticket_description: this.data.filterData.ticket_description,
          priority: this.data.filterData.priority
               
             });
             */
    } else {
      this.filter = false;
    }
  }

  getticketDeatils() {
    /* subscribe  ticket api*/
    this.maintenanceService.getMyticketDetails(this.ticket_id).subscribe(
      (res) => {
        /* if successfully submitted display msg to user in toast then close modal*/
        // console.log("ticket_id", res);
        this.createTicketForm.patchValue({
          ticket_number: res.ticket_number,
          department: res.department,
          //   maintenance_department:res.maintenance_department,

          // equipment: res.equipment,
          //   ticket_notes: res.ticket_notes,
          ticket_description: res.ticket_description,
          //  priority: res.priority
        });
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get ticketdetails');
      }
    );
  }

  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    if (this.filter) {
      this.closeDialogFilterForm();
    } else if (this.ticket_id) {
      /* subscribe create ticket api*/
      this.maintenanceService
        .updateTicketDetails(this.ticket_id, this.createTicketForm.value)
        .subscribe(
          (res) => {
            /* if successfully submitted display msg to user in toast then close modal*/
            // console.log("ticket_id", res);
            //  this.updateStatus(this.ticket_id,);
            /* if image available add below to payload */
            if (this.uploadedImage != undefined) {
              this.submitImageValue(res.id);
            } else {
              this.closeDialog(); //close modal
              this.toastPopupService.openToast('ticket Updated Successfully'); //display success mesg
            }
          },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to Update Ticket');
          }
        );
    } else {
      /* subscribe create ticket api*/
      this.maintenanceService.addticket(this.createTicketForm.value).subscribe(
        (res) => {
          /* if successfully submitted display msg to user in toast then close modal*/
          // console.log("ticket_id", res.id);

          /* if image available add below to payload */
          if (this.uploadedImage != undefined) {
            this.submitImageValue(res.id);
          } else {
            this.closeDialog(); //close modal
            this.toastPopupService.openToast('Task Created Successfully'); //display success mesg
          }
        },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create a Task');
        }
      );
    }
  }

  updateStatus(id) {
    const payload = {
      edit_requested: false,
    };
    this.maintenanceService.requestEditTicket(id, payload).subscribe(
      (res) => {
        // console.log(res);
      },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable');
      }
    );
  }

  submitImageValue(id) {
    /* to send payload in formData (bez it has image field)*/
    const imageFormData = new FormData();
    // console.log(id);

    /* if image available add below to payload */
    if (this.uploadedImage != undefined) {
      imageFormData.append('image', this.uploadedImage);
    }

    /* subscribe create ticket api*/
    this.maintenanceService.addticketImage(id, imageFormData).subscribe(
      (res) => {
        /* if successfully submitted display msg to user in toast then close modal*/

        this.closeDialog(); //close modal
        this.toastPopupService.openToast('Task Created Successfully');
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to upload image');
      }
    );
  }

  /* Func to close modal */
  closeDialogFilterForm() {
    const result = {
      ticket_number: this.createTicketForm.value.ticket_number,
      department: this.createTicketForm.value.department,
      // equipment: this.createTicketForm.value.equipment,
      // ticket_notes: this.createTicketForm.value.ticket_notes,
      ticket_description: this.createTicketForm.value.ticket_description,
      // priority: this.createTicketForm.value.priority
    };
    this.dialogRef.close(result);
  }

  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  /* Func to get list of departments */
  getDepartmentsList() {
    this.departmentService.getDepartments().subscribe(
      (res: any) => {
        this.departments = res;
        //  this.getEquipmentsList();
        //  this.getEquipmentsList();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get Department list');
      }
    );
  }

  /* Func to get list of departments */
  getMaintenanceDepartmentsList() {
    this.departmentService.getMaintenanceDepartments(this.page).subscribe(
      (res: any) => {
        this.mainteneceDepartmentCount = res.count;
        // console.log(this.mainteneceDepartmentCount)
        // this.maintenance_departments = res.results;
        this.maintenance_departments = [
          ...new Map(
            this.maintenance_departments
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.onScroll();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast(
          'Unable to get maintain Department list'
        );
      }
    );
  }

  onScroll() {
    // console.log(this.maintenance_departments.length);
    if (this.mainteneceDepartmentCount != this.maintenance_departments.length) {
      this.page++;
      this.getMaintenanceDepartmentsList();
    }
  }
  onChangeDepartment(event) {
    // console.log(event);
    this.getEquipmentsList();
  }
  /* Func to get list of equipments */
  getEquipmentsList() {
    this.equipmentService.getEquipments().subscribe(
      (res: any) => {
        this.equipments = res.results;
        //  let depart = this.createTicketForm.value.department;
        //  this.equipments = this.equipments.filter((x: any) => { return x.department == depart; });

        /* this.results_per_page=res.results_per_page;
        //const tickets1 = res.results;
          this.count=res.count;
          this.equipments  =[
            ...new Map(
              this.equipments
                .concat(res.results)
                .map((item) => [item['id'], item])
            ).values(),
          ]; */
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get list');
      }
    );
  }

  /* Function called on choosing a pic */
  onImageUpload(event) {
    let files = event.target.files;
    //check file is valid
    if (!this.validateFile(files[0].name)) {
      // console.log('Selected file format is not supported');
      this.errormsg =
        'Selected file format is not supported. Choose ".jpg" format only.';
      return false;
    } else {
      this.uploadedImage = event.target.files[0];
      this.imageerrormsg = '';
    }
  }

  //check file is valid
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      return true;
    } else {
      return false;
    }
  }
}
