import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
// import { MatDialog } from '@angular/material/dialog';

import { LoginService } from 'src/app/service/login.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})

export class ChangePasswordComponent implements OnInit {

  /*intalise form */
  changePasswordForm = new FormGroup({
    password: new FormControl('', [Validators.required,]),
    password2: new FormControl('', [Validators.required,]),
  })

  user_id: any;
  errormsg: any;
  count: any;
  page: any = 1;
  LoggedInrole: any;
  LoggedUsername: any;
  logged_user_id: any;
  maintenance_departments = [];
  mainteneceDepartmentCount: any;
  error: string;
  // dialog: any;

  constructor(
    // public dialogRef: MatDialogRef<any>,
    private maintenanceService: MaintenanceService,
    private equipmentService: EquipmentService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private userService: UserService,
    // public dialog: MatDialog,
    public loginService: LoginService,
    // @Inject(MAT_DIALOG_DATA) public data
  ) {




  }


  ngOnInit() {

  }


  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    if (this.changePasswordForm.value.password2 != this.changePasswordForm.value.password) {
      this.error = "password and repassword not matched"
    }
    // console.log(this.changePasswordForm.value)
    else {
      /* subscribe create ticket api*/

      this.userService.updatePassword(this.changePasswordForm.value).subscribe((res) => {

        this.changePasswordForm.patchValue({
          password: '',
          password2: ''
        });
        this.error = '';
        this.errormsg = '';

        /* if successfully submitted display msg to user in toast then close modal*/
        this.toastPopupService.openToast('Updated Password');

      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Update Password');
        }
      )
    }


  }











}



