import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
@Component({
  selector: 'app-ticket-view',
  templateUrl: './ticket-view.component.html',
  styleUrls: ['./ticket-view.component.scss'],
})
export class TicketViewComponent implements OnInit {
  image_url: string;
  loginData: string;
  loginTokenDecodedData: any;
  role: string;
  res: any;
  ticket_id: any;
  myticket = 0;
  loggedInName: any;
  loggedgetUsername: any;
  errormsg: any;

  constructor(
    private loginService: LoginService,
    private maintenanceService: MaintenanceService,
    private toastPopupService: ToastPopupService,
    public dialogRef: MatDialogRef<TicketViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.ticket_id = this.data.ticketData.id;
    this.image_url = environment.IMAGE_URL;
    this.role = this.loginService.getRole();  //get loginUser Role

    this.loggedgetUsername = this.loginService.getUsername();
    // console.log(this.loggedgetUsername);
    // console.log(this.data);
    this.myticket = this.data.myticket;
    this.getTicketData();

  }


  /*func to get task details and patch values to form */
  getTicketData() {
    {
      this.maintenanceService.getTicketFullData(this.ticket_id, this.myticket
      )
        .subscribe((response) => {
          // console.log(response);

          this.res = response;
        },
          (error) => {
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to get ticket data');
          }
        )
    }


  }


  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close();
  }
}
