import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { LoginService } from 'src/app/service/login.service';
import { CategoryService } from 'src/app/service/category.service';
import { DepartmentService } from 'src/app/service/department.service';

@Component({
  selector: 'app-ticket-change-department',
  templateUrl: './ticket-change-department.component.html',
  styleUrls: ['./ticket-change-department.component.scss'],
})
export class TicketChangeDepartmentComponent implements OnInit {
  ForwardTicketForm = new FormGroup({
    category: new FormControl('', [Validators.required]),
    sub_category: new FormControl(''),
    department: new FormControl(''),
    // maintenance_category:new FormControl([], [Validators.required,]),
  });

  subcategory_id: any;
  subcategory_details: any;
  errormsg: any;
  category = [];
  selectedCategory: any;
  errormsg_re_serial_number: string = '';
  mainteneceCategoryCount: any;
  page: number = 1;
  maintenance_category = [];
  selectedCategorys: any;
  category_id: any;
  department_id: any;
  subCategories: any;
  mainteneceDepartmentCount: any;
  maintenance_departments = [];
  maintenecepage: any;
  current_department_name: any;

  constructor(
    private maintenanceService: MaintenanceService,
    private toastPopupService: ToastPopupService,
    private categoryService: CategoryService,
    private departmentService: DepartmentService,
    public dialogRef: MatDialogRef<TicketChangeDepartmentComponent>, //reference to modal
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.current_department_name= this.data.department;
    // this.getCategoriesList();
    this.getMaintenanceDepartmentsList();
  }

  /* Func to get list of category */
  getCategoriesList() {
    this.categoryService.getCategory(this.page, this.ForwardTicketForm.value.department).subscribe(
      (res: any) => {
        this.category = res.results;
        // console.log(this.category);
        // this.selectList3= this.category.filter((x: any) => { return x.department == this.selected_1.id; });
        // this.getSubCategories();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get Category list');
      }
    );
  }

  onchange() {
    // console.log(e)
    this.getSubCategories();
  }

  /*func to get departmentlist */
  getSubCategories() {
    // this.subCategories = this.subCategories;
    this.categoryService
      .getSubCategories(
        this.page,
        this.ForwardTicketForm.value.category,
        this.data.case_type
      )
      .subscribe(
        (res: any) => {
          this.subCategories = res.results;
          // this.selectList4= this.subCategories;
          //this.selectList4 = this.subCategories.filter((x: any) => { return x.category == this.selected_3.name; })
          //  this.equipments = this.equipments.filter((x: any) => { return x.department == this.selectedDepartmentValue; })
        },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get sub category data');
        }
      );
  }

  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    {
      /* subscribe create ticket api*/
      this.maintenanceService
        .forwarddTicket(this.data.id, this.ForwardTicketForm.value)
        .subscribe(
          (res) => {
            /* if successfully submitted display msg to Category in toast then close modal*/
            // console.log("Category_id", res.id);
            this.cancelDeptRequest(this.data.id)
            this.closeDialog();
            this.toastPopupService.openToast('Successfully changed'); //display success mesg
          },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to change');
          }
        );
    }
  }

  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  ondepartmentchange() {
    this.getCategoriesList();
  }

  /* Func to get list of departments */
  getMaintenanceDepartmentsList() {
    {
      this.departmentService.getMainDepartments(this.maintenecepage).subscribe((res: any) => {
        this.mainteneceDepartmentCount = res.count;
        this.maintenance_departments = res.results;
        this.maintenance_departments= this.maintenance_departments.filter((x: any) => { return x.name != this.current_department_name; });
    
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to get departments list');
        });
    }

  }

  onScrollDepartmentList() {
    // console.log(this.maintenance_departments.length);
    if (this.mainteneceDepartmentCount != this.maintenance_departments.length) {
      this.maintenecepage++;
      this.getMaintenanceDepartmentsList();
    }
  }


  cancelDeptRequest(id){
    // this.saveCurrentPageNumber = this.page;
    // this.page = Math.floor((sno + 1) / (this.results_per_page)) + 1;
    // // console.log(this.page);
    const payload = {
      "dept_change_requested": false
    }
    this.maintenanceService.requestDepartmentChangeTicket(id, payload).subscribe((res) => {
      // console.log(res);
      //  this.page--;
     // this.refresh = true;
     // this.getticketList();
     // this.page = this.saveCurrentPageNumber;
      //this.tickets = res;
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to request');
      });
  }


}
