import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assets-layout',
  templateUrl: './assets-layout.component.html',
  styleUrls: ['./assets-layout.component.scss'],
})
export class AssetsLayoutComponent implements OnInit {
  sub_category_id: any;
  department_id: any;
  refresh: any;

  constructor() { }

  ngOnInit() {
    // this.sub_category_id=136;
  }


  refreshEquipments(e: any) {
    this.refresh = 'refresh';
    // console.log(e);
    this.sub_category_id = ''
  }

  newdepartment(id) {
    this.department_id = id;
  }

  newsubcategory(id: any) {
    //console.log(id,'p')
    this.sub_category_id = id;

  }



}
