import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-role-view',
  templateUrl: './custom-role-view.component.html',
  styleUrls: ['./custom-role-view.component.scss'],
})
export class CustomRoleViewComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
