import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Country } from '../interface/Country';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(public http: HttpClient) {
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }


  getCountries(page = 1): Observable<Country> {
    return this.http.get<Country>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/countries?page=${page}`,
    );
  }

  addCountry(postData): Observable<Country> {
    return this.http.post<Country>(
      `${this.endpoint}api/countries/create/`,
      postData
    )
  }

  updateCountry(id, postData) {
    return this.http.put<Country>(
      `${this.endpoint}api/countries/${id}/`,
      postData
    )
  }

  getCountryData(id) {
    return this.http.get<Country>(
      `${this.endpoint}api/countries/${id}/`,

    )
  }
  getCountrySearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Country>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/countries?page=${page}&search=${result.search}`
    );
  }
}
