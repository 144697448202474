import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { activityService } from 'src/app/service/activity.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { UserService } from 'src/app/service/users.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-user-activility-list',
  templateUrl: './user-activility-list.component.html',
  styleUrls: ['./user-activility-list.component.scss'],
})

export class UserActivilityListComponent implements OnInit {

  /*inialize varaibale for technician dropdown */
  selectedUser: any;
  technicians = [];
  error: any;
  count: any;
  page: number = 1;
  Activitys: any = [];
  refresh: boolean = false;

  /*intalise form */
  searchUserActivityForm = new FormGroup({
    search: new FormControl('',),
  })

  filteruserActivityForm = new FormGroup({
    user: new FormControl('',),
    created_at: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;

  constructor(
    private userService: UserService,
    private activityService: activityService,
    private toastPopupService: ToastPopupService
    //  @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.getActivityList();
    this.getUserList();
  }


  /*func to get ticket list */
getActivityList() {
    if (this.refresh == true){
      this.Activitys = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    if (this.search) {
      this.activityService.getSearchUsersActivity(this.page, this.searchUserActivityForm.value).subscribe((res: any) => {
        //console.log(res);
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.Activitys = [
          ...new Map(
            this.Activitys
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      });
    }
     else
      {
      this.activityService.getUsersActivity(this.page).subscribe((res: any) => {
        //console.log(res);
        this.count = res.count;
        this.Activitys = [
          ...new Map(
            this.Activitys
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];

      });
    }
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.Activitys.length === this.count) {
        event.target.disabled = true;
        //   console.log('Done');
      }
      else {
        this.page++;
        this.getActivityList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getActivityList();
  }

  refreshUsers() {
    // this.filter=false;
    this.search = false;
    this.refresh = true;
    this.searchUserActivityForm.patchValue({
      search: ''

    });
    this.getActivityList();
  }

  /* Func to get list of activity */
  getUserList() {
    this.userService.getusers(this.page).subscribe((res: any) => {
      // this.technicians = res.results;
      this.technicians = [
        ...new Map(
          this.technicians
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];
      this.count = res.count;

      this.onScroll();

    },
      (error) => {
        // console.log(error);
        this.error = error.error;
        this.toastPopupService.openToast('Unable to get Users List');
      });
  }


  onScroll() {
    //      console.log("hello")
    if (this.count != this.technicians.length) {
      this.page++;
      this.getUserList();
    }
  }

onFilterSubmit() {
    /* set the payload as per backedn api need*/
    this.getfilterUserList();
  }


/*func to get ticket list */
getfilterUserList() {
    if (this.refresh == true) {
      this.technicians = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    {
      this.activityService.getfilterUser(this.page, this.filteruserActivityForm.value).subscribe((res: any) => {
        //console.log(res);
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.technicians = [
          ...new Map(
            this.technicians
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      });
    }
  }



}

