import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(public http: HttpClient) { }

  inMaintenance() {
    return false;
  }

  //   inMaintenance(){
  //   return this.http.get<any>(
  //     `${this.endpoint}api/maintenance_mode/list/`,
  //   );
  // }


  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  serverDown() {
    return false;
  }

  noInternetConnection() {

  }

}