import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupCreateComponent } from '../../groups/group-create/group-create.component';
import { LoginService } from 'src/app/service/login.service';
import { CustomRoleService } from 'src/app/service/customrole.service';

@Component({
  selector: 'app-custom-role-create',
  templateUrl: './custom-role-create.component.html',
  styleUrls: ['./custom-role-create.component.scss'],
})
export class CustomRoleCreateComponent implements OnInit {

  /*  roleTypes */
  selectedroleType: any = '';
  roleTypes = [

  ];

  customrole_id: any;
  errormsg: any;
  count: any;
  page: any = 1;
  LoggedInrole: any;
  LoggedUsername: any;
  error_msg: string;
  // dialog: any;

  /*intalise form */
  createCustomRoleForm = new FormGroup({
    name: new FormControl('', [Validators.required,]),
    permissions: new FormControl('', [Validators.required,]),
  })

  roleTypesCount: number;
  role_id: any;
  permissions = [];
  permmisionTypesCount: any;
  permissionPage = 1;


  constructor(
    public dialogRef: MatDialogRef<any>,
    private maintenanceService: MaintenanceService,
    private toastPopupService: ToastPopupService,
    private userService: UserService,
    public dialog: MatDialog,
    public loginService: LoginService,
    private customRoleService: CustomRoleService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.LoggedUsername = this.loginService.getUsername();
    this.LoggedInrole = this.loginService.getUserRole();
    // console.log(this.LoggedInrole);
    if (this.data) {
      this.role_id = this.data.id;
      this.readRole(this.data.id);
    }
  }

  ngOnInit() {
    this.getRolesList();
    this.getPermissions();
  }

  getPermissions() {
    this.customRoleService.getPermissions(this.permissionPage).subscribe((res: any) => {
      this.permissions = [
        ...new Map(
          this.permissions
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];
      this.permmisionTypesCount = res.count;
      this.onPermissionScroll();
    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Permissions');
      });

  }

  onPermissionScroll() {
    // console.log("hello")
    if (this.permmisionTypesCount != this.permissions.length) {

      this.permissionPage++;

      this.getPermissions();
    }
  }


  getRolesList() {
    this.customRoleService.getCustomRoles().subscribe((res: any) => {
      this.roleTypes = [
        ...new Map(
          this.roleTypes
            .concat(res.results)
            .map((item) => [item['id'], item])
        ).values(),
      ];
      this.roleTypesCount = res.count;
      this.onScroll();
    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Custom Role List');
      });
  }


  onScroll() {
    // console.log("hello")
    if (this.roleTypesCount != this.roleTypes.length) {

      this.page++;

      this.getRolesList();
    }
  }



  readRole(id) {
    // console.log(e);
    this.customRoleService.readCustomRole(id).subscribe((res: any) => {
      // console.log(res);
      this.createCustomRoleForm.patchValue({
        name: res.name,
        permissions: res.permissions,

      });

    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to read Custo Role');
      });

  }

  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    // console.log(this.createCustomRoleForm.value);
    if (this.role_id) {
      this.customRoleService.updateCustomRole(this.role_id, this.createCustomRoleForm.value).subscribe((res: any) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Role Created Successfully');   //display success mesg

      },
        (error) => {
          // console.log(error);

          this.errormsg = error.error;
          this.toastPopupService.openToast('Role Created UnSuccessfully');
        });

    } else {

      this.customRoleService.createCustomRole(this.createCustomRoleForm.value).subscribe((res: any) => {
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Role Created Successfully');   //display success mesg

      },
        (error) => {
          // console.log(error);
          this.toastPopupService.openToast('Unable');
        });

    }

  }





  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }


}
