import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { LoginService } from 'src/app/service/login.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
})

export class UserViewComponent implements OnInit {
  image_url: string;
  role: string;
  res: any;
  permissions: any;
  errormsg: any;
  constructor(
    private toastPopupService: ToastPopupService,
    private loginService: LoginService,
    public dialogRef: MatDialogRef<UserViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.res = this.data.userData;
  }


  formatRole(role) {
    if (role == 'client') {
      return 'user'
    }
    else {
      return role;
    }
  }

  ngOnInit() {
    this.image_url = environment.IMAGE_URL;
    this.role = this.loginService.getUserRole();  //get loginUser Role

  }


  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close();
  }

}
