import { Component, OnInit, Injectable, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
//import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';
// Material import
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import {NestedTreeControl} from '@angular/cdk/tree';

import { CollectionViewer, SelectionChange, DataSource } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';

// Service import
//import { TodoallusersService } from '../../../shared/services/todoallusers.service';
//import { DynamicTreeDatabaseService  } from '../../../shared/services/dynamic-tree-database.service';

// operators
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DynamicTreeDatabaseService } from 'src/app/service/dynamic-tree-database.service';
import { TodoallusersService } from 'src/app/service/todoallusers.service';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { CategoryCreateComponent } from '../category-create/category-create.component';
import { SubcategoryCreateComponent } from '../subcategory-create/subcategory-create.component';


/** Flat node with expandable and level information */
export class ItemNode {
  id: number;
  due_date: string;
  description: string;
  todo_image: null;
  priority: string;
  assigned_by_user: number;
  task_status: string;
  text_note: string;
  // create_at: "2022-04-08T13:18:19.647457Z",
  // updated_at: "2022-04-08T13:18:19.647492Z",
  assigned_to_user: number;
  have_parent: boolean;
  parent_id: number;
  parent_has_child: boolean;
  parent_node_details: any;
  assigned_to_user_details: any;
  assigned_by_user_details: any;
}

export class TodoItemNode {
  tag: string;
  id: number;
}

export class DynamicFlatNode {
  constructor(
    public item: ItemNode,
    public level = 1,
    public expandable = false,
    public isLoading = false) {
  }
}


/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  children;
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] { return this.dataChange.value; }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }


  constructor(private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private _database: DynamicTreeDatabaseService) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    // let children;
    this._database.getChilds(node.item.id).subscribe((nodes) => {
      // const children = this._database.getChildren(node.item.id);
      const children = nodes['results'];
      const index = this.data.indexOf(node);
      if (!children || index < 0) { // If no children, or cannot find the node, no op
        return;
      }

      node.isLoading = true;
      setTimeout(() => {
        if (expand) {
          const nodes = children.map(name =>
            new DynamicFlatNode(
              name,
              node.level + 1,
              true
              // this._database.isExpandable(name.id)
            )
          );
          this.data.splice(index + 1, 0, ...nodes);
        } else {
          let count = 0;
          for (let i = index + 1; i < this.data.length
            && this.data[i].level > node.level; i++, count++) { }
          this.data.splice(index + 1, count);
        }

        // notify the change
        this.dataChange.next(this.data);
        node.isLoading = false;
      }, 100);
    });

  }
}

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
})

export class CategoryListComponent implements OnInit {

  values = [];
  // @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @Output() subCategoryEvent = new EventEmitter<number>();
  @Output() categoryEvent = new EventEmitter<number>();
  @Output() departmentEvent = new EventEmitter<number>();
  @Output() refreshEvent = new EventEmitter<string>();
  selectedItem: any;
  dialogRef: MatDialogRef<any>;
  tickets: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  category: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;
  errormsg: any;







  formData: UntypedFormGroup;
  selector: string = ".main-panel";
  //page: number = 1;
  // subscription: Subscription;
  listdata = [];
  modalScrollDistance = 2;
  modalScrollThrottle = 50;


  //for testing purpose
  width: number = 10;
  width1: number = 50;
  width2: number = 100;
  list_count: any;
  departments: any;
  departmentID: any;
  categoryID: any;
  displayAddSubCategoryIcon: boolean = false;
  displayAddCategoryIcon: boolean = true;
  clickMessage: string;

  //errormsg: any;

  /*to create space before taskname of subtasks */
  counter(i: number) {
    return new Array(i);
  }



  /*to create readmore/readless functionality at taskName field */
  read: boolean = false;
  readMore(text_length: number) {
    if (text_length > 14) {
      return false;
    }
    else {
      return true;
    }
  }





  nodesdata: Array<any>;
  //count: number;
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource;
  getLevel = (node: DynamicFlatNode) => node.level;
  isExpandable = (node: DynamicFlatNode) => node.expandable;
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
  activeNode: any;

  constructor(
    private departmentService: DepartmentService,
    private dialog: MatDialog,
    private nodeservice: TodoallusersService,
    private database: DynamicTreeDatabaseService,
    private _cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private toastPopupService: ToastPopupService,
  ) {
  }

  searchCategoryForm = new FormGroup({
    search: new FormControl('',),
  })


  /*func to open create category modal */
  addCategoryDialog() {
    this.dialogRef = this.dialog.open(CategoryCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }



  /*func to open create category modal */
  addSubCategoryDialog() {
    this.dialogRef = this.dialog.open(SubcategoryCreateComponent, {
      width: '80%',
      data: {
        category_id: this.categoryID
      }
    });
    this.reloadList();
  }




  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getParent();
      }
    });
  }

  display() {
    this.displayAddSubCategoryIcon = !this.displayAddSubCategoryIcon;
    //this.displayAddCategoryIcon=false;
  }

  displayRev() {
    this.displayAddSubCategoryIcon = false;
    //  this.displayAddCategoryIcon=true;
  }

  onSelectSubCategory(e) {
    this.selectedItem = e;
    this.subCategoryEvent.emit(e);

    // this.displayAddSubCategoryIcon=false;
  }



  ngOnInit(): void {
    this.getDepartmentsList();

    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.database);
    /*  this.nodeservice.refreshneeded.subscribe(data => {
        this.getParent();
      });
      this.getParent();
      */

    this.formData = this.formBuilder.group({
      page: new UntypedFormControl(1),
    });

    this.nodeservice.refreshneeded.subscribe(data => {
      //  this.List();
      this.getParent();
    });

    // this.List();
    this.getParent();
  }

  getParent() {
    this.database.getParent(this.page, this.searchCategoryForm.value, this.departmentID).subscribe((item) => {
      this.count = item['count'];
      this.dataSource.data = item['results'].map((name: ItemNode) => new DynamicFlatNode(name, 0, true));
      //   this.dataSource.data =this.dataSource.data.concat(item['results'].map((name: ItemNode) => new DynamicFlatNode(name, 0, true)));   
      this._cdref.detectChanges();
    });
  }




  onSelectDepartment(e) {
    // console.log(e);
    this.departmentID = e;
    // this.departmentEvent.emit(e.id);
    this.refreshEvent.emit('refresh');
    this.refresh = true;
    this.getParent();
  }


  onSelectCategory(e, is_expanded) {
    
    this.categoryID = e;
    this.categoryEvent.emit(e);
    this.refreshEvent.emit('refresh');
    // this.refresh = true;
    // this.getParent();
    if (is_expanded == true) {
      this.displayAddSubCategoryIcon = true;
      this.displayAddCategoryIcon = false;
    }
    else {
      this.displayAddSubCategoryIcon = false;
      this.displayAddCategoryIcon = true;
      this.subCategoryEvent.emit(0);    //pass zero to hide plus icon in equipment page
    }
  }


  /* Func to get list of departments */
  getDepartmentsList() {
    this.departmentService.getDepartments().subscribe((res: any) => {
      this.departments = res.results;
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Department list');
      });
  }


  pushValue(ev?) {
    if (this.count != this.dataSource.data.length) {
      // if (this.count != 4) {
      this.formData.patchValue({
        page: this.page,
      });
      // this.List();
      // this.getParent();
      this.page++;
    }
  }

  List(refresh = 0) {

    /*to get data from initial */
    if (refresh = 1) {
      this.dataSource.data = [];
      this.formData.patchValue({
        page: 1,
      });
      this.page = 2;
    }

    //  this.spinner.show(); 
    let formValue = this.formData.value;
    this.subscription = this.database.getParent(this.page, this.searchCategoryForm.value, this.departmentID)
      // (formData)
      .subscribe((data) => {
        
        this.dataSource.data = data['results'].map((name: ItemNode) => new DynamicFlatNode(name, 0, true));
        
        this.count = data['count'];
        this._cdref.detectChanges();
        //  this.spinner.hide();
      });
  }


  onClickMe() {
    this.clickMessage = 'You are my hero!';
  }

}