import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EquipmentService } from 'src/app/service/equipment.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-equipment-view',
  templateUrl: './equipment-view.component.html',
  styleUrls: ['./equipment-view.component.scss'],
})
export class EquipmentViewComponent implements OnInit {
  errormsg: any;
  res: any;
  list: any;
  equip_id: any;
  md: any;
  enableDeleteButton: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EquipmentViewComponent>,
    private equipmentService: EquipmentService,
    private toastPopupService: ToastPopupService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data

  ) {
    // this.res=this.data.userData;

  }

  ngOnInit() {
    if (this.data) {
      this.res = this.data.equip_data;
      this.equip_id = this.data.equip_data.id;
      this.getEquipDetails();
    }
    if (this.data.deleteButton) {
      this.enableDeleteButton = true;
    }
    //console.log(this.res.name,'lll')
    //console.log(this.res,'rrr')
  }


  delete() {
    Swal.fire({
      title: 'Are you sure?',
      //text: "You won't be able to revert this!<br> hello",
      html:
        "You won't be able to revert this!<br><br> " +
        '<p style="font-size:14px">To Confirm deletion, Enter delete below</p>',
      icon: 'warning',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      heightAuto: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      preConfirm: (res) => {
        if (res == 'delete') {

          return true;
        }
        else {
          Swal.showValidationMessage(
            `Request failed: 'mismatch code'`
          )
        }
        // return fetch(`//api.github.com/users/${login}`)
        //   .then(response => {
        //     if (!response.ok) {
        //       throw new Error(response.statusText)
        //     }
        //     return response.json()
        //   })
        //   .catch(error => {
        //     Swal.showValidationMessage(
        //       `Request failed: ${error}`
        //     )
        //   })
        return true
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //  this.del(id);
        // Swal.fire(
        //   'Deleted!',
        //   'Your file has been deleted.',
        //   'success',
        // )

        this.del(this.equip_id);

      }
    })
  }

  del(id) {
    const payload = {
      "is_discarded": true,
    }

    {
      this.equipmentService.discardEquipments(id, payload)
        .subscribe((res: any) => {
          //  this.refreshEquipment();
          this.closeDialog();
        });
    }
  }


  getEquipDetails() {
    /* subscribe create ticket api*/
    this.equipmentService.getEquipmentDetail(this.equip_id).subscribe((response) => {
      this.res = response;
      // this.md=res.maintenance_department;
      // console.log(this.res);
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('unable to get Equipment details');
      }
    )


  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }
}
