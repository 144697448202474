import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../../../service/maintenance.service';
import { MatDialog } from '@angular/material/dialog';
import { IonInfiniteScroll } from '@ionic/angular';
import { MatDialogRef } from '@angular/material/dialog';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UserService } from 'src/app/service/users.service';
import { UserViewComponent } from '../user-view/user-view.component';
import { ScreensizeService } from 'src/app/service/screensize.service';
import { LoginService } from 'src/app/service/login.service';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserResetPasswordComponent } from '../user-reset-password/user-reset-password.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})

export class UsersListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  errormsg: any;
  tickets: any;
  dialogRef: MatDialogRef<any>;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  users: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: any;
  refresh: boolean = false;
  subscription: Subscription;

  /*intalise form */
  searchUserForm = new FormGroup({
    search: new FormControl('',),
  })
  search: boolean = false;
  results_per_page: any;
  LoggedUsername: any;
  has_all_permission = 0;

  constructor(
    private userService: UserService,
    private screensizeService: ScreensizeService,
    private loginService: LoginService,
    private toastPopupService: ToastPopupService,
    public dialog: MatDialog
  ) { }

  formatRole(role) {
    if (role == 'client') {
      return 'user'
    }
    else {
      return role;
    }
  }

  ngOnInit() {
    this.LoggedUsername = this.loginService.getUsername();
    if (this.LoggedUsername == 'admin') {   // if loggedin user is admin, give all permissions for this page
      this.has_all_permission = 1;
    }
    this.getUsersList();
    this.permissions = this.loginService.currentUserPermissions();
  }

  /**function to delete user */
  deleteUser(id) {
    this.userService.deleteUser(id).subscribe((res: any) => {
      this.refreshUsers();
    },
      (error) => {
        this.errormsg = error.error;
        this.toastPopupService.openToast('User Deleted Successfully');
      }
    );
  }

  /**func to get users list */
  getUsersList() {
    if (this.refresh == true) {
      this.users = [];
      this.page = 1;
      this.refresh = false;
    }
    if (this.search) {
      this.userService.getSearchUsers(this.page, this.searchUserForm.value).subscribe((res: any) => {
        this.results_per_page = res.results_per_page;
        this.count = res.count;
        this.users = [
          ...new Map(
            this.users
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      });
    }
    else {
      this.userService.getusers(this.page).subscribe((res: any) => {
        this.count = res.count;
        this.users = [
          ...new Map(
            this.users
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];

      });
    }
  }


  /*func to open create ticket modal */
  addUserDialog() {
    this.dialogRef = this.dialog.open(UserCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }


  camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      if (result == 'refresh') {
        this.refresh = true;
        this.getUsersList();
      }

    });
  }

  /**fuc to open model for view user */
  viewUser(res) {
    this.dialogRef = this.dialog.open(UserViewComponent, {
      data: {
        userData: res,
      },
      width: '80%',
    });
  }

  resetUserPassword(res){
    this.dialogRef = this.dialog.open(UserResetPasswordComponent, {
      data: res,
      width: '80%',
    });
  }


  /**func to open modal for update user */
  updateUser(id, res) {
    this.dialogRef = this.dialog.open(UserCreateComponent, {
      data: {
        user_id: id,
        user_data: res
      },
      width: '80%',
    });
    this.reloadList();
  }


  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();
      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.users.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getUsersList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  toggleInfiniteScroll() {
    this.infiniteScroll.disabled = !this.infiniteScroll.disabled;
  }


  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getUsersList();
  }

  refreshUsers() {
    // this.filter=false;
    this.search = false;
    this.refresh = true;
    this.searchUserForm.patchValue({
      search: ''

    });
    this.getUsersList();
  }
}

