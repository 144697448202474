import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-ticket-notes',
  templateUrl: './ticket-notes.component.html',
  styleUrls: ['./ticket-notes.component.scss'],
})
export class TicketNotesComponent implements OnInit {


  /*intalise form */
  notesTicketForm = new FormGroup({
    note: new FormControl('',),
    ticket: new FormControl(this.data.ticket_id,),
  })
  ticket_id: any;
  myticket_yes = 0;
  errormsg: any;





  constructor(
    public dialogRef: MatDialogRef<TicketNotesComponent>,
    private maintenanceService: MaintenanceService,
    private equipmentService: EquipmentService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }


  ngOnInit() {

    if (this.data) {
      this.ticket_id = this.data.ticket_id;
      //   this.getticketDeatils();
    }
    if (this.data.myticketNotes) {
      this.myticket_yes = this.data.myticketNotes;
    }

  }


  /* Func onsubmitting form - to create a notes*/
  onSubmit() {

    /* subscribe create notes api*/
    this.maintenanceService.updateTicketNotes(this.ticket_id, this.notesTicketForm.value, this.myticket_yes).subscribe((res) => {
      /* if successfully submitted display msg to user in toast then close modal*/
      // console.log("ticket_id", res);
      this.closeDialog();
      this.toastPopupService.openToast('Sucessfully added notes');

    },
      (error) => {
        // console.log(error);
        this.errormsg = error;
        this.toastPopupService.openToast('Unable ');
      }
    )
  }



  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }



}




