import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IonInfiniteScroll } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { FloorService } from 'src/app/service/floor.service';
import { FloorCreateComponent } from '../floor-create/floor-create.component';

@Component({
  selector: 'app-floor-list',
  templateUrl: './floor-list.component.html',
  styleUrls: ['./floor-list.component.scss'],
})
export class FloorListComponent implements OnInit {
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  dialogRef: MatDialogRef<any>;
  errormsg: any;
  loginData: string;
  loginTokenDecodedData: any;
  role: any;
  image_url: string;
  floors: any = [];
  count: any;
  page: number = 1;
  isDesktop: boolean = false;
  permissions: {};
  refresh: boolean = false;
  subscription: Subscription;
  searchFloorForm = new FormGroup({
    search: new FormControl('',),
  })
  filterFloorForm = new FormGroup({
    start_date: new FormControl('',),
    end_date: new FormControl('',),
  })

  search: boolean = false;
  results_per_page: any;
  LoggedUsername: any;

  constructor(
    private floorService: FloorService,
    public dialog: MatDialog, private toastPopupService: ToastPopupService
  ) { }

  ngOnInit() {
    this.getFloorList();
  }
  /*func to get ticket list */
  getFloorList() {
    if (this.refresh == true) {
      this.floors = [];
      this.page = 1;
      // this.saveCurrentPageNumber=1;
      this.refresh = false;
    }
    {
      this.floorService.getFloorSearch(this.page, this.searchFloorForm.value).subscribe((res: any) => {
        this.results_per_page = res.results_per_page;
        //const tickets1 = res.results;
        this.count = res.count;
        this.floors = [
          ...new Map(
            this.floors
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
      },
        (error) => {
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Get Floors');
        }
      )
    };
  }

  refreshFloor() {
    this.search = false;
    this.refresh = true;
    this.searchFloorForm.patchValue({
      search: ''
    });
    this.getFloorList();
  }

  addFloorDialog() {
    this.dialogRef = this.dialog.open(FloorCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }

  updateFloorDialog(res) {
    this.dialogRef = this.dialog.open(FloorCreateComponent, {
      width: '80%',
      data: res
    });
    this.reloadList();
  }

  /*func to reload ticketList */
  reloadList() {
    this.dialogRef.afterClosed().subscribe(result => {
      if (result == 'refresh') {
        this.refresh = true;
        this.getFloorList();
      }

    });
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();
      if (this.floors.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      }
      else {
        this.page++;
        this.getFloorList();
      }
    }, 500);
  }

  onSearchSubmit() {
    // this.filter=false;
    this.search = true;
    this.refresh = true;
    this.getFloorList();
  }
}
