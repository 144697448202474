import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Department } from '../interface/Department';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getHospitalDepartments(): Observable<Department> {
    return this.http.get<Department>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}hospital-department/api/`,
      //this.endpoint +'auth/',    
    );
  }

  getDepartments(page = 1): Observable<Department> {
    return this.http.get<Department>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}department/api/?page=${page}`,
      //this.endpoint +'auth/',    
    );
  }


  getSubDepartments(page = 1): Observable<Department> {
    return this.http.get<Department>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/maintenance/department/?page=${page}`,
      //this.endpoint +'auth/',    
    );
  }

  getMaintenanceDepartments(page = 1): Observable<Department> {
    return this.http.get<Department>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/maintenance/department/?page=${page}`,
      //this.endpoint +'auth/',    
    );
  }

  getMainDepartments(page = 1): Observable<Department> {
    return this.http.get<Department>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}department/api/?page=${page}`,
      //this.endpoint +'auth/',    
    );
  }


  ///

  getDepartmenties(page = 1): Observable<Department> {
    return this.http.get<Department>(
      `${this.endpoint}department/api/?page=${page}`,
    );
  }

  addDepartment(postData): Observable<Department> {
    return this.http.post<Department>(
      `${this.endpoint}department/api/create/`,
      postData
    )
  }

  updateDepartment(id, postData) {
    return this.http.put<Department>(
      `${this.endpoint}department/api/${id}/`,
      postData
    )
  }

  getDepartmentData(id) {
    return this.http.get<Department>(
      `${this.endpoint}department/api/${id}/`,

    )
  }
  getDepartmentSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Department>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}department/api/?page=${page}&search=${result.search}`
    );
  }


  ///
}
