import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { EquipmentService } from 'src/app/service/equipment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/service/department.service';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { UserService } from 'src/app/service/users.service';
import { MatDialog } from '@angular/material/dialog';
import { GroupCreateComponent } from '../../groups/group-create/group-create.component';
import { LoginService } from 'src/app/service/login.service';
import { CategoryService } from 'src/app/service/category.service';
import { CustomRoleService } from 'src/app/service/customrole.service';
import { LocationService } from 'src/app/service/location.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit {
  uploadedImage: File;

  /*  roleTypes */
  selectedroleType: any = '';
  roleTypes = [];

  /*  roleTypes */
  selectedGroupType: any;
  groupTypes = [];

  selectedLevel: any;
  levelTypes = [
    { id: 1, name: 'L1', value: 'Level 1' },
    { id: 2, name: 'L2', value: 'Level 2' },
    { id: 2, name: 'L3', value: 'Level 3' },
  ];

  genderTypes = [
    { id: 1, name: 'Male', value: 'Male' },
    { id: 2, name: 'Female', value: 'Female' },
  ]

  /*intalise form */
  createUserForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('',[Validators.required,Validators.pattern('[- +()0-9]{10,12}')]),
    password: new FormControl(''),
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl(''),
    gender: new FormControl(),
    role: new FormControl('', [Validators.required]),
    department: new FormControl(),
    level: new FormControl(),
    city: new FormControl(),

    // main_department: new FormControl('',),
    // has_approval_permission: new FormControl(false,),
    // has_status_update_permission: new FormControl(false,),
    // has_mail_permission: new FormControl(false,),
    // has_job_assign_permission: new FormControl(false,),
    // has_ticket_create_permission: new FormControl(false,),
    // check_all_permission: new FormControl(false),
  });


  get m(){
    return this.createUserForm.controls;
  }

  user_id: any;
  errormsg: any;
  count: any;
  page: any = 1;
  LoggedInrole: any;
  LoggedUsername: any;
  maintenance_departments = [];
  mainteneceDepartmentCount: any;
  main_departments: any;
  mainDepartmentCount: any;
  mainDepartmentPage = 1;
  selectedDepartmentTypes: any;
  error_msg: string;
  roleTypesCount: number;
  DepartmentCount: any;
  departmentCount: any;
  departments: any;
  displayDepartment: number = 1;
  cities: any;
  displayLevel: number = 1;
  // dialog: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private maintenanceService: MaintenanceService,
    private equipmentService: EquipmentService,
    private departmentService: DepartmentService,
    private toastPopupService: ToastPopupService,
    private userService: UserService,
    public dialog: MatDialog,
    public loginService: LoginService,
    private categoryService: CategoryService,
    private customRoleService: CustomRoleService,
    private locationService: LocationService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.LoggedUsername = this.loginService.getUsername();
    // if (this.LoggedUsername == 'admin') {
    //   this.roleTypes.push({ id: 3, name: 'Manager', value: 'manager' })
    // }
    //  this.LoggedInrole = this.loginService.getUserRole();
    //  console.log(this.LoggedInrole);
  }

  ngOnInit() {
    //this.getGroupsList();
    this.getDepartmentsList();
    this.getRolesList();
    this.getCity();
    // this.getMaintenanceDepartmentsList();
    /*if task deatails alredy exists get task deatails */
    if (this.data) {
      //this.getTaskDetails();
      // console.log(this.data.user_data);
      this.user_id = this.data.user_data.id;
      this.getUserDetails();
      this.createUserForm.get('username')?.disable();
      this.createUserForm.get('email')?.disable();
      this.createUserForm.get('department')?.disable();
    }
    // this.user_id=this.data.user_id;
  }

  getCity() {
    this.locationService.getcities(1).subscribe((res: any) => {
      this.cities = res.results;
    });
  }





  setRole(e) {
    let name = this.getRoleName(e);
    if (name == 'user' || name == 'User' || name == 'client' || name == 'Client'
      || name == 'helpdesk'
      || name == 'Helpdesk'
    ) {
      this.displayDepartment = 0;
      this.displayLevel = 0;
    }

    else if (name == 'Manager' || name == 'manager') {
      this.displayDepartment = 1;
      this.displayLevel = 0;
    }
    else {
      this.displayDepartment = 1;
      this.displayLevel = 1;
    }




  }



  getRoleName(id) {
    let role = this.roleTypes.filter((x: any) => {
      return x.id == id;
    });
    // console.log(role);
    return role[0].name;
  }

  getRolesList() {
    this.customRoleService.getCustomRoles().subscribe(
      (res: any) => {
        //this.roleTypes = res.results;
        this.roleTypes = [
          ...new Map(
            this.roleTypes.concat(res.results).map((item) => [item['id'], item])
          ).values(),
        ];
        this.roleTypesCount = res.count;
        this.onScrollRoleTypes();
      },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Custom Role List');
      }
    );
  }

  onScrollRoleTypes() {
    // console.log('hello');
    if (this.roleTypesCount != this.roleTypes.length) {
      this.page++;

      this.getRolesList();
    }
  }

  onchange() {
    // console.log(e);
    // this.getMaintenanceDepartmentsList();
    // this.getMaintenanceDepartmentsList();
  }

  /*
  check_all_permissions() {
    // console.log("hello", this.createUserForm.value.check_all_permission);
    if (this.createUserForm.value.check_all_permission == false) {
      this.createUserForm.patchValue({
        has_approval_permission: true,
        has_status_update_permission: true,
        has_mail_permission: true,
        has_job_assign_permission: true,
        has_ticket_create_permission: true,
      });
    }
    if (this.createUserForm.value.check_all_permission == true) {
      this.createUserForm.patchValue({
        has_approval_permission: false,
        has_status_update_permission: false,
        has_mail_permission: false,
        has_job_assign_permission: false,
        has_ticket_create_permission: false,
      });
    }

  }
*/

  /* Func to get list of departments */
  getDepartmentsList() {
    {
      this.departmentService
        .getMainDepartments(this.mainDepartmentPage)
        .subscribe(
          (res: any) => {
            this.departmentCount = res.count;
            // console.log(this.mainteneceDepartmentCount);
            this.departments = res.results;
            /*  this.maintenance_departments  =[
            ...new Map(
              this.maintenance_departments
                .concat(res.results)
                .map((item) => [item['id'], item])
            ).values(),
          ]; */
            // this.loadmore();
          },
          (error) => {
            // console.log(error);
            this.errormsg = error.error;
            this.toastPopupService.openToast('Unable to get Department list');
          }
        );
    }
  }

  /* Func to get list of departments 
  getMaintenanceDepartmentsList() {
    // console.log(this.createUserForm.value.main_department);
    {
      this.categoryService.getCategory(this.page, this.createUserForm.value.main_department).subscribe((res: any) => {
        this.mainteneceDepartmentCount = res.count;
        // console.log(this.mainteneceDepartmentCount)
        this.maintenance_departments = res.results;
        this.maintenance_departments = [
          ...new Map(
            this.maintenance_departments
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        // this.loadmore();  
      },
        (error) => {
          // console.log(error);
          this.toastPopupService.openToast('Unable to get Department list');
        });
    }

  }

  loadmore() {
    // console.log(this.maintenance_departments.length);
    if (this.mainteneceDepartmentCount != this.maintenance_departments.length) {
      this.page++;
      this.getMaintenanceDepartmentsList();
    } else {
      // console.log("priya", this.selectedDepartmentTypes.name);
      this.maintenance_departments = this.maintenance_departments.filter((x: any) => { return x.department == this.selectedDepartmentTypes.name })

    }
  }

  */

  /*func to get task details and patch values to form */
  getUserDetails() {
    this.userService.getUserData(this.user_id).subscribe(
      (res) => {
        if (res.role.length == 1) {
          let role_id = res.role;
          this.setRole(role_id);
        }

        this.createUserForm.patchValue({
          //technician: this.data.taskdetails[0].technician,
          username: res.username,
          email: res.email,
          phone: res.phone,
          //password:res.username,
          first_name: res.first_name,
          last_name: res.last_name,
          gender: res.gender,
          role: res.role,
          level: res.level,
          city: res.city,
          department: res.department,
        });

        // this.getMaintenanceDepartmentsList();
      },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to create user details');
      }
    );
  }

  setpermissions() {
    if (this.createUserForm.value.role == 'user') {
      //this.createUserForm.value.has_approval_permission = false;
      this.createUserForm.value.department = null;
      // this.createUserForm.value.main_department = null;
    }
    if (this.createUserForm.value.role == 'manager') {
      this.createUserForm.value.department = null;
    }
    if (this.createUserForm.value.role == 'technician') {
      //    this.createUserForm.value.has_approval_permission = false;
    }
    if (this.createUserForm.value.role == 'lead') {
      //  this.createUserForm.value.has_approval_permission = false;
    }
  }
  validation() {
    let name = this.getRoleName(this.createUserForm.value.role);
    // console.log(name);
    if (!(name == 'client' || name == 'helpdesk')) {
      if (this.createUserForm.value.department == '') {
        this.error_msg = 'please enter department';
        return 0;
      } else if (this.createUserForm.value.department == null) {
        this.error_msg = 'please enter department';
        return 0;
      } else return 1;
    }

    return 1;
  }

  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {
    // console.log(this.createUserForm.value);

    if (
      1
      // this.validation()
    ) {
      // this.setpermissions();

      if (this.createUserForm.value.role == 'user') {
        this.createUserForm.value.role = 'client';
      }

      if (this.user_id) {
        /* subscribe create ticket api*/

        this.userService
          .updateUser(this.data.user_data.id, this.createUserForm.value)
          .subscribe(
            (res) => {
              /* if successfully submitted display msg to user in toast then close modal*/
              // console.log('user_id', res.id);

              /* if image available add below to payload */
              if (this.uploadedImage != undefined) {
                this.submitImageValue(res.id);
              } else {
                this.closeDialog(); //close modal
                this.toastPopupService.openToast('User Created Successfully'); //display success mesg
              }
            },
            (error) => {
              // console.log(error);
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to Create User');
            }
          );
      } else {
        {
          /* subscribe create ticket api*/
          this.userService.addUser(this.createUserForm.value).subscribe(
            (res) => {
              /* if successfully submitted display msg to user in toast then close modal*/
              // console.log('user_id', res.id);
              /* if image available add below to payload */
              if (this.uploadedImage != undefined) {
                this.submitImageValue(res.id);
              } else {
                this.closeDialog(); //close modal
                this.toastPopupService.openToast('User Created Successfully'); //display success mesg
              }
            },
            (error) => {
              // console.log(error);
              this.errormsg = error.error;
              this.toastPopupService.openToast('Unable to Create User');
            }
          );
        }
      }
    }
  }

  submitImageValue(id) {
    /* to send payload in formData (bez it has image field)*/
    const imageFormData = new FormData();
    // console.log(id);

    /* if image available add below to payload */
    if (this.uploadedImage != undefined) {
      imageFormData.append('image', this.uploadedImage);
    }

    /* subscribe create ticket api*/
    this.maintenanceService.addticketImage(id, imageFormData).subscribe(
      (res) => {
        /* if successfully submitted display msg to user in toast then close modal*/

        this.closeDialog();
      },
      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to upload image');
      }
    );
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

  /* Function called on choosing a pic */
  onImageUpload(event) {
    let files = event.target.files;
    //check file is valid
    if (!this.validateFile(files[0].name)) {
      // console.log('Selected file format is not supported');
      this.errormsg =
        'Selected file format is not supported. Choose ".jpg" format only.';
      return false;
    } else {
      this.uploadedImage = event.target.files[0];
      this.errormsg = '';
    }
  }

  //check file is valid
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'jpg') {
      return true;
    } else {
      return false;
    }
  }

  /* Func to get list of equipments */
  getGroupsList() {
    this.userService.getGroups(this.page).subscribe(
      (res: any) => {
        //   this.groupTypes = res.results;
        this.groupTypes = [
          ...new Map(
            this.groupTypes
              .concat(res.results)
              .map((item) => [item['id'], item])
          ).values(),
        ];
        this.count = res.count;
        this.onScroll();
      },
      (error) => {
        // // console.log(error);
        this.toastPopupService.openToast('Unable to get Group list');
      }
    );
  }

  addGroup() {
    this.dialogRef = this.dialog.open(GroupCreateComponent, {
      width: '80%',
    });
    this.reloadList();
  }

  onScroll() {
    // console.log('hello');
    if (this.count != this.groupTypes.length) {
      this.page++;

      this.getGroupsList();
    }
  }

  loadData(event) {
    setTimeout(() => {
      //  console.log('Done');
      event.target.complete();

      // App logic to determine if all data is loaded
      // and disable the infinite scroll
      if (this.groupTypes.length === this.count) {
        event.target.disabled = true;
        // console.log('Done');
      } else {
        this.page++;
        this.getGroupsList();
        // console.log('Inprogress');
      }
    }, 500);
  }

  /*func to reload ticketList */
  reloadList() {
    // console.log("at reload page", this.page,this.saveCurrentPageNumber)
    this.dialogRef.afterClosed().subscribe(
      (result) => {
        // console.log(`Dialog result: ${result}`);
        // console.log('The dialog was closed');
        if (result == 'refresh') {
          // this.refresh=true;
          this.getGroupsList();
        }

        /*else if(result=='norefresh'){
 
      }  else if(result=='refreshCurrentScreen'){ 
        this.getticketList();
      } 
      else{ 
      this.getticketList();
      } */
        // this.page=this.saveCurrentPageNumber;
      },

      (error) => {
        // console.log(error);
        this.toastPopupService.openToast('Unable to get list');
      }
    );
  }
}
