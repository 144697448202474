import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Department } from '../interface/Department';
import { Groups } from '../interface/Groups';
import { RestService } from './rest.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TechnicianUser } from '../interface/TechnicianUser';
import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Maintenance } from '../interface/Maintenance';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }




  downloadFile(path): any {
    return `${this.endpoint}${path}`;
  }

  getreportData(payload) {
    return this.http.post<Maintenance>(
      `${this.endpoint}api/reports/`
      //?start_date=${start_date}&end_date=${end_date}
      , payload,
    )
  }

  getTechnicianReportData(payload) {
    return this.http.post<Maintenance>(
      `${this.endpoint}api/techreports/`
      //?start_date=${start_date}&end_date=${end_date}
      , payload,
    )
  }

  getFormatreportData(payload) {

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json'
    //   })
    // };

    //  let start_date=payload.start_date;
    //  let end_date=payload.end_date;
    return this.http.post<Maintenance>(
      `${this.endpoint}api/ticker_report/`
      //?start_date=${start_date}&end_date=${end_date}
      , payload,
      //httpOptions
    )
  }

  getFieldValues() {
    return this.http.get<Maintenance>(
      `${this.endpoint}api/reports/fields/`
    )
  }



}
