import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserActivity } from '../interface/UserActivity';
import { User } from '../interface/User';

@Injectable({
  providedIn: 'root',
})
export class activityService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getUsersActivity(page = 1): Observable<UserActivity> {
    return this.http.get<UserActivity>(
      `${this.endpoint}user/api/activity/?page=${page}`
    );
  }

  getSearchUsersActivity(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<UserActivity>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}user/api/activity/?page=${page}&search=${result.search}`
    );
  }

  getfilterUser(page = 1, result) {
    if (result.user == undefined) {
      result.user = '';
    }
    if (result.activity == undefined) {
      result.activity = '';
    }
    if (result.created_at == undefined) {
      result.created_at = '';
    }
    return this.http.get<UserActivity>(
      `${this.endpoint}user/api/activity/?page=${page}&user=${result.user}&activity=${result.activity}&created_at=${result.created_at}`
    );
  }
}
