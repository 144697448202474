import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { CategoryService } from 'src/app/service/category.service';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { CategoryService } from 'src/app/service/category.service';
import { EquipmentService } from 'src/app/service/equipment.service';

@Component({
  selector: 'app-subcategory-create',
  templateUrl: './subcategory-create.component.html',
  styleUrls: ['./subcategory-create.component.scss'],
})
export class SubcategoryCreateComponent implements OnInit {

  /*intalise form */
  createSubCategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required,]),
    category: new FormControl(this.data.category_id),
    sub_category_type: new FormControl(''),
    item: new FormControl(''),
    // maintenance_category:new FormControl([], [Validators.required,]),
  })


  selectedTicketType: any;
  ticketTypes = [
    { id: 1, name: 'Incident', value: 'Incident' },
    { id: 2, name: 'Request', value: 'Request' },
  ];
  subcategory_id: any;
  subcategory_details: any;
  errormsg: any;
  category = [];
  selectedCategory: any;
  errormsg_re_serial_number: string = '';
  mainteneceCategoryCount: any;
  page: number = 1;
  maintenance_category = [];
  selectedCategorys: any;
  category_id: any;


  items = [];
  selectedItems: any;


  constructor(
    public dialogRef: MatDialogRef<SubcategoryCreateComponent>,
    private maintenanceService: MaintenanceService,
    // private categoryService: CategoryService,
    private toastPopupService: ToastPopupService,
    private categoryService: CategoryService,
    private equipmentService: EquipmentService,
    //  private userService: UserService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {

  }


  ngOnInit() {
    this.getEquipmentsList();
    // this.getCategoriesList();
    //  this.createSubCategoryForm.value.category=this.category_id;
    //  this.getMaintenanceCategorysList();
    // console.log(this.data);
    if (this.data) {
      //this.getTaskDetails();
      //  console.log(this.data.Category_data);
      this.category_id = this.data.category_id;
      this.createSubCategoryForm.value.category = this.category_id;
      //  this.getSubCategoryDetail();

    }
  }



  /* Func to get list of category */
  getCategoriesList() {
    this.categoryService.getCategory().subscribe((res: any) => {
      this.category = res.results;
      // console.log(this.category);
      //      this.selectList3= this.category.filter((x: any) => { return x.department == this.selected_1.id; });

    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Category list');
      });
  }




  /* Func to get list of Equipment */
  getEquipmentsList() {
    this.equipmentService.getEquipments(this.page).subscribe((res: any) => {
      this.items = res.results;
      // console.log(this.category);
      //      this.selectList3= this.category.filter((x: any) => { return x.department == this.selected_1.id; });

    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Equipment list');
      });
  }



  /*func to get task details and patch values to form */
  getSubCategoryDetail() {
    /* subscribe create ticket api*/
    this.categoryService.getCategoryDetail(this.subcategory_id).subscribe((res) => {
      this.subcategory_details = res;

      const a = [];


      this.createSubCategoryForm.patchValue({
        name: this.subcategory_details.name,
        category: this.subcategory_details.category

      });

      // this.selectedcategory.id=this.subcategory_details.category

    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Sub Category details');
      }
    )
    // console.log(this.subcategory_details);


  }




  /* Func onsubmitting form - to create a ticket*/
  onSubmit() {

    // if(this.createSubCategoryForm.value.name!=this.createSubCategoryForm.value.name){
    // this.errormsg_re_serial_number='mismatch in serial number';
    //}
    //console.log(this.createSubCategoryForm.value)

    //if(this.createSubCategoryForm.valid && this.errormsg_re_serial_number=='') 

    if (this.subcategory_id) {
      this.categoryService.updateSubCategory(this.subcategory_id, this.createSubCategoryForm.value).subscribe((res) => {
        /* if successfully submitted display msg to Category in toast then close modal*/
        //console.log("Category_id", res.id);
        this.closeDialog();
        this.toastPopupService.openToast('Category Created Successfully');   //display success mesg


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Category');
        }
      )
    } else {
      /* subscribe create ticket api*/
      this.categoryService.addSubCategory(this.createSubCategoryForm.value).subscribe((res) => {
        /* if successfully submitted display msg to Category in toast then close modal*/
        // console.log("Category_id", res.id);
        this.closeDialog();
        this.toastPopupService.openToast('Category Created Successfully');   //display success mesg


      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Category');
        }
      )
    }



  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }

  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }

}
