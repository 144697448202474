import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceService } from 'src/app/service/maintenance.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastPopupService } from 'src/app/service/toast-popup.service';
import { DepartmentService } from 'src/app/service/department.service';
import { ChatbotService } from 'src/app/service/chatbot.service';

@Component({
  selector: 'app-modal-question-create',
  templateUrl: './modal-question-create.component.html',
  styleUrls: ['./modal-question-create.component.scss'],
})

export class ModalQuestionCreateComponent implements OnInit {

  myForm = this.fb.group({
    documents: this.fb.array([])
  });

  get documentsControl(): FormArray {
    return this.myForm.get("documents") as FormArray;
  }
  documents = [
    { id: 1, service_item_id: 311101, name: "document 1" },
    { id: 2, service_item_id: 311102, name: "document 2" },
    { id: 3, service_item_id: 311103, name: "document 3" },
  
  ];


  selectedquestion_types: any
  name: any;


  department_id: any;
  errormsg: any;
  page: number = 1;
  count: any;
  field_types: any;
  displayDropDownValues: boolean = false;
  question_types: any;
  createModalQuestionForm: FormGroup<{ question_type: FormControl<any>; question_text: FormControl<string>; type: FormControl<string>; basic: FormControl<boolean>; dropdown_values: FormControl<any[]>; }>;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModalQuestionCreateComponent>,
    private maintenanceService: MaintenanceService,
    // private departmentService: DepartmentService,
    private ChatbotService: ChatbotService,
    private toastPopupService: ToastPopupService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.documents.forEach(document =>
      this.documentsControl.push(
        this.fb.group({
          id: [document.id],
          name: [document.name],
          service_item_id: [document.service_item_id]
        })
      )
    );


    /*intalise form */
    this.createModalQuestionForm = new FormGroup({
      question_type: new FormControl(null,),
      question_text: new FormControl('',),
      type: new FormControl(null,),
      basic: new FormControl(false,),
      dropdown_values: new FormControl([],),
      // skip: new FormControl('',),
    })

    this.getQuestionTypeValues();
    this.getFieldTypeValues();
    // console.log(this.data);
    // if (this.data.id) {
    //   this.department_id = this.data.id;
    //   this.getDepartmentDetails();
    // }
  }


  getQuestionTypeValues() {

    this.ChatbotService.questionTypeTicket().subscribe((res) => {
      this.question_types = res.results;


    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Department Details');
      }
    )

  }


  getFieldTypeValues() {

    this.ChatbotService.fieldTypeList().subscribe((res) => {
      // this.field_types=res.results;
      this.field_types =
        [
          {
            "id": 1,
            "name": "dropdown",
          },
          {
            "id": 2,
            "name": "text",
          },
          {
            "id": 3,
            "name": "attachment",
          },
        ]

    },
      (error) => {
        // console.log(error);

        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Department Details');
      }
    )

  }



  // getDepartmentDetails() {

  //   this.departmentService.getDepartmentData(this.department_id).subscribe((res) => {
  //     // console.log(res);
  //     // this.createModalQuestionForm.patchValue({
  //     //   name: res.name
  //     //       });
  //   },
  //     (error) => {
  //       // console.log(error);
  //       this.errormsg = error.error;
  //       this.toastPopupService.openToast('Unable to get Department Details');
  //     }
  //   )
  // }
  /* Func onsubmitting form - to create a department*/
  onSubmit() {
    this.createModalQuestionForm.patchValue({
      dropdown_values: this.listOfNames
    });



    // if (this.department_id) {
    //   /* subscribe create department api*/
    //   this.departmentService.updateDepartment(this.department_id, this.createModalQuestionForm.value).subscribe((res) => {
    //     /* if successfully submitted display msg to user in toast then close modal*/
    //     // console.log("department_id",res);
    //     this.closeDialog();    //close modal
    //     this.toastPopupService.openToast('Department update Successfully');   //display success mesg
    //     // }


    //   },
    //     (error) => {
    //       // console.log(error);
    //       this.errormsg = error.error;
    //       this.toastPopupService.openToast('Unable to update Department');
    //     }
    //   )
    // }
    // else
    {

      /* subscribe create department api*/
      this.ChatbotService.modalQuestionsCreate(this.createModalQuestionForm.value).subscribe((res) => {
        /* if successfully submitted display msg to user in toast then close modal*/
        // console.log("department_id",res);
        this.closeDialog();    //close modal
        this.toastPopupService.openToast('Department Created Successfully');   //display success mesg
        // }

      },
        (error) => {
          // console.log(error);
          this.errormsg = error.error;
          this.toastPopupService.openToast('Unable to Create Department');
        }
      )
    }
  }





  /* Func to close modal */
  closeDialogsimple() {
    this.dialogRef.close('refreshCurrentScreen');
  }

  /* Func to close modal */
  closeDialog() {
    this.dialogRef.close('refresh');
  }


  /* Func to close modal */
  closeDialogNoRefresh() {
    this.dialogRef.close('norefresh');
  }


onchange(e) {    
    if(e=='dropdown'){
       this.displayDropDownValues=true;
    }else{
      this.displayDropDownValues=false;
      this.listOfNames = [];
    }
    // console.log(e,"ev");
}

  nameModel: string = "";
  listOfNames = [];

  onSubmitValues() {
    this.listOfNames.push(this.nameModel)
    this.nameModel = '';
  }



  onFocusOutEvent(event: any) {
    // console.log(event.target.value);
  }



  submitForm() {
    // console.log(this.documents)
  }
  add() {
    this.documentsControl.push(
      this.fb.group({
        id: [null],
        name: [null],
        service_item_id: [null]
      })
    );
  }
  remove(index) {
    this.documentsControl.removeAt(index);
  }
  insert(index) {
    const serviceItenId = this.documentsControl.controls[index].get(
      "service_item_id"
    ).value;
    this.documentsControl.insert(
      index + 1,
      this.fb.group({
        id: [null],
        name: [null],
        service_item_id: [serviceItenId]
      })
    );
  }

}

