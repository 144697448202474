import { Component, OnInit } from '@angular/core';
import { Status } from 'src/app/constant/status';

//import * as echarts from 'echarts';
import { ChartService } from 'src/app/service/charts.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-ticket-status-pie',
  templateUrl: './ticket-status-pie.component.html',
  styleUrls: ['./ticket-status-pie.component.scss'],
})
export class TicketStatusPieComponent implements OnInit {
  A: any;
  result: any;
  sum: any;
  p_key: any;
  p_val: any;
  options: any;
  role: any;
  statusConst = Status;

  constructor(
    private chartService: ChartService,
    private loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.role = this.loginService.getRole(); //get loginUser Role
    this.getticketstatus();
  }

  getticketstatus() {
    this.chartService.getticketstatusData(this.role).subscribe((res) => {
      this.A = res;
      delete this.A['cancelled_tickets'];
      delete this.A['approved_tickets'];
      delete this.A['pending_tickets'];
      delete this.A['assigned_tickets'];
      delete this.A['reopened_tickets'];

      this.A[this.statusConst.Opened.status] = this.A['in_progress_tickets']
      delete this.A['in_progress_tickets'];

      this.A[this.statusConst.Solved.status] = this.A['completed_tickets']
      delete this.A['completed_tickets'];

      this.A[this.statusConst.Reopened.status] = this.A['reopened_tickets']
      delete this.A['reopened_tickets'];

      this.A[this.statusConst.Closed.status] = this.A['closed_tickets']
      delete this.A['closed_tickets'];

      this.result = Object.keys(this.A).map(key => ({ value: this.A[key], name: key }));
      this.sum = this.result;
      this.options = {
        title: {
          left: 'center',
          top: 0
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 0,
          top: 5,
          color: '#ccc',
          icon: 'circle',
          textStyle: {
          }

        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: '50%',
            top: 80,
            avoidLabelOverlap: false,
            data: this.sum,
            // Apply final data here
            emphasis: {
              label: {
                show: true,
                onZero: false,
                //            fontSize: 40,
                fontWeight: 'bold'
              },
              itemStyle: {
                //            color: '#ccc',
                shadowBlur: 10,

                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true,
              onZero: false

            },
            label: {
              show: true,
              onZero: false
              //          position: 'center'
            },
          }
        ]
      };

    });
  }
}
