import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastPopupService {
  constructor(public toastController: ToastController) { }
  async openToast(message) {
    const toast = await this.toastController.create({
      message: message,
      animated: true,
      duration: 2000,
      position: 'bottom',
      // buttons: [
      //   {
      //     text: 'OK',
      //     handler: () => {
      //       // console.log("ok clicked");
      //     },
      //   },
      // ],
    });
    toast.present();
    toast.onDidDismiss().then((val) => {
      // console.log("toast dismissed");
    });
  }
}
