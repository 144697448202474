import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '../interface/Location';
import { RestService } from './rest.service';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(public http: HttpClient) {
    // this.url = environment.base_url;
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }

  getregions(page = 1): Observable<Location> {

    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/regions/?page=${page}`,
      //this.endpoint +'auth/',

    );
  }


  getstate(page = 1, country = ''): Observable<Location> {

    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/states/?page=${page}&country__id=${country}`,
      //this.endpoint +'auth/',

    );
  }

  getcountry(page = 1, region = ''): Observable<Location> {

    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/countries/?page=${page}&region__id=${region}`,
      //this.endpoint +'auth/',

    );
  }

  getcities(page = 1, state = ''): Observable<Location> {
    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/cities/?page=${page}&state__id=${state}`,
      //this.endpoint +'auth/',
    );
  }

  getFloor(page = 1, city = ''): Observable<Location> {

    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/floors/?page=${page}&city__id=${city}`,
      //this.endpoint +'auth/',

    );
  }

  getRoom(page = 1, floor = ''): Observable<Location> {

    return this.http.get<Location>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/rooms/?page=${page}&floor__id=${floor}`,
      //this.endpoint +'auth/',

    );
  }










  getLocationSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Location>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}Location/subLocation/api/?page=${page}&search=${result.search}`
    );
  }

  getLocationDetail(id: any) {

    return this.http.get<any>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}Location/subLocation/api/${id}`,
      //this.endpoint +'auth/',

    );

  }




  addLocation(postData) {
    return this.http.post<Location>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}Location/api/create/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }



  updateLocation(id, postData) {
    return this.http.put<Location>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}Location/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }


  updateSubLocation(id, postData) {
    return this.http.put<Location>(
      // this.url + 'dashboard/appointment-work',
      `${this.endpoint}subLocation/api/${id}/`,
      // 'http://192.168.143.237:8001/maintenance/tickets/api/create/',
      postData
    ).pipe(
      // this.ticketList();
    );
  }





}