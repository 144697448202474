import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoginService } from '../service/login.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  router: any;
  errormsg: any;
  toastPopupService: any;
  constructor(private injector: Injector) { }

  intercept(req, next) {
    const jwt = localStorage.getItem('token');
    if (!!jwt) {
      req = req.clone({
        setHeaders: {
          Authorization: `JWT ${jwt}`,
        },
      });
    }
    return next.handle(req);
  }

  // constructor(private loginService: LoginService) { }
  // intercept(request: HttpRequest<any>, next: HttpHandler) {
  //   if (this.loginService.loggedIn()) {
  //     request = this.addToken(request, this.loginService.getToken());
  //   }
  //   return next.handle(request).pipe(
  //     catchError((error: HttpErrorResponse) => {
  //       if (error.status === 403) {
  //         this.loginService.logout(''); // Handle the expired token error
  //         localStorage.clear(); //clear the headers
  //         window.location.reload();
  //       }
  //       return throwError(error);
  //     })
  //   );
  // }
  // private addToken(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     setHeaders: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }

  /*Func to call logout api and redirect to login page*/
  //  logout() {
  //   this.loginService.logout('').subscribe(
  //     (res: any) => {
  //       // console.log(res);
  //       localStorage.clear(); //clear the headers
  //       //window.location.reload();
  //       this.router.navigate(['/']); //redirect to login page
  //     },
  //     (error) => {
  //       localStorage.clear(); //clear the headers
  //       // window.location.reload();
  //       this.router.navigate(['/']);
  //       // console.log(error);

  //       this.errormsg = error.error;
  //       this.toastPopupService.openToast('Successfully Log out');
  //     }
  //   );
  // }
}
