import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Region } from '../interface/Region';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegionService {
  constructor(public http: HttpClient) {
  }

  get endpoint(): string {
    return `${environment.BASE_URL}`;
  }


  getregions(page = 1): Observable<Region> {
    return this.http.get<Region>(
      // this.url + 'http://192.168.143.237:8001/auth/',
      `${this.endpoint}api/regions?page=${page}`,
    );
  }

  addRegion(postData): Observable<Region> {
    return this.http.post<Region>(
      `${this.endpoint}api/regions/create/`,
      postData
    )
  }

  updateRegion(id, postData) {
    return this.http.put<Region>(
      `${this.endpoint}api/regions/${id}/`,
      postData
    )
  }

  getRegionData(id) {
    return this.http.get<Region>(
      `${this.endpoint}api/regions/${id}/`,

    )
  }
  getregionSearch(page = 1, result) {
    if (result.search == undefined) {
      result.search = '';
    }
    return this.http.get<Region>(
      //'http://192.168.143.237:8001/maintenance/tickets/api/'
      // `${this.endpoint}service/maintenance/api`
      `${this.endpoint}api/regions?page=${page}&search=${result.search}`
    );
  }
}
