import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-widget2',
  templateUrl: './widget2.component.html',
  styleUrls: ['./widget2.component.scss']
})
export class Widget2Component implements OnInit {

  item: any;
  product: any;

  isShown = false; // hidden by default
  constructor(@Inject(MAT_DIALOG_DATA) public data: { name: string; product: string },
    public dialogRef: MatDialogRef<Widget2Component>,
  ) { }

  ngOnInit(): void {
    this.item = this.data.name;
    this.product = this.data.product;
    // console.log(this.item);
  }


  toggleShow() {

    this.isShown = !this.isShown;

  }

}
