// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-infinite-scroll-content.md.infinite-scroll-content-md.hydrated,
ion-infinite-scroll-content {
  height: 0px !important;
  min-height: 0px !important;
}

.alert.alert-success.alert-dismissible {
  width: 96.5% !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vdGlmaWNhdGlvbnMuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7O0VBR0Usc0JBQUE7RUFDQSwwQkFBQTtBQUFGOztBQUdBO0VBQ0UsdUJBQUE7QUFBRiIsImZpbGUiOiJub3RpZmljYXRpb25zLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW9uLWluZmluaXRlLXNjcm9sbC1jb250ZW50Lm1kLmluZmluaXRlLXNjcm9sbC1jb250ZW50LW1kLmh5ZHJhdGVkLFxyXG5cclxuaW9uLWluZmluaXRlLXNjcm9sbC1jb250ZW50IHtcclxuICBoZWlnaHQ6IDBweCAhaW1wb3J0YW50O1xyXG4gIG1pbi1oZWlnaHQ6IDBweCAhaW1wb3J0YW50O1xyXG59XHJcblxyXG4uYWxlcnQuYWxlcnQtc3VjY2Vzcy5hbGVydC1kaXNtaXNzaWJsZXtcclxuICB3aWR0aDogOTYuNSUgIWltcG9ydGFudDtcclxufVxyXG5cclxuXHJcbiAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/pages/components/notifications/notifications.component.scss"],"names":[],"mappings":"AAAA;;EAGE,sBAAA;EACA,0BAAA;AAAF;;AAGA;EACE,uBAAA;AAAF;AACA,4pBAA4pB","sourcesContent":["ion-infinite-scroll-content.md.infinite-scroll-content-md.hydrated,\r\n\r\nion-infinite-scroll-content {\r\n  height: 0px !important;\r\n  min-height: 0px !important;\r\n}\r\n\r\n.alert.alert-success.alert-dismissible{\r\n  width: 96.5% !important;\r\n}\r\n\r\n\r\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
