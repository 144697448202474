import { Component, OnInit } from '@angular/core';
import { statusService } from 'src/app/service/status.service';
import { ToastPopupService } from 'src/app/service/toast-popup.service';

@Component({
  selector: 'app-system-status-list',
  templateUrl: './system-status-list.component.html',
  styleUrls: ['./system-status-list.component.scss'],
})

export class SystemStatusListComponent implements OnInit {
  errormsg: any;
  statusResult: any;

constructor(private statusService: statusService,
    private toastPopupService:ToastPopupService) { }

ngOnInit() {
    this.getStatusCount();
}

getStatusCount(){
    this.statusService.getStatusUpdate().subscribe((res) => {
      this.statusResult=res;     
     //this.unassignedCount=res.pending_tickets;
    },
      (error) => {
        // console.log(error);
        this.errormsg = error.error;
        this.toastPopupService.openToast('Unable to get Category list');
      });
}


}
